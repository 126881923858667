@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-graph-params {
  .graph-params {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    min-height: 40px;
    border: 1px solid black;
    margin-bottom: 20px;
  }

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 110px;
  right: $spacing-05;
  background-color: transparent;
  .section {
    display: flex;
    flex-direction: row;
    .section-textBox {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      border-bottom: 2px solid #6f6f6f;
      padding-top: 10px;
      max-width: 80px;
      max-height: 50px;
      min-width: 80px;
      min-height: 50px;
    }
    .bx--text-input__field-outer-wrapper {
      display: none;
    }
  }
}
