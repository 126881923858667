@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.generic-responsive-middle-truncation {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .opacity {
    opacity: 0;
  }
  .skeleton {
    width: 100%;
    height: $spacing-07;
  }
  .hidden-text {
    position: absolute;
    visibility: hidden;
  }

  .truncated-text {
    max-width: 100%;

    .text-truncation {
      display: grid;
      grid-auto-flow: column;
      .truncate-text-start {
        display: inline-block;
        direction: ltr;
        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
      }
      .truncate-text-end {
        display: flex;
        flex-direction: row-reverse;
        overflow: hidden;
        white-space: pre;
        hyphens: auto;
      }
    }
  }

  p {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    height: 0;
  }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
    outline: none;
  }

  .bx--tooltip--definition {
    position: absolute;
    display: none;

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
      font-size: inherit;
      text-align: left;
      color: inherit;

      &.bx--tooltip--bottom.bx--tooltip--align-center.bx--tooltip--a11y {
        color: transparent;
        margin-top: $spacing-05 + $spacing-02;
        width: 100%;
        .text-truncation {
          width: 100%;
        }
      }
    }
  }
}
