@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';


.procedural-activity-component {
  display: flex;

  .icon {
    padding-right: $spacing-02;
  }

  .label, .bx--inline-loading__text {
    @include carbon--type-style('body-short-01');
    color: $text-01;
  }
}
