@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.appliction-deployment-instance-card {
  background: #ffffff;
  padding: $spacing-05;
  margin: $spacing-05;
  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: $spacing-03;
  }

  .header {
    @include carbon--type-style('productive-heading-03');
    flex: 1;
    display: flex;
  }
  #ip_address {
    background: $gray-10;
  }
  .bx--text-input::placeholder {
    color: #a8a8a8;
    opacity: 1;
    font-size: 12px;
  }
  .btn-action {
    margin-top: $spacing-06;
  }
  .btn-action {
    .bx--btn--disabled {
      background: #c6c6c6;
      color: #8d8d8d;
    }
  }
  .table-container {
    margin-top: $spacing-05;
    margin-left: -$spacing-05;
    margin-right: -$spacing-05;
    .bx--data-table tr {
      height: $spacing-08;
    }
    .delete-link {
      color: black;
      cursor: pointer;
    }
    &:hover {
      background-color: $background-hover;
      .delete-link {
        color: black;
        cursor: pointer;
      }
    }
  }
  .find-and-filter-container {
    display: none;
  }
  .bx--pagination {
    display: none;
  }
}
