@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-container {
  .bx--data-table-container {
    .bx--data-table {
      tbody {
        tr {
          td {
            padding: 15px 16px;
            a {
              text-decoration: none;
              position: initial;
              display: inline-flex;

              .bx--tooltip--definition {
                .bx--assistive-text {
                  z-index: 9999;
                }

                .bx--tooltip--bottom.bx--tooltip--align-start.bx--tooltip--a11y
                  + .bx--assistive-text {
                  transform: translate(-13%, 100%);
                }
                .bx--tooltip--top.bx--tooltip--align-start
                  + .bx--assistive-text {
                  transform: translate(-13%, -98%);
                }
              }

              .unmanaged-icon {
                height: $spacing-04;
                width: $spacing-04;
                border: 2px solid $gray-30;
                border-radius: 50%;
                margin-right: $spacing-02;
                margin-top: $spacing-02;
              }

              .table__gateway__name {
                color: $gray-100;
                display: flex;
                align-items: flex-start;
                @include type-style('body-short-01');

                &.unmanaged {
                  color: $gray-60;

                  .gateway-name {
                    .name {
                      font-weight: 450;
                    }
                  }
                }

                .gateway-name {
                  .name {
                    font-weight: 450;
                  }
                }

                .gateway-type {
                  color: $gray-60;
                  @include carbon--type-style('label-01');
                }
              }

              button {
                border: 0;
              }
            }

            a:hover {
              color: $blue-60;
              text-decoration: none;

              .table__gateway__name {
                color: $blue-60;
              }
            }

            .health-status {
              .bx--tooltip--definition {
                .bx--tooltip__trigger--definition {
                  border: 0;
                }
              }
              .health-status-component {
                align-items: flex-start;
                justify-content: flex-start;

                .label {
                  color: $gray-100;
                }
              }

              &.unmanaged {
                .health-status-component {
                  .label {
                    color: $gray-60;
                  }
                }
              }
            }

            .network-segment {
              color: $gray-100;
              &.unmanaged {
                color: $gray-60;
              }
            }

            .procedural__status {
              .bx--tooltip--definition {
                .bx--tooltip__trigger--definition {
                  border: 0;
                }
              }
            }

            .cloud-and-location-field {
              &.unmanaged {
                .cloud-field {
                  a {
                    color: $gray-60;
                    &:hover {
                      color: $blue-60;
                      text-decoration: none;
                    }
                  }
                }
              }

              .cloud-field {
                @include carbon--type-style('body-short-01');
                color: $gray-100;
                a {
                  @include carbon--type-style('body-short-01');
                  color: $gray-100;

                  &:hover {
                    color: $blue-60;
                    text-decoration: none;
                  }
                }
              }

              .location-field {
                color: $gray-60;
                @include type-style('label-01');
                a {
                  color: $gray-60;
                  @include type-style('label-01');
                  &:hover {
                    color: $blue-60;
                    text-decoration: none;
                  }
                }
              }
            }

            .environment-and-partition-field {
              &.unmanaged {
                .environment-field {
                  a {
                    color: $gray-60;
                    &:hover {
                      color: $blue-60;
                      text-decoration: none;
                    }
                  }
                }
              }

              .environment-field {
                @include carbon--type-style('body-short-01');
                color: $gray-100;
                a {
                  @include carbon--type-style('body-short-01');
                  color: $gray-100;
                  &:hover {
                    color: $blue-60;
                    text-decoration: none;
                  }
                }
              }

              .partition-field {
                color: $gray-60;
                @include type-style('label-01');
                a {
                  color: $gray-60;
                  @include type-style('label-01');
                  &:hover {
                    color: $blue-60;
                    text-decoration: none;
                  }
                }
              }
            }

            .updated-at-field-row {
              color: $gray-100;
              &.unmanaged {
                color: $gray-60;
              }
            }
          }
        }
      }
    }
  }

  .bx--data-table td .bx--tag {
    white-space: nowrap;
    margin: 0 0.25rem 0 0;
  }
}
