@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.sidePanelTableComponent {
  background-color: $white-0;

  .sidePanelTable-header {
    background-color: $white-0;
  }

  .sidePanelTable-header-row th {
    background-color: $white-0;
    border-bottom: 0.063rem solid $gray-20;
  }

  .row-link:hover {
    color: $blue-60;
    cursor: pointer;
  }
  .row-icon {
    display: flex;
    column-gap: 0.313rem;
    padding-top: 0.313rem;
  }
}
