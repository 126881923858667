@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.identity-container {
  .identity-table-tooltip {
    .bx--tooltip--definition {
      position: absolute;
      margin: $spacing-01;

      .bx--assistive-text {
        display: flex;
        flex-direction: column;
        text-align: left;
      }
    }
  }

  .bx--data-table-container {
    .identity__table_name {
      display: flex;
      align-items: center;
    }

    .circle-inner {
      height: $spacing-07;
      width: $spacing-07;
      font-size: $spacing-04 + $spacing-01;
    }
  }
}
