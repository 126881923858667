@import 'carbon-components/scss/globals/scss/typography.scss';

.address-search-component {
  .address-search-bar {
    margin-top: 0.5rem;
  }

  .address-search-list {
    background: #FFFFFF;
    padding: 1rem;
    border-bottom: 1px solid $ui-03;
    display: flex;
  }

  .address-search-div {
    width: 100%;

    .address-search-icon {
      margin-right: 0.5rem;
    }

    .address-search-content {
      width: 100%;
    }

    .address-search-title {
      @include type-style('body-short-01');
      color: $text-01;
    }

    .address-search-subtitle {

      span,
      p {
        @include type-style('caption-01');
        color: $text-02;
      }
    }
  }
}