@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-resource-card {
  min-height: 100%;
  cursor: default;

  .bx--tile--clickable.resource-card1 {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    height: 40px;

    .name {
      @include carbon--type-style('productive-heading-02');
      display: inline-flex;
      align-items: center;
      color: $gray-60;

      &:hover {
        color: $blue-60;

        &.managed {
          color: $blue-60;
        }
      }

      &.managed {
        color: $gray-100;
      }

      .unmanaged-icon {
        height: $spacing-04;
        width: $spacing-04;
        border: 2px solid $gray-40;
        border-radius: 50%;
        margin-right: 0.45rem;
        margin-top: 0.094rem;
      }

      .bx--tooltip--definition {
        .bx--assistive-text {
          z-index: 9999;
        }
      }

      button {
        @include carbon--type-style('productive-heading-03');
        color: $text-01;
        margin-right: 4px;
        border: 0;

        img {
          position: relative;
        }
      }
    }

    .health-status-column {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 0.5rem;

      .health-status {
        text-align: right;
        display: flex;
        width: 50%;
        justify-content: center;
        max-width: 32px;
      }
    }

    .perform-action-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      max-width: 32px;
    }
  }

  .label-text {
    @include carbon--type-style('label-01');
    color: $gray-70;
    margin-bottom: $spacing-02;
  }

  .value {
    @include carbon--type-style('body-short-01');
    color: $gray-100;
    a {
      text-decoration: none;
      @include carbon--type-style('body-short-01');
      color: $gray-100;
      cursor: pointer;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: none;
      cursor: pointer;
    }
    .no-underline-link,
    .bx--tooltip--definition,
    .bx--tooltip__trigger {
      width: 100%;
      display: inline-block;
    }
    .procedural-activity-field {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        display: flex;
        margin-right: $spacing-02;

        svg {
          color: $blue-60;
          fill: $blue-60;
        }
      }

      .status-message {
        @include carbon--type-style('body-short-01');
        color: $gray-100;
      }
    }
  }

  .value,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
    cursor: pointer;
    border: 0;
    color: $gray-100;
  }

  .data {
    margin-bottom: 1rem;
  }

  .card-footer {
    margin-top: 1rem;
    
    .helper-text {
      @include carbon--type-style('helper-text-01');
      color: $gray-70;
    }
  }
}
