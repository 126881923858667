@import 'carbon-components/scss/globals/scss/typography.scss';
.headercontainer {
  display: flex;
  width: 100%;
  margin: 0 -1rem;
  .item.hovered {
    background-color: #dcdcdc;
    cursor: pointer;
  }
  .item {
    flex-grow: 1;
    flex-basis: 0;
    padding: 1rem;
    max-width: 33.33%;

    .title {
      @include carbon--type-style('label-01');
      color: $text-02;
    }
    .value {
      @include carbon--type-style('productive-heading-06');
      color: $text-01;
      display: flex;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }

      img {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}
