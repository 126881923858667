@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.metric-container {
  margin-right: $spacing-05;
  .header {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
  }
  .selection-container {
    display: flex;
    margin-bottom: $spacing-05;
    .bx--dropdown {
      background-color: #f4f4f4;
      height: $spacing-07;
    }
    .chart-switcher {
      display: flex;
      align-self: end;
      cursor: pointer;
      .bx--tooltip--definition .bx--tooltip__trigger {
        border-bottom: 0px;
      }
    }
    .chart-type {
      background-color: #f4f4f4;
      display: flex;
      align-items: flex-start;
      align-items: center;
      justify-content: center;
      width: $spacing-07;
      height: $spacing-07;
      cursor: pointer;
    }
    .selected {
      background-color: #393939;
      // color: #ffffff;
    }
    .bx--dropdown__wrapper.bx--list-box__wrapper {
      flex: 1;
      padding-right: 15px;
    }
  }
  .bx--dropdown__wrapper {
    .bx--dropdown {
      background-color: #f4f4f4;
      height: $spacing-07;
    }
  }
  .child-items {
    margin-bottom: $spacing-05;
  }

  .metric-skeleton-container {
    .chart-skeleton {
      width: 100%;
    }
  }
}
