@import 'carbon-components/scss/globals/scss/colors.scss';

.icon-button-container{
    position: absolute;
     img {
       // margin-top:-0.6rem;
     }
    .bx--btn {
        min-height: 1px;
        width: 1rem;
        height: 1rem;
       background-color: transparent;
    }

    .bx--btn--icon-only {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.1rem;
    }

    .bx--btn--ghost:hover {
        background-color: transparent;
    }

    .bx--btn--ghost:active {
        color: $white-0;
    }
}