@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.delete-button {
  width: 3rem;

  .trash-icon {
    color: #000000;
  }
}

.compact-suggested-table-container {
  margin: 0 -1rem;
  min-height: 6.5rem;

  .compact-suggested-table {
    @include carbon--type-style('body-short-01');
    width: 100%;
    table-layout: fixed;

    .row {
      border-top: 1px solid #dcdcdc;
      color: $text-02;
      max-height: 2rem;

      .col {
        padding: $spacing-03 $spacing-05;
        vertical-align: middle;
      }

      a {
        text-decoration: none;
        color: $text-secondary;
      }
    }

    .row.hovered {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }

    td:last-child {
      word-wrap: break-word;
    }

    .row-value-with-icon {
      display: flex;
      align-items: center;

      .text {
        margin-left: 0.25rem;
      }

      .critical-status-gw-icon {
        filter: invert(14%) sepia(82%) saturate(5900%) hue-rotate(352deg)
          brightness(91%) contrast(86%);
      }

      svg {
        margin-right: 0.25rem;
      }

      a {
        text-decoration: none;
        color: $text-secondary;
        word-break: break-all;
      }

      a:hover {
        color: $hover-primary-text;
        text-decoration: underline;
      }
    }
  }
}
