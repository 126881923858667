@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-legend-container {
  display: flex;
  flex-direction: row;
  background-color: $white-0;
  border-left: 1px solid $gray-20;
  border-top: 1px solid $gray-20;
  margin-top: -6px;
  min-height: 322px;
  min-width: 100%;
  .legend-tabs-main {
    display: inline-block;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    overflow-y: scroll;
    height: 322px;
  }
  .legend-tab::selection {
    border: none;
    outline: none;
    border-bottom: 2px solid $blue-60;
  }
  .legend-tabs-close {
    display: inline-block;
    max-width: 48px;

    .legend-tabs-close-btn {
      color: $gray-100;
      padding-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 48px;
      max-height: 48px;
    }
  }
}
