@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.delete-gateway {

  .delete-gateway-modal {
    .bx--modal-container {
      min-height: 39rem;
    }
  }

  .delete-gateway-description {
    margin-bottom: $spacing-07;
    max-width: $spacing-13 + $spacing-13 + $spacing-09;
  }

  .line-divider {
    margin: $spacing-07 0;
    border: 1px solid $gray-20;
  }

  .delete-gateway-checklist-description {
    max-width: $spacing-13 + $spacing-13 + $spacing-09;
  }

  .delete-gateway-checkbox {
    margin-top: $spacing-05;
    margin-bottom: $spacing-03;
  }

  .multi-group-items {
    margin-left: $spacing-07;

    .multigroup-item-header {
      color: $text-01;
      font-weight: 600;
      font-size: $spacing-04 + $spacing-01;
      line-height: $spacing-05 + $spacing-02;
      margin-top: $spacing-03;
    }

    .multigroup-item {
      margin-bottom: $spacing-02;
    }
  }

  .no-deployment-modal {
    .bx--modal-container {
      max-width: 30rem;
    }

    .bx--modal-content {
      width: 25rem;
    }
  }
}