@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.namespace_security_generic_table {
  .bx--data-table-container {
    margin-bottom: 0px;
  }

  .bx--data-table thead {
    position: sticky;
    top: 0;
  }

  .bx--data-table-content {
    overflow: hidden;
  }

  .bx--data-table tbody {
    overflow-x: auto;
  }

  .name-column-value {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .value {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $gray-70;
    }

    .icon {
      margin-left: 0.25rem;
      width: 0.75rem;
      height: 0.75rem;
      .icon-with-tooltip {
        .bx--btn--ghost:hover {
          background-color: $gray-10;
        }
      }
    }
  }

  .no-underline-link {
    text-decoration: none !important;
  }
  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }
}
