@import 'carbon-components/scss/globals/scss/colors.scss';

.generic-table-title {
  background-color: $white-0;
  height: 5.375rem;
  padding: 1rem 1rem 1.5rem 1rem;

  .bx--row {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 400;
    font-size: 1.24rem;
    line-height: 1.75rem;
    color:$gray-100;
  }

  .description {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color:$gray-70;
  }
}