@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.identity-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;

    .bx--data-table-container {
      padding-top: 0;
    }
  }

  .bx--data-table {
    a {
      color: $text-secondary;
      text-decoration: none;
    }

    tbody tr:hover .identity__table_name a {
      color: $hover-primary-text;
    }
  }

  .policies-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    justify-content: flex-end;

    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;

      button {
        background-color: $ui-background;
        color: $ui-05;
      }

      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }

  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;

    .roles-card-skeleton {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }

  .resource-card {
    margin: $spacing-03 0;
    padding-right: $spacing-03;
    padding-left: $spacing-03;

    .identities-card {
      margin: $spacing-03 0;
      padding-right: $spacing-03;
      padding-left: $spacing-03;
    }
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }

    .roles-names {
      .names:nth-child(1) {
        display: block;
      }
    }
  }

  .cds--grid {
    margin: 0;
  }
}
