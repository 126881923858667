@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-partition-form {
  padding: $spacing-05;

  &.configure {
    .add-labels-component {
      margin-top: 2rem;
      .input-container {
        .input {
          .bx--text-input-wrapper {
            margin-top: 0;
          }
        }
        .button {
          padding-top: 1.5rem;
        }
      }
      .values-container {
        margin-top: 2rem;
      }
    }

    .autodicoveryWrapper {
      margin-top: 3rem;
    }

    .autodiscover-label {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-06;
    }
  }

  .bx--form-item,
  .bx--list-box__wrapper {
    margin-top: $spacing-05;
  }

  .autodicoveryWrapper .bx--form-item {
    margin-top: 0;
    margin-bottom: $spacing-05;
  }

  .add-labels-component {
    width: 98%;

    .input-container .button {
      padding-top: $spacing-08;
    }
  }

  .autodiscover-label {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  .autodiscover-tooltip {
    margin-left: 9px;
  }

  .autodicoveryWrapper {
    display: flex;
    margin-top: $spacing-05;
    .auto-discover-toggles {
      .bx--tooltip--definition {
        button {
          border: none;
        }
      }
    }
  }

  .auto-discover-row {
    flex: 1;
  }
}
