.generic-truncate-string {
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
    font-size: inherit;
    text-align: left;
    color: inherit;
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
    outline: none;
  }
}
