@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.connect-gateway-cluster-tearsheet {
  margin: $spacing-06 $spacing-07;

  .header {
    margin-bottom: $spacing-06;

    .title {
      @include type.type-style('heading-03');
      color: $text-01;
      margin-bottom: $spacing-04;
    }

    .description {
      @include type.type-style('body-01');
      color: $text-secondary;
    }
  }

  .table {
    margin-bottom: $spacing-07;
  }

  .form {
    margin-bottom: $spacing-11;
  }

  .inline-notification-container {
    position: sticky;
    top: 25px;
    z-index: 1;
  }

  #connect-gateway-cluster-table {
    tr {
      cursor: pointer;
    }
  }
}
