@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.select-cloud-container-tearsheet {
  padding: 0.5rem;

  .c4p--tearsheet-create__step--title {
    display: none;
  }

  .heading {
    @include carbon--type-style('productive-heading-03');
  }

  .select-cloud {
    max-width: 90%;
    .bx--label--disabled {
      color: $gray-70;
    }

    .select-cloud-dropdown {
      &.disabled {
        button {
          background: $gray-10;
          border-bottom: 1px solid $gray-30;
          .bx--list-box__label {
            color: $gray-70;
          }
        }
      }

      .bx--list-box__label {
        .option {
          display: flex;
          align-items: center;
          .cloud-icon {
            display: flex;
            margin-right: 8px;
            padding-top: 1px;
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .bx--list-box__menu {
        .bx--list-box__menu-item {
          .bx--list-box__menu-item__option {
            .option {
              display: flex;
              align-items: center;
              .cloud-icon {
                display: flex;
                margin-right: 8px;
                padding-top: 1px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .select-location-dropdown-column {
      display: flex;
      align-items: flex-end;
    }

    .select-location-dropdown-container {
      width: 100%;
      &.focus-on {
        .select-location-dropdown {
          border: 2px solid $blue-60;
          .bx--text-input {
            border-bottom: 1px solid $blue-60;
            &:focus {
              outline: none;
            }
          }
        }
      }
      .select-location-dropdown {
        &.disabled {
          border: none;
          border-bottom: 1px solid $gray-30;
          .bx--list-box__field {
            cursor: not-allowed;
            input {
              background: $gray-10;
              border-bottom: 1px solid $gray-30;
              color: $gray-70;
              pointer-events: none;
            }
            button {
              display: none;
            }
          }
        }
        .bx--list-box__menu {
          .bx--list-box__menu-item {
            height: 3.75rem;
            .bx--list-box__menu-item__option {
              height: 3.75rem;
              .location-option {
                display: flex;
                flex-direction: column;
                .location-name {
                  @include carbon--type-style('body-long-01');
                  color: $gray-100;
                }
                .location-type {
                  @include carbon--type-style('body-long-01');
                  color: $gray-60;
                }
              }
            }
          }
        }
      }
    }

    .bx--list-box__wrapper {
      margin-top: $spacing-05;
    }
  }

  .select-subtype-container {
    padding-top: 36px;

    .heading {
      @include carbon--type-style('productive-heading-03');
    }

    .select-subtype {
      display: flex;
      flex-wrap: wrap;
      margin-top: $spacing-06;

      .cloud-card-border {
        border: $spacing-01/2 solid black;
      }

      .cloud-card {
        height: 112px;
        width: 277px;
        max-width: 277px;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background: #ffffff;
        padding: 1rem 1rem 0.75rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        .cloud-icon {
          height: $spacing-07;
          display: flex;
          flex-direction: row;
          gap: $spacing-03;
          bottom: 0;
          position: absolute;
          margin-bottom: $spacing-05;
        }

        &.focus-on {
          outline: 2px solid $blue-60;
        }
      }

      .title {
        @include carbon--type-style('productive-heading-03');
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .iconsContainer {
        display: flex;

        .icon {
          display: flex;
          margin-right: 0.5rem;
        }
      }

      .wrapInfo {
        display: flex;
        align-items: center;

        .bx--tooltip__trigger--definition {
          cursor: pointer;
        }
        .info-icon {
          margin-top: -0.5rem;
        }
      }

      .selected-cloud {
        margin-top: -0.5rem;
        display: flex;
      }

      .icon-with-tooltip {
        display: flex;
        margin-left: 0.5rem;
        margin-top: 0.25rem;
        position: unset;

        img {
          position: relative;
          top: 4px;
        }

        button {
          padding: 0;
        }
      }
    }

    .select-subtype-empty {
      margin-top: 3.5rem;

      .empty_header {
        @include carbon--type-style('productive-heading-03');
        color: #161616;
      }
      .empty_description {
        @include carbon--type-style('body-long-01');
        color: #525252;
        padding-top: 0.7rem;
      }
    }

    &.cloud-subtype-container {
      .selected-cloud {
        margin-top: -0.5rem;
      }
    }

    .description-container {
      max-width: 12rem;
      .description {
        max-width: 100%;
        display: inline-block;
        direction: ltr;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .generic-truncate-string {
    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
      font-size: inherit;
      text-align: left;
      color: inherit;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
      outline: none;
    }
  }

  .loader-skeleton-container {
    max-width: 90%;
    .skeleton-row {
      .cloud-select-skeleton {
        height: 2.5rem;
        width: 100%;
        margin-top: 2.5rem;
      }
    }
  }

  .generic-notAuthorised-container {
    .label {
      display: flex;
      margin-bottom: 6px;
      @include carbon--type-style('label-01');
      color: $gray-70;
    }
    .generic-status-container {
      display: flex;
      height: 40px;
      align-items: center;
    }
  }
}
