@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import '@carbon/themes/scss/themes';
@import 'carbon-components/scss/globals/scss/spacing.scss';

$carbon--theme: $carbon--theme--g10;
@include carbon--theme();
.policy-allow-connection {
  .connections-container {
    padding: $spacing-05;
  }

  .c4p--tearsheet-create__step--title {
    display: none;
  }
  .c4p--tearsheet-create__step--subtitle {
    display: none;
  }
  .policy-allow-connections-title {
    @include type-style('productive-heading-03');
    color: $text-01;
    margin-bottom: $spacing-04;
  }
  .policy-allow-connections-subtitle {
    @include type-style('label-02');
    color: $text-02;
    width: 65%;
  }
  .policy-allowConnection-networkSegment {
    margin-top: $spacing-07;
    margin-bottom: $spacing-08;
    padding-right: $spacing-06;
    .policy-allowConnection-dropdown {
      @include carbon--type-style('label-01');
      color: $text-02;
    }

    .policy-allowConnection-dropdown.disabled {
      pointer-events: none;
      input {
        background-color: $ui-background;
      }
    }
    .policy-networkSegment-skelton {
      display: flex;
      justify-items: center;
      width: 100%;
      height: $spacing-05 + $spacing-01;
      margin-top: $spacing-07;
      margin-bottom: $spacing-07 + $spacing-03;
    }

    .service-sync-setting {
      .label {
        display: flex;
        align-items: center;
        .bx--tooltip--definition {
          margin-left: $spacing-02;
        }
      }
    }
  }

  .policy-allowConnection-fromToContainer {
    display: flex;
    min-height: 344px;
    position: relative;

    .from-container,
    .to-container {
      display: flex;
      flex: 1;
      position: relative;
    }

    .from-container {
      margin-right: 2rem;
    }

    .selected.allowHoverBackground {
      :hover {
        background-color: $ui-03;
      }
    }

    .selected.dontAllowHoverBackground {
      :hover {
        background-color: none;
      }
    }

    .to-container {
      margin-left: 2rem;
    }

    .connection-line {
      position: absolute;
      top: 52.6%;
      right: 47%;
      width: 4rem;
      height: 1px;
      background-color: $gray-40;
      z-index: -1;
    }
  }
}

.policy-allow-connection.edit {
  .bx--inline-notification.notification.info {
    background-color: #edf5ff;
    border: 1px solid rgba(0, 67, 206, 0.3);
    border-left: 3px solid $link-02;
  }
}
