@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/theme.scss';
@import 'carbon-components/scss/globals/scss/theme.scss';

.container {
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: $ui-01;
  height: 100%;

  .svg-container {
    background-color: $ui-background;
    padding: 2rem;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .button-container {
    padding-left: 2rem;
    // display: flex;
    justify-content: center;
    align-items: center;

    .text-container {
      padding-bottom: 2rem;
      .bold {
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 671px) {
    .svg-container {
      img {
        width: 100%;
        height: 50vh;
      }
    }

    .button-container {
      height: 50vh;
    }
  }

  & + .toast-notification-container {
    top: 0;
  }
}
