@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-zoom-options {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: $spacing-10;
  right: $spacing-05;
  background-color: $white-0;

  .bx--btn--ghost {
    padding: $spacing-03;
    min-width: $spacing-05;
    min-height: $spacing-05;

    .bx--btn__icon {
      margin: 0;
      color: $black-100;
      height: $spacing-05;
      width: $spacing-05;
    }
  }
}
