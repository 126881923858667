@import 'carbon-components/scss/globals/scss/spacing.scss';

.register-partition-form {
  padding: 0 $spacing-05;

  .bx--form-item,
  .bx--list-box__wrapper {
    margin-top: $spacing-05;
  }

  .autodicoveryWrapper .bx--form-item {
    margin-top: 0;
    margin-bottom: $spacing-05;
  }

  .add-labels-component {
    width: 98%;

    .input-container .button {
      padding-top: $spacing-08;
    }
  }

  #secret-label {
    position: relative;
    top: 3px;
  }

  .autodiscover-row {
    flex-wrap: unset;
    width: 100%;
    margin-top: 1rem;

    .bx--list-box__wrapper {
      width: 100%;
      padding-right: 0;
      margin-left: $spacing-05;
    }
  }

  .autodiscover-toggle-group {
    padding-left: $spacing-05;
    .label {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .bx--assistive-text {
        left: $spacing-11 + $spacing-01;
      }

      .autodiscover-tooltip {
        margin-left: $spacing-02;
      }
    }

    .autodiscover-toggle {
      margin-right: $spacing-12 + $spacing-03;
      .bx--toggle__switch {
        margin-top: 0;
      }
    }
  }
}
