@import 'carbon-components/scss/globals/scss/spacing.scss';

.toast-notification-container {
  position: fixed;
  top: $spacing-10;
  right: -($spacing-13 + $spacing-13);
  z-index: 10000;
  
  animation-name: moveRightToLeft;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  @keyframes moveRightToLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-($spacing-13 + $spacing-13));
    }
  }
}
