@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.detailsAccordion {
  background-color: $white-0;
  padding: 0;

  .bx--accordion__title {
    @include carbon--type-style('heading-02');
    color: $gray-100;
  }

  .bx--accordion__heading:hover::before {
    background-color: $white-0;
  }

  .sidePanelDetailsTab {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .label {
      @include carbon--type-style('label-01');
      color: $gray-70;
    }

    .value {
      @include carbon--type-style('body-short-01');
      margin-top: $spacing-02;
      color:$text-01;
    }

    .not-authorized-icon {
      display: flex;
      align-items: center;

      .value {
        margin-left: $spacing-02;
      }
    }

    .hyperLink {
      @include carbon--type-style('body-short-01');
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $blue-60;
      }
    }

    .iconRoot {
      display: flex;
      flex-direction: row;
      column-gap: $spacing-05;
      margin-left: $spacing-02;
    }

    .iconWithValue {
      display: flex;
      flex-direction: row;
      margin-top: $spacing-02;
      margin-left: -$spacing-01;
      line-height: 1.125;
    }

    .iconValue {
      margin-left: $spacing-02;
    }

    .linkIcon {
      margin-right: $spacing-02;
      img {
        margin-top: 0.375rem;
      }
    }
  }

  @media (min-width: 640px) {
    .bx--accordion__content {
      padding-right: 0;
    }
  }

  @media (min-width: 480px) {
    .bx--accordion__content {
      padding-right: 0;
    }
  }

  .bx--accordion__heading {
    border-top: 1px solid $gray-20;
  }

  .bx--accordion__item:last-child {
    border-bottom: none;
  }
}
