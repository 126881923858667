@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-service-details-container {
  .header-title {
    display: flex;

    .page-title-icon {
      margin-left: 5.5px;
      margin-top: $spacing-03;
    }
  }
  .bx--accordion__item {
    border-bottom: none;
  }

  .bx--accordion__content {
    padding-left: $spacing-05;
    padding-top: $spacing-02;
  }

  .service-header {
    padding-left: $spacing-05;
  }

  .service-header .app-heading {
    margin-bottom: $spacing-02;
    @include carbon--type-style('label-01');
    color: #525252;
  }
  .skeleton-container {
    width: 90%;
    margin: 0 auto;
    margin-left: 0;
  }

  .skeleton-container .service-skeleton {
    height: 1.5rem;
    width: 100%;
    margin: 10px 0;

    &:first-child {
      margin-bottom: 18px;
    }
    &:nth-child(2) {
      width: 65%;
      height: 1rem;
    }
    &:nth-child(3) {
      width: 50%;
      margin-bottom: $spacing-07;
      height: 1.125rem;
    }
  }

  // .side-panel-empty-state {
  //   padding: $spacing-05;
  // }

  .sidePanelDetailsTab {
    padding-bottom: $spacing-05;
  }

  .bx--accordion__content {
    padding-bottom: $spacing-05;
  }

  .bx--data-table th,
  .bx--data-table td {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
  }
  .bx--data-table {
    tbody {
      tr {
        height: $spacing-07;

        &:hover {
          background: transparent;

          td {
            background: transparent;
            border: transparent;
          }
        }
      }
    }
  }
}
