@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.roles-card {
  min-height: 100%;

  .header {
    margin-bottom: $spacing-05;
    .name {
      @include carbon--type-style('heading-02');
    }
  }

  .helper-text {
    @include carbon--type-style('helper-text-01');
    color: $text-05;
  }

  .label-text {
    @include carbon--type-style('label-01');
    color: $text-02;
  }

  .value,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
  }

  .data {
    margin-bottom: $spacing-05;
  }

  .from {
    margin-bottom: $spacing-05;
  }

  .tags {
    margin-left: unset;
    margin-bottom: $spacing-06;

    .bx--tag {
      margin-left: 0;
    }
  }

  .descContent {
    text-align: left;
  }

  .resource-name {
    text-align: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .icon {
    margin-left: $spacing-02;
    width: $spacing-04;
    height: $spacing-04;
    display: inline-block;

    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
      position: absolute;
      margin-bottom: $spacing-03;
    }

    .icon-with-tooltip {
      margin-left: $spacing-02;
      .bx--btn--ghost:hover {
        background-color: transparent;
      }
    }
  }
  .name-row {
    display: flex;
    align-items: center;
  }
}
