@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.bx--data-table {
  td {
    background: white;
  }

  .emptyContainerCol {
    border-top: none;
    border-bottom: none;
    background-color: none;
    background: none;
    color: #6f6f6f;
  }
  .bx--btn {
    max-width: fit-content;
  }
  .emptyContainerCol .header {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
  }
  .emptyContainerCol .description {
    @include carbon--type-style('body-long-01');
    margin-bottom: $spacing-05;
    color: $text-01;
  }
  .emptyContainer:hover  td{
    background-color: white;
    margin-top: none;
    margin-bottom: none;
}
}

.bx--data-table-container {
  margin-bottom: 0px;
  padding-top: 0px;

  .bx--toolbar-content{
    z-index:10;
  }
}

.tablePagination {
  background : white;
  height: 3rem;
  .bx--select-input {
    background-color: white;
  }
  .bx--pagination__control-buttons {
    height: 100%;
    button {
      height: 100%;
    }
}
}
.emptyContainer {
  background-color: white;
  .bx--btn {
    max-width: fit-content;
  }
}