@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.connect-gateway-tearsheet {
  padding: 0.5rem;
  .connect-gateway-container {
    .tear-sheet-heading {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-06;
    }

    .tile-section {
      padding-bottom: 2.5rem;

      .connect-gateway-tile {
        min-height: 164px;
        height: 100%;
        width: 32%;
        margin-right: 1rem;
        margin-bottom: 1rem;
        background: #ffffff;
        padding: 1rem 1rem 0.75rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        &.disabled {
          border: 1px solid $gray-30;
          pointer-events: none;
          cursor: not-allowed;

          .tile-content {
            .tile-heading,
            .tile-description {
              color: $gray-60;
            }
          }
        }

        .tile-content {
          .tile-icons {
            position: relative;

            .redhat-icon {
              img {
                width: 30px;
                height: 25px;
              }
            }
            .selected-icon {
              position: absolute;
              right: 0;
            }
            .outline-icon {
              filter: invert(45%) sepia(4%) saturate(9%) hue-rotate(16deg)
                brightness(93%) contrast(80%);
            }
          }

          .tile-heading {
            @include carbon--type-style('productive-heading-03');
            margin-bottom: $spacing-05;
            margin-top: $spacing-05;
          }

          .tile-description {
            @include carbon--type-style('body-long-01');
          }
        }

        .cloud-icon {
          height: $spacing-07;
          display: flex;
          flex-direction: row;
          gap: $spacing-03;
          bottom: 0;
          position: absolute;
          margin-bottom: $spacing-05;
        }
      }
    }

    .divider {
      display: block;
      background: $gray-20;
      height: 1px;
      width: calc(100% + 48px);
      margin-left: -24px;
    }
    .description-section {
      padding-top: $spacing-06;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .description-content {
        display: flex;
        flex-direction: column;
        flex: 0 0 44%;
        justify-content: center;

        .title {
          margin-top: $spacing-09;
          @include carbon--type-style('productive-heading-03');
        }
        .description {
          margin-top: $spacing-05;
          @include carbon--type-style('body-long-01');
          color: $gray-70;
        }
      }

      .connectGatewayStepImage {
        display: flex;
        flex: 0 0 40%;
        justify-content: center;
      }
    }
  }
  .no-gateway-container {
    .heading {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-03;
      margin-top: $spacing-05;
    }
    .description {
      @include carbon--type-style('body-long-01');
    }
  }
}
