@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.manage-button-disabled,
.manage-button {
  min-width: 159px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $white-0;
  padding: 16px 10px 16px 10px;
  height: 40px;
  .bx--checkbox:checked + .bx--checkbox-label::before,
  .bx--checkbox:indeterminate + .bx--checkbox-label::before,
  .bx--checkbox-label[data-contained-checkbox-state='true']::before,
  .bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
    border-color: $link-primary;
    background-color: $link-primary;
  }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: 0 dotted $gray-70;
  }
  .bx--checkbox-label-text {
    font-family: IBM Plex Sans;
    font-size: $spacing-04 + $spacing-01;
    font-weight: 400;
    line-height: $spacing-05 + $spacing-01;
  }
}
.manage-button {
  padding: 16px 10px 16px 14px;
  .bx--checkbox-wrapper {
    position: relative;
    cursor: pointer;

    & input:checked ~ .manage-button-checkbox::before {
      transform: scale(0.9);
    }
    & input:checked ~ .manage-button-checkbox {
      background: $link-primary;
      border-color: $link-primary;
      animation: animate 0.7s ease;
    }
    & input {
      position: absolute;
      opacity: 0;
    }
    .bx--checkbox-label {
      padding-top: 0;
      padding-left: 0;
      min-height: 1rem;
      &::after {
        top: 0.675rem;
        left: 0.125rem;
        transform-origin: top left;
      }
      .bx--checkbox-label-text {
        padding-left: 1.475rem;
        position: absolute;
        top: -2px;
      }
    }
    .manage-button-checkbox {
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border: 1px solid $gray-100;
      transition: 0.5s;
    }

    .manage-button-checkbox::before {
      content: '';
      position: absolute;
      border: 1px solid $gray-100;
      top: -3px;
      left: -4px;
      width: 1rem;
      height: 1rem;
      transform: scale(0);
      transition: 0.5s;
      margin: 0.125rem 0.125rem 0.125rem 0.1875rem;
      background-color: transparent;
    }
    .bx--checkbox:focus + .bx--checkbox-label::before {
      outline: none;
    }
  }
}
.manage-button-disabled {
  .bx--checkbox-wrapper {
    padding-right: 10px;
  }
  .bx--checkbox-label::after {
    border-bottom: $spacing-01 solid $gray-100;
    border-left: $spacing-01 solid $gray-100;
  }
  .bx--checkbox:disabled + .bx--checkbox-label,
  .bx--checkbox-label[data-contained-checkbox-disabled='true'] {
    color: unset;
  }
  .bx--checkbox:checked + .bx--checkbox-label::before,
  .bx--checkbox:indeterminate + .bx--checkbox-label::before,
  .bx--checkbox-label[data-contained-checkbox-state='true']::before,
  .bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
    border-width: 1px;
    background-color: $white-0;
    border-color: $gray-30;
  }
}
