@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.policy-define-details {
  padding: $spacing-05;
  .c4p--tearsheet-create__step--title {
    display: none;
  }
  .policy-define-details-title {
    @include type-style('productive-heading-03');
    color: $text-01;
    margin-bottom: $spacing-06;
  }
  .c4p--tearsheet-create__step--fieldset {
    margin: 0;
  }
  .row {
    margin-bottom: $spacing-07;
    .policy-define-details-dropdown {
      display: inline-block;
      @include carbon--type-style('label-01');
      color: $text-02;
    }
    .bx--list-box__wrapper {
      display: flex;
      flex-direction: column;
      .policy-define-details-resourceGroup {
        height: $spacing-08;
      }
    }
    .add-labels-component {
      .input-container {
        .input {
          flex: 1;
          margin-right: $spacing-03;
        }
        .button {
          width: auto;
          padding-right: 0;
          button {
            width: 100%;
          }
        }
      }
    }
    .bx--combo-box .bx--list-box__selection {
      display: none;
    }
  }
}
