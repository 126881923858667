@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.location-deployment-environment-details {
  .header-container {
    .title-container {
      .page-title-main {
        align-items: center;
      }
    }
  }
  .page-content {
    background-color: $ui-01;
    padding: 1rem;
    .details-tile {
      margin-bottom: 1rem;
      .header {
        .bx--btn--ghost:hover {
          background-color: unset;
        }
      }
    }
  }

  .details-tile-skeleton {
    width: 100%;
    min-height: 20rem;
  }

  .bx--data-table-header,
  .bx--table-toolbar,
  td {
    background: white;
  }

  .bx--child-row-inner-container {
    width: 74.25%;
    float: right;
  }

  .bx--pagination {
    background-color: $ui-background;

    select {
      background-color: $ui-background;
    }
  }

  .table-header {
    width: 25%;
  }
  .generic-table-title {
    margin-bottom: -1rem;
  }

  .services-table {
    .headers {
      border-bottom: 1px solid $ui-03;
    }
    .header {
      @include carbon--type-style('productive-heading-01');
      color: $text-01;
      padding: 1rem;
    }

    .cell {
      padding: 1rem;
    }
  }
  .bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--off,
  .bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--on {
    margin-left: $spacing-09;
  }
  .bx--form-item {
    margin-right: $spacing-05;
  }

  // Styles related to managed toggle
  .header-wrapper {
    padding-right: 0;
  }

  .tooltip-container {
    .bx--form-item {
      margin-right: 0;
    }

    .label {
      @include carbon--type-style('label-01');
      color: $text-02;
      letter-spacing: 0.32px;
    }

    .bx--toggle__switch {
      margin-top: $spacing-03;
    }

    .bx--tooltip--definition {
      button {
        border: none;
      }
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end
      + .bx--assistive-text {
      right: $spacing-02;
    }
  }
  .header-icon-contaniner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon-text {
      padding-left: 4px;
    }
    .managed-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $interactive-01;
      background-color: $interactive-01;
      border-radius: 50%;
    }
    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
    }
    &.unmanaged {
      .icon-text {
        color: $gray-60;
      }
    }
  }

  .autodiscovered-icon-contaniner {
    position: relative;

    .icon-with-tooltip {
      left: 0;
      bottom: -14px;
    }
  }

  .delete-location {
    margin-top: $spacing-05;
    margin-bottom: 4rem;
  }

  .delete-location-button-disabled {
    pointer-events: none;
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
  }

  .locationDetailsDeleteDisabled {
    border-color: transparent;
    background: transparent;
    color: #c6c6c6;
    outline: none;
    cursor: not-allowed;
    box-shadow: none;
  }

  .delete-location-modal {
    .bx--modal-container {
      height: 13.25rem;
    }
  }

  .details-tile .data-container .value .bx--tag {
    margin-left: 0;
    margin-right: $spacing-04;
  }
}
