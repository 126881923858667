@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.error403-state {
  display: flex;
  flex-direction: column;
  min-width: 99.25%;
  min-height: 20rem;
  background-color: #ffffff;
  padding: 30px;
  border: 1px solid #ffffff;
  margin-left: 5px;
  padding-right: 10px;

  .header {
    @include carbon--type-style('productive-heading-03');
    padding-top: 10px;
  }

  .description {
    @include carbon--type-style('body-short-01');
    padding-top: 10px;
  }

  .error403-state-icon {
    padding-top: 30px;
  }
}
