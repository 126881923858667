@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.assign-roles {
  .c4p--tearsheet-create__step--title {
    display: none;
  }
  .c4p--tearsheet-create__step--subtitle {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
  }
  .row-add {
    display: flex;
    align-items: flex-end;
  }
  .role-header-title {
    height: 42px;
    @include carbon--type-style('productive-heading-01');
    color: $text-01;
    border-bottom: 1px solid $ui-03;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }
  .role-row {
    height: 68px;
    @include carbon--type-style('body-long-01');
    color: $text-02;
    border-bottom: 1px solid $ui-03;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
  }
  .remove-button-container {
    button {
      color: $text-primary;
    }
  }
}
