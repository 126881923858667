@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-tooltip-disabled {
  background-color: $white-0;
  max-width: $spacing-13;
  min-height: $spacing-08;

  color: $text-01;
  border-left: 3px solid $gray-30;

  .body {
    padding: $spacing-03;

    .label {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
    }
  }
}
