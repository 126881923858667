@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.resource-groups-table {
  .bx--data-table-content {
    table.bx--data-table {
      tbody {
        tr {
          td {
            .action-buttons {
              visibility: hidden;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .edit-resource-group {
                margin-right: 8px;
              }

              .delete-resource-group {
                margin-left: 8px;
              }

              .edit-resource-group,
              .delete-resource-group {
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.disabled {
                  cursor: not-allowed;

                  .icon-with-tooltip {
                    button {
                      cursor: not-allowed;
                    }
                  }

                  button {
                    color: $gray-70;

                    .bx--assistive-text {
                      transform: translate(-90%, 100%);
                    }
                  }
                }

                &:hover {
                  background-color: $gray-30;
                }

                button {
                  color: $black-100;
                }
              }
            }
          }

          &:hover {
            cursor: pointer;
            background-color: $background-hover;
            .action-buttons {
              visibility: visible;
            }
          }
        }
      }
    }
  }

  td:nth-child(1),
  td:nth-child(4),
  th:nth-child(4) {
    white-space: nowrap;
  }

  .resource-name {
    text-align: initial;
  }

  .bx--tooltip--definition.bx--tooltip--a11y .bx--assistive-text {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    td:nth-child(1),
    td:nth-child(4),
    th:nth-child(4) {
      white-space: normal;
    }
  }
}
