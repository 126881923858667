@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.side-panel-component {
  background-color: $white-0;
  box-shadow: none !important;
  border-left: $spacing-01 solid #e0e0e0;

  .c4p--side-panel__inner-content {
    position: relative;

    .header-title {
      margin-right: $spacing-04;
      display: flex;

      .page-title-icon {
        margin-left: $spacing-03;
        margin-top: $spacing-03;

        .icon-with-tooltip {
          z-index: 10;

          img {
            margin-top: -$spacing-03;
          }

          .bx--btn .bx--assistive-text {
            max-width: $spacing-12 + $spacing-07;
          }
        }
      }
    }
  }

  .c4p--side-panel__subtitle-text {
    @include carbon--type-style('productive-heading-03');
    color: $text-primary;
  }

  .bx--accordion__heading {
    padding: 14px 0;

    .bx--accordion__arrow {
      margin-top: 4px;
    }
  }

  .bx--accordion__title {
    @include carbon--type-style('productive-heading-02');
    color: $text-01;
  }

  .side-panel-accordion-items {
    border-top: 0;
  }

  .c4p--side-panel__action-toolbar {
    background-color: $white-0;
  }

  .bx--btn.c4p--side-panel__close-button {
    background-color: $white-0;
    right: $spacing-03;
  }

  .c4p--side-panel__body-content {
    padding: 0;

    .bx--tab-content {
      padding: 0;
    }
  }

  .bx--accordion__item--active .bx--accordion__content {
    padding-right: 16px;
    padding-top: 0rem;
  }

  .no-underline-link {
    text-decoration: none !important;
  }

  a {
    text-decoration: none;
    color: $text-01;
  }

  a:hover {
    color: $blue-60;
    text-decoration: none !important;

    .bx--tooltip__trigger {
      color: $hover-primary-text;
      cursor: pointer;
    }

    svg {
      color: $text-secondary;
    }
  }
}

.side-panel-container {
  .c4p--side-panel__container {
    z-index: 7000;
    .c4p--side-panel__subtitle-text {
      &.c4p--side-panel__subtitle-without-title {
        overflow: unset;
      }
    }
  }

  #Draggable {
    background: #e0e0e0;
    cursor: ew-resize;
    height: 100%;
    top: 0;
    position: absolute;
    width: 1px;
    z-index: 10;
    opacity: 1;

    &.highlightBorder {
      width: $spacing-01;
      background: $gray-30;
    }

    &:hover {
      width: $spacing-01;
      background: $gray-50;
    }

    &:focus {
      width: $spacing-02;
      background: $blue-60;
    }

    &.dragModeOn {
      width: $spacing-02;
      background: $blue-60;
    }
  }
}
