@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.event-details {
  background-color: $ui-01;

  .header-container {
    .resource-icon img {
      filter: invert(12%) sepia(83%) saturate(5796%) hue-rotate(562deg)
        brightness(120%) contrast(185%);
    }

    .resource-icon-new-tab img {
      filter: invert(12%) sepia(83%) saturate(5796%) hue-rotate(562deg)
        brightness(120%) contrast(185%);
    }
    svg.sub-heading-launch {
      fill: #0f62fe;
    }
    .resource-id-text {
      padding: 0 $spacing-02;

      .icon {
        padding-right: $spacing-02;

        img {
          margin-bottom: -3px;
        }
      }
    }

    .depl-name-details-card {
      top: -3px;
      position: relative;
      padding: 0 0.25rem;
    }
  }

  .title-get-message {
    display: flex;
    align-items: center;
    .generic-responsive-middle-truncation {
      padding-left: $spacing-03;
    }
  }

  .link-header-title {
    position: relative;
    top: $spacing-01;
    left: $spacing-03;
    align-items: center;
  }

  .edit {
    margin-right: -0.5rem;
  }

  .dropdown-status-change {
    width: 9.375rem;
    margin-left: 0.75rem;

    .security--combo-button {
      display: flex;
      column-gap: 0.063rem;

      .security--combo-button__overflow-menu {
        background-color: #0f62fe;
        color: white;
        min-width: 3rem;
        height: 3rem;
      }

      .bx--overflow-menu.bx--overflow-menu--open,
      .bx--overflow-menu.bx--overflow-menu--open .bx--overflow-menu__trigger {
        box-shadow: 0;
      }

      ul {
        position: absolute;
        opacity: 1;
        right: auto;
        margin-top: 0.25rem;
      }

      .bx--overflow-menu.bx--overflow-menu--open {
        box-shadow: none;
      }

      .bx--overflow-menu-options__option {
        height: 3rem;
      }
    }
  }

  .depl-name-header {
    padding: 0 0.438rem;
    top: -0.125rem;
    position: relative;
  }

  .event-details-card {
    padding: 1rem;

    .resource-icon img {
      filter: invert(12%) sepia(83%) saturate(5796%) hue-rotate(562deg)
        brightness(120%) contrast(185%);
    }
    .resource-icon-new-tab img {
      filter: invert(12%) sepia(83%) saturate(5796%) hue-rotate(562deg)
        brightness(120%) contrast(185%);
    }

    .link-card-title {
      position: relative;
      top: $spacing-01;
      display: flex;
      align-items: center;
      display: flex;
    }

    .value {
      @include type-style('body-short-01');
      color: $text-01;
      margin-top: $spacing-02;
    }

    .undefined-icon {
      margin-top: $spacing-02;
    }

    .bold-text .value {
      font-weight: 600;
    }

    .section-second-row {
      margin-top: $spacing-06;
    }

    .section-row {
      display: flex;
    }

    .section-row-item {
      flex: 1;
    }

    .depl-name-details-card {
      padding: 0 $spacing-02;
      position: relative;
      top: -0.063rem;
    }
  }

  .action-buttons {
    display: flex;
    height: 48px;
  }

  .status-button {
    display: flex;
    margin-right: 0.8rem;
    column-gap: $spacing-06;

    .text-status {
      margin: auto;
      min-width: 8.75rem;
      text-align: right;

      span {
        font-weight: 700;
      }
    }

    .tenant-dropdown {
      button {
        background-color: #0f62fe;
      }

      .bx--list-box__field:focus {
        outline: 2px solid #0f62fe;
      }

      .bx--list-box__menu-item__option {
        color: #525252;
      }

      .bx--list-box__menu-item:hover {
        color: #525252;
      }
    }

    .tenant-dropdown .bx--list-box__menu-item {
      background-color: #ffffff;
    }

    .bx--dropdown--inline .bx--list-box__menu {
      min-width: 7rem;
      max-width: 12rem;
    }
  }

  .event-details-card {
    background-color: $ui-01;
    padding-top: $spacing-05;

    .details-tile-skeleton {
      width: 100%;
      min-height: 20rem;
    }

    .details-tile {
      .data-container .value:nth-child(4) {
        white-space: normal;
      }
    }
  }

  .eventDeteteButton {
    margin-left: $spacing-05;
  }

  .delete-event-modal {
    .bx--modal-container {
      width: 35rem;
      height: 15.25rem;
    }
  }

  .undefinedValue {
    display: flex;
    column-gap: 0.3125rem;
  }

  .combo-btn-menu-reassign {
    .bx--overflow-menu-options {
      width: 10.3125rem !important;
    }
  }

  .bx--overflow-menu-options {
    width: 10.625rem !important;
  }

  .delete-btn-skeleton {
    margin-left: $spacing-05;
    height: $spacing-09;
  }

  .line-break {
    white-space: pre-wrap;
  }

  .resouce-name {
    display: flex;

    .icon {
      padding-right: $spacing-02;

      img {
        margin-bottom: -3px;
      }
    }
  }
}
