.account-modal {
  .bx--modal-container {
    height: 80%;
    width: 40%;
  }

  .bx--modal-close {
    display: none;
  }

  .account-dropdown {
    width: 250%;
  }
}
