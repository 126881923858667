@import 'carbon-components/scss/globals/scss/typography.scss';

.label-tags {
  .c4p--tag-set__tag-container {
    white-space: normal;
  }
}

.label-tag-set {
  z-index: 10000;
  padding: $spacing-03 + $spacing-01;

  .c4p--tag-set-overflow__tag-item {
    span {
      font-size: $spacing-04;
    }
  }
}

.c4p--tag-set-modal {
  .bx--modal-close {
    display: block;
  }
}
.label-tags {
  .bx--tag--outline {
    background-color: $white-0;
  }
}
