@import 'carbon-components/scss/globals/scss/typography.scss';

.create-role-permissions-step {
  .c4p--tearsheet-create__step--title {
    display: none;
  }

  .c4p--tearsheet-create__step--subtitle {
    @include type-style('productive-heading-03');
    color: $text-01;
    margin-bottom: $spacing-03;
    margin-left: $spacing-03;
  }

  .role-permission-tab-contained {
    padding-left: 0.5rem;
  }

  .role-permission-tab {
    width: $spacing-13 + $spacing-04;
  }

  .cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected {
    background-color: #fff;
  }

  .role-permission-tab-panel {
    padding-top: 0rem;
    padding-left: 0.5rem;
  }
}