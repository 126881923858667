@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.cloud-search {
  position: relative;
  background-color: $white-0;
  height: $spacing-09;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;

  .bx--search-magnifier {
    .icon-with-tooltip {
      top: 12px;
      left: 14px;

      button {
        margin-top: 1.5px;

        .bx--assistive-text {
          transform: translate(-10%, 100%);
        }

        svg {
          fill: $gray-70;
          overflow: initial;
        }
      }
    }
  }

  input {
    border-bottom-color: transparent;
  }

  .vertical-empty-state {
    position: absolute;
    background: $white-0;
    width: 100%;
    z-index: 100;
    min-height: unset;
    padding: $spacing-07;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

    .header,
    .description {
      background: none;
      border: none;
      padding: 0;
      color: $text-01;
      height: unset;
    }

    .header {
      @include type.type-style('body-short-02');
    }

    .description {
      @include type.type-style('caption-01');
      margin-bottom: 0;
    }

    .empty-state-icon {
      img {
        height: 64px;
        width: 64px;
      }
    }
  }

  .search-container {
    position: absolute;
    background: $white-0;
    width: 100%;
    z-index: 9999;
    padding: $spacing-05 0;
    max-height: calc(100vh - 156px);
    overflow: scroll;

    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);

    .session {
      .heading {
        @include carbon--type-style('label-01');
        color: $gray-50;
        padding: 0 $spacing-05;
      }

      &:nth-child(2),
      &:nth-child(3) {
        .heading {
          padding-top: $spacing-05;
        }
      }

      .list {
        display: flex;
        align-items: center;
        min-height: 42px;
        cursor: pointer;
        padding: 0 $spacing-05;
        color: $text-primary;

        &:hover {
          background: $gray-10;
        }

        &.active {
          background: $gray-10;
        }

        .seperator {
          padding: 0 $spacing-03;
        }

        .icon {
          margin-top: $spacing-02;
          margin-right: $spacing-03;
        }

        .title {
          @include type.type-style('body-short-01');
          color: $text-secondary;

          b {
            font-weight: 700;
          }
        }

        .address-session {
          @include type.type-style('body-short-01');
          color: $text-secondary;

          display: flex;
          align-items: center;
          margin-left: $spacing-03;

          .name {
            padding-left: $spacing-01;
          }
        }

        .subtype {
          @include type.type-style('body-short-01');
          color: $text-secondary;
          margin-left: $spacing-03;

          b {
            font-weight: 700;
          }
        }
      }
    }
  }
}
