@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.add-application-form {
  padding: 1rem;
  margin-left: $spacing-05;
  margin-top: $spacing-05;

  .cds--form-item,
  .cds--list-box__wrapper,
  .add-labels-component {
    margin-bottom: 1rem;
  }

  .row {
    margin-bottom: $spacing-05;
  }

  .disabled-segment {
    margin-top: -$spacing-03;
    background-color: $gray-10 !important;
    border-bottom: 0.063rem solid $gray-50;
    color: $gray-70;
  }

  .segment-set-label {
    @include carbon--type-style('label-01');
    color: $gray-70;
    display: flex;

    .info-tooltip-text {
      padding-left: $spacing-03;
      color: $gray-70;
      border: none;
      background-color: $gray-10;
    }
  }
}