@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.cloud-table {
  td:nth-child(1),
  td:nth-child(4),
  th:nth-child(4) {
    white-space: nowrap;
  }

  .resource-name {
    text-align: initial;
  }

  .bx--tooltip--definition.bx--tooltip--a11y .bx--assistive-text {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    td:nth-child(1),
    td:nth-child(4),
    th:nth-child(4) {
      white-space: normal;
    }
  }
  .no-underline-link {
    text-decoration: none !important;
  }
  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }

    .auto-discover-label {
      .auto-discover-label-item {
        display: flex;
        align-items: center;

        .completed-icon,
        .errored-icon,
        .in-progress-icon,
        .pending-icon {
          line-height: 1;
        }
        .errored-icon {
          filter: invert(14%) sepia(82%) saturate(5900%) hue-rotate(352deg)
            brightness(91%) contrast(86%);
        }

        .pending-icon {
          line-height: 1;
          filter: invert(20%) sepia(77%) saturate(2871%) hue-rotate(210deg)
            brightness(91%) contrast(123%);
        }
        .value {
          margin-left: 10px;
        }
      }
    }
  }
}
