@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.topology-gateway-connection-details-container {
  .header-title {
    svg {
      margin-right: 4px;
      position: relative;
      top: 2px;
    }
    .title-styling {
      width: 100%;
      .gateway-from {
        display: flex;
        flex-direction: row;

        .gateway-from-title-truncate {
          margin-right: -2.7rem;
        }
      }
      .gateway-to {
        padding-left: 1.2rem;
      }
    }
  }
  .skeleton-container {
    padding-right: $spacing-05;

    .gateway-connection-details-skeleton {
      width: 100%;

      &:nth-child(n) {
        width: 40%;
        max-height: 14px;
        margin-bottom: $spacing-05;
      }
      &:nth-child(2n) {
        width: 100%;
        max-height: 18px;
      }
    }
  }

  .no-underline-link {
    color: $text-01;
  }
}
