@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.resource-card {
  min-height: 100%;
  cursor: pointer;
  position: relative;

  .header {
    margin-bottom: $spacing-05;

    .name {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include carbon--type-style('heading-02');
      .generic-truncate-string .bx--tooltip__trigger {
        @include carbon--type-style('heading-02');
      }
    }
  }

  .helper-text {
    @include carbon--type-style('helper-text-01');
    color: $text-05;
    position: absolute;
    bottom: $spacing-05;
  }

  .label-text {
    @include carbon--type-style('label-01');
    color: $text-02;
    position: relative;
  }

  .policy-column {
    padding-left: 1.625rem;

    .label-text::before {
      content: '';
      width: 0.125rem;
      height: 0.125rem;
      border-radius: 50%;
      position: absolute;
      border: 0.1rem solid $gray-30;
      left: -0.25rem;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .from .label-text::after {
      content: '';
      border: solid $gray-30;
      border-width: 0.1rem 0 0.1rem 0.1rem;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -2%);
      left: -0.5rem;
      height: 3rem;
      width: 0.1rem;
    }
  }

  .value,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
  }

  .data {
    margin-bottom: $spacing-05;
  }

  .from {
    margin-bottom: $spacing-05;
  }

  .tags {
    margin-left: unset;
    margin-bottom: $spacing-05;

    .bx--tag {
      margin-left: 0;
    }
  }

  .bx--col {
    padding-left: $spacing-06;
  }

  .nw-segment .bx--col {
    padding-bottom: $spacing-04;
  }

  .label-col {
    padding-left: $spacing-03;
  }
  .description-container {
    max-width: 20rem;
    .description {
      max-width: 118%;
      display: inline-block;
      direction: ltr;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
