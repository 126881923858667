@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.cloud-card {
  min-height: 100%;
  cursor: pointer;

  .header {
    margin-bottom: 1rem;
    .name {
      @include carbon--type-style('heading-02');
      display: flex;
      align-items: center;
    }
    .icon {
      margin-left: 20px;
      display: flex;
      height: 32px;
      width: 33px;
      border-radius: 50%;
      border: 1px solid #e0e0e0;
      justify-content: center;
      align-items: center;
    }
  }

  .helper-text {
    @include carbon--type-style('helper-text-01');
    color: $text-05;
  }

  .label-text {
    @include carbon--type-style('label-01');
    color: $text-02;
  }

  .value,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
  }

  .data {
    margin-bottom: 1rem;
    .auto-discover-label {
      .auto-discover-label-item {
        display: flex;
        align-items: center;

        .completed-icon,
        .errored-icon,
        .in-progress-icon,
        .pending-icon {
          line-height: 1;
        }
        .errored-icon {
          filter: invert(14%) sepia(82%) saturate(5900%) hue-rotate(352deg)
            brightness(91%) contrast(86%);
        }

        .pending-icon {
          line-height: 1;
          filter: invert(20%) sepia(77%) saturate(2871%) hue-rotate(210deg)
            brightness(91%) contrast(123%);
        }
        .value {
          margin-left: 10px;
        }
      }
    }
  }

  .from {
    margin-bottom: 1rem;
  }

  .tags {
    margin-left: unset;
    margin-bottom: 1rem;

    .bx--tag {
      margin-left: 0;
    }
  }

  .descContent {
    text-align: left;
  }

  .resource-name {
    text-align: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
