@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.topology-side-panel-gateway-details {
  .side-panel-gateway-header {
    display: block;

    .header-gateway-name-sidepanel {
      display: flex;
      align-items: center;
    }

    a {
      color: #161616;
    }

    .bx--link {
      @include carbon--type-style('productive-heading-03');
    }

    a:hover {
      text-decoration: none;
    }

    .gateway-header-text {
      @include carbon--type-style('productive-heading-03');
      padding-left: 0.5rem;
      max-height: 4.2rem;
      text-overflow: ellipsis;
    }
  }

  .gateway_healthstatus_header {
    padding-left: $spacing-05;
    .tooltip {
      cursor: pointer;

      &:hover {
        color: $blue-60;
      }
    }

    .bx--tooltip__trigger--definition {
      border: 0;
    }
  }

  .bx--tabs--scrollable__nav {
    margin-top: $spacing-05;
  }

  #topology-details-tab-1,
  #topology-details-tab-2 {
    width: fit-content;
  }

  .skeleton-container {
    padding-right: $spacing-05;

    .gateway-connection-details-skeleton {
      width: 100%;

      &:nth-child(n) {
        width: 40%;
        max-height: 14px;
        margin-bottom: $spacing-05;
      }
      &:nth-child(2n) {
        width: 100%;
        max-height: 18px;
      }
    }
    .gateway-details-skeleton {
      width: 100%;
    }
  }

  #gatewayInterconnectChart1 {
    margin-top: 1.063rem;
  }
}

.topology-side-panel-gateway-details {
  .header-title {
    svg {
      height: $spacing-05;
      width: $spacing-05;
      position: relative;
      top: 2px;
    }
  }

  .skeleton-container {
    &.summary-details {
      .gateway-details-skeleton {
        height: 15px;
        margin-bottom: $spacing-03;
        width: 100%;

        &.header {
          width: 30%;
        }

        &.value {
          width: 60%;
        }
      }
    }

    &.metric-details {
      min-height: 200px;

      .gateway-details-skeleton {
        min-height: 200px;
      }
    }
  }

  .details-container {
    padding: $spacing-01 0;
    margin-bottom: $spacing-03;

    .label {
      @include carbon--type-style('label-01');
      color: $text-02;
      padding: $spacing-01 0;
    }

    .value {
      @include carbon--type-style('body-short-01');
      color: $text-01;
      padding: $spacing-01 0;
    }

    .summary-time {
      margin-bottom: $spacing-03;
    }

    .summary {
      .average-latency {
        margin-bottom: $spacing-05;
      }

      .session {
        display: flex;
        padding: $spacing-02 0 0 0;

        .header {
          flex: 0 0 33%;
        }

        .value {
          flex: 0 0 33%;
        }
      }

      .header {
        @include carbon--type-style('productive-heading-01');
        color: $text-01;
      }

      .value {
        svg {
          position: relative;
          top: 2px;
          margin-right: $spacing-02;
          fill: $cyan-60;
        }
      }
    }
  }

  .dropdown-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing-05;

    div {
      flex-grow: 1;
    }

    .refresh {
      position: relative;
      height: 32px;
      max-width: 32px;
      width: 11%;
      .icon {
        position: absolute;
        cursor: pointer;
        bottom: 6px;
        right: 9.5px;

        svg {
          fill: $teal-50;
        }
      }
    }
  }

  .metric-container {
    .cds--modal {
      right: 0;
      left: unset;
    }

    .child-items {
      margin-bottom: 0;
      .dropdown-container {
        align-items: end;
        .left.history-dropdown {
          margin-right: 16px;
          width: 38%;
        }
        .right.resolution-dropdown {
          margin-right: 16px;
          width: 38%;
        }
      }
    }
  }
  .bx--data-table {
    tbody {
      tr {
        height: $spacing-07;

        &:hover {
          background: transparent;

          td {
            background: transparent;
            border: transparent;
          }
        }
      }
    }
  }
  .bx--data-table th,
  .bx--data-table td {
    padding-left: 0;
    border: 0;
  }
}
