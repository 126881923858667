@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.bx--data-table td .bx--tag {
  white-space: nowrap;
  margin: 0 0.25rem 0 0;
}
.name-column-value {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;

  .depl-env-icon {
    margin-right: $spacing-02;
    padding-top: 5px;
  }

  .icon {
    margin-left: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;

    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
      position: absolute;
      margin-bottom: 0.5rem;
    }

    .icon-with-tooltip {
      margin-top: 1.25px;
      margin-left: 3px;
      .bx--btn--ghost:hover {
        background-color: transparent;
      }
    }
  }
  .circle-icon {
    border: 1px solid gray;
    height: $spacing-04;
    width: $spacing-04;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    left: $spacing-04;
    top: $spacing-01;
    cursor: pointer;
  }
}
