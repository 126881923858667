@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
.resource-groups-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;
  }
  .bx--modal-container {
    height: auto;
  }
  .delete-resource-group-modal {
    .bx--modal-container--sm {
      width: 35rem;
      height: 13.25rem;
    }
  }
  .resource-group-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    justify-content: flex-end;
    .unmanaged-checkbox {
      margin-left: $spacing-03;
    }
    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;
      button {
        background-color: $ui-background;
        color: $ui-05;
      }
      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }
  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }
    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }
  .resource-card {
    margin: $spacing-03 0;
    padding-right: $spacing-03;
    padding-left: $spacing-03;
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }
  }
  .cds--grid {
    margin: 0;
  }
  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;
    .resouce-group-skeleton {
      width: 100%;
      height: $spacing-13;
    }
    .no-resource-group {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-13 + $spacing-07;
    }
  }

  .add-rg-modal {
    .bx--form-item {
      margin-bottom: $spacing-05;
    }

    .bx--tooltip-definition {
      @include carbon--type-style('body-short-01');
    }
  }
}
