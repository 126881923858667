@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.option-container {
  .left-panel {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 7rem;
    margin-left: 1rem;
    background-color: $ui-background;

    .layers-icon,
    .expandTo-icon {
      padding: 0;
      border: 0;
      appearance: none;
      background: none;
      outline-offset: -2px;
      position: relative;
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: $gray-20;
      }
    }

    .layers-icon-show-shadow,
    .expandTo-icon-show-shadow {
      padding: 0;
      border: 0;
      appearance: none;
      background: none;
      outline-offset: -2px;
      position: relative;
      display: flex;
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: $white-0;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
      transition: none;
    }
  }

  .stroke-hider-layers {
    position: fixed;
    border: 3px solid $white-0;
    margin-left: 2.7rem;
    height: 32px;
    top: 112px;
    z-index: 6000;
  }

  .stroke-hider-expandTo {
    position: fixed;
    border: 3px solid $white-0;
    margin-left: 2.7rem;
    height: 32px;
    top: 144px;
    z-index: 6000;
  }

  .right-panel {
    min-width: 277px;
    position: absolute;
    margin-left: 3.01rem;
    background-color: $ui-background;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 1;
    .layers-panel {
      padding-top: $spacing-03;
      padding-bottom: 16px;
      z-index: 1;

      .header-label {
        @include carbon--type-style('label-01');
        color: $text-02;
        padding-left: 16px;
        padding-bottom: 3px;
      }
      .header-label-infra {
        @include carbon--type-style('label-01');
        color: $text-02;
        padding-left: 16px;
        padding-bottom: 3px;
        padding-top: 16px;
      }
      .gateway-conn {
        padding-left: 1.5rem;
        padding-bottom: 2px;
        &:hover {
          background: $gray-20;
        }
      }
      .checkboxes {
        @include carbon--type-style('body-short-01');
        color: $text-01;
        margin-left: 0px;

        .bx--checkbox-label::before {
          top: 4px;
          left: 14px;
        }
        .bx--checkbox-label::after {
          right: 10px;
          left: 1.3rem;
          top: 10px;
        }
        .layers-checkbox {
          margin-bottom: -4px;
          padding-left: 36px;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-left: 0px;
          min-width: 100%;
          &:hover {
            background: $gray-20;
          }
        }
        .layers-checkbox-gwConn {
          padding-left: 36px;
          margin-bottom: -4px;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-left: 0px;
          min-width: 100%;
        }
      }
      .checkboxes .bx--form-item .bx--checkbox-wrapper {
        &:hover {
          background: $gray-20;
        }
      }
    }
    &.layers {
      top: 112px;
    }
    &.expand-to {
      top: 144px;
    }
    .expand-to-panel {
      padding-top: $spacing-01;
      padding-bottom: $spacing-03;
      z-index: 1;

      .header-label {
        @include carbon--type-style('label-01');
        color: $text-02;
        padding: $spacing-03 $spacing-05;
        // padding-bottom: $spacing-05;
      }

      .radiobuttons {
        @include carbon--type-style('body-short-01');
        color: $text-01;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        &::after {
          content: ' ';
          position: absolute;
          height: 58%;
          width: 1px;
          background: $ui-04;
          top: 61px;
          left: 26px;
        }
        .bx--radio-button-wrapper {
          display: flex;
          align-items: center;
          padding-bottom: 0.5rem;
          padding-top: 0.5rem;
          min-width: 100%;
          padding-left: 16px;
          &:hover {
            background: $gray-20;
          }
          .bx--radio-button__label {
            display: flex;
            justify-content: flex-start;
          }
          .bx--radio-button__appearance {
            z-index: 1;
            background-color: $white-0;
          }
        }
      }
    }
  }
}
