@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.add-identity-details {
  .c4p--tearsheet-create__step--title {
    display: none;
  }
  .c4p--tearsheet-create__step--subtitle {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
  }
}
