@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.side-panel-empty-state{

    .empty-icon{
       margin-top:$spacing-05;
       margin-left:$spacing-05;
    }
    .header {
        margin-top:$spacing-05;
        @include carbon--type-style('body-short-02');
        color:#161616;
    }
    .message {
        margin-top:$spacing-03;
        @include carbon--type-style('body-long-01');
        color:#525252;
    }
    .action-button {
        margin-top:$spacing-05;
    }

    &.error-500 {
        display: flex;
        margin-top: $spacing-05;

        .header {
            margin-top:0;
            color:$text-01;
        }

        .message {
            @include carbon--type-style('caption-01');
            color:$text-01;
        }
    }
}