@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';



.no-data-container {
  margin-bottom: 0px;
  height: 296px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $spacing-05;
  padding-top: $spacing-07;
  width: calc(100% - ($spacing-03 * 2));
  color: #6f6f6f;
  margin-left: $spacing-03;
  background-color: #ffffff;
  margin-top: $spacing-03;
}
