@import 'carbon-components/scss/globals/scss/typography.scss';
.topology-legend-shortcuts {
  
  .legend-sub-section-label {
    @include carbon--type-style('body-long-01');
  }

  .shortcut-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 0px;
    padding-bottom: 18px;
    max-width: 320px;
    .dragButton {
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 7px;
      padding-bottom: 7px;
      color: #161616;
      font-size: 14px;
      font-family: IBM Plex Sans;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.16px;
      word-wrap: break-word;
      border: 1px solid $gray-20;
      border-radius: 15%;
    }
  }
  .expand-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .shortcut-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .shift-key-icon {
      padding-right: 10px;
    }
   
  }
}
