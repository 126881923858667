@import 'carbon-components/scss/globals/scss/spacing.scss';

.manage-application-service {
  @media (min-width: 42rem) {
    .c4p--tearsheet__header-description {
      max-width: 100%;
    }
  }
  //Made the notification sticky
  .inline-notification-container {
    margin-top: -$spacing-05;
    position: sticky;
    top: 0;
    z-index: 10000;
  }
}
