@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
.edit-event-modal {
  .bx--modal-header__heading {
    @include carbon--type-style('productive-heading-03');
  }
  .c4p--tearsheet__header-description {
    @include carbon--type-style('body-long-01');
    min-width: 70%;
  }
  .bx--modal-container {
    max-width: 30rem;
  }
  .name {
    @include carbon--type-style('productive-heading-02');
    white-space: pre-wrap;
  }
  .items-container {
    margin-left: $spacing-05;
    margin-right: $spacing-05;
  }
  .item {
    margin-top: $spacing-05;
  }
}