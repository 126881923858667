@import 'carbon-components/scss/globals/scss/typography.scss';

.overflow-component {
  line-height: 1;
  white-space: nowrap;

  .overflow-wrap {
    width: 100%;

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      @include carbon--type-style('body-short-01');
      color: $text-02;
      border-bottom: none;
    }

    .bx--tooltip__trigger {
      width: 100%;
    }
  }
}