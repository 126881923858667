@import 'carbon-components/scss/globals/scss/typography.scss';

.create-role-permission-resource-group-table {
  .role-permission-expand-cell {
    @include type-style('productive-heading-01');

    &:nth-child(2) {
      width: $spacing-13 + $spacing-09;
      min-width: $spacing-13 + $spacing-09;
    }
  }

  .bx--data-table thead {
    position: sticky;
    top: -1.5rem;
    z-index: 1;
  }

  .bx--data-table-container {
    overflow-x: inherit;
    padding-top: 0;
  }

  .bx--data-table-content {
    overflow-x: inherit;
    overflow-y: inherit;
  }

  .role-search {
    .bx--search-input {
      border-bottom: 0;
    }
  }

  .bx--table-expand {
    padding: 0;
  }

  .bx--data-table th {
    width: $spacing-12 + $spacing-05;
    min-width: $spacing-12 + $spacing-05;
    padding: 0;

    .bx--table-header-label {
      display: flex;
      justify-content: center;
    }

    .bx--tooltip__trigger--definition {
      @include type-style('productive-heading-01');
    }

    &:nth-child(2) {
      width: $spacing-13 + $spacing-09;
      min-width: $spacing-13 + $spacing-09;
      padding: 0;
    }
  }

  .row-expanded-table td {
    border-top: 0;
  }

  .row-expanded-table td:nth-of-type(2) {
    align-items: start;
  }

  tr.bx--parent-row.bx--expandable-row+tr[data-child-row] td {
    padding-left: $spacing-05;
    text-align: start;
    margin-left: $spacing-07;
  }

  tr.bx--parent-row.bx--expandable-row+tr[data-child-row] td:nth-child(2) {
    padding-left: 0rem;
  }

  tr.bx--parent-row.bx--expandable-row+tr[data-child-row] td:nth-child(1) {
    .bx--child-row-inner-container {
      .bx--data-table-content {
        tr:not(:nth-child(3n + 1)):not(:nth-child(4)) td:nth-child(1) {
          padding-left: 16%;
        }

        tr:not(:nth-child(3n-1)):not(:nth-child(3n)) td:nth-child(1) {
          padding-left: 5%;
        }
      }
    }
  }

  .row-resource-group-section {
    text-align: end;
    width: $spacing-13 + $spacing-11;
    min-width: $spacing-13 + $spacing-11;
  }

  .row-resource-group-name {
    @include type-style('body-short-01');
    color: $text-01;
    border-bottom: 1px solid #e0e0e0;
  }

  .row-resource-group-name td {
    text-align: end;
    word-break: break-word;
  }

  .resource-group-check-box {
    width: $spacing-12 + $spacing-05;
    min-width: $spacing-12 + $spacing-05;

    .bx--tooltip--definition {
      display: flex;
      justify-content: center;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: 0;
    }
  }

  .resource-group-check-box div {
    display: flex;
    align-items: center;
  }

  .bx--data-table tbody tr:hover,
  .bx--data-table tbody tr:hover td,
  tr.bx--parent-row.bx--expandable-row:hover+tr[data-child-row] td {
    background-color: #fff;
    background: #fff;
  }
}