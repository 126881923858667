@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@mixin edit-font($fam, $style, $weight, $size, $height) {
  font-family: $fam;
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}
.edit-edgeGateway {
  .inline-notification-container {
    .bx--inline-notification--error {
      position: fixed;
      z-index: 100;
    }
  }
  z-index: 9999 !important;
  .bx--modal-header__heading.c4p--tearsheet__heading {
    @include edit-font('IBM Plex Sans', normal, 400, $spacing-07, $spacing-09);
    color: $text-01;
  }

  .c4p--tearsheet__header-description {
    @include edit-font('IBM Plex Sans', normal, 400, $spacing-04, $spacing-05);
    color: $text-02;
  }
  .edit-gateway-form {
    padding: $spacing-03;
    margin-left: $spacing-06;
    margin-right: $spacing-06;
    .flex {
      display: flex;
      position: relative;
      .edit-deployment-button {
        @include edit-font('IBM Plex Sans', normal, 400, 14px, 18px);
        border-color: #f4f4f4;
        position: absolute;
        right: 0;
        bottom: 1px;
      }
    }
    .bx--text-input__readonly-icon {
      display: none;
    }
    .row.bx--row {
      padding-bottom: 24px;
    }
    .edit-gateway__connection-ingress-section,
    .edit-gateway__add-connection-ingress,
    .edit-gateway__gWdetails-section,
    .edit-gateway__cluster-details-section,
    .edit-gateway__vpc-details-section,
    .deploy-in-vpc-form {
      margin: 1.5rem 0;
      .bx--label--disabled,
      .bx--form__helper-text--disabled {
        color: #525252;
      }
    }
    .edit-gateway__cluster-details__heading,
    .edit-gateway__connection-ingress__heading,
    .edit-gateway__vpc-details__heading,
    .edit-gateway__gWdetails-heading {
      @include edit-font('IBM Plex Sans', normal, 400, 20px, 28px);
      padding-bottom: 1.5rem;
    }
    .edit-gateway__connection-ingress-section {
      .edit-gateway__connection-ingress__subheading {
        @include edit-font('IBM Plex Sans', normal, 400, 12px, 16px);
        color: #525252;
        padding-bottom: 8px;
      }
      .edit-gateway__connection-ingress__checkbox {
        @include edit-font('IBM Plex Sans', normal, 400, 14px, 18px);
        letter-spacing: 0.1599999964237213px;
      }
      .edit-gateway__add-connection-ingress {
        margin-top: 2rem;
      }
      .edit-gateway__add-connection-ingress {
        margin-left: -1rem;
        margin-right: -1rem;
      }
    }

    #edit-gateway-clusterId,
    #edit-gateway-locationId,
    #edit-gateway-cloudId,
    #edit-gateway-vpcId,
    #edit-gateway-gwImage,
    #network-segment,
    #vpc-name,
    #cloud-name,
    #location-name,
    #edit-gateway-namespace,
    .bx--dropdown--disabled {
      background: transparent;
      border-color: #c6c6c6;
      &:focus,
      &:hover,
      .bx--text-input:active {
        outline: 2px solid transparent;
        cursor: no-drop;
      }
    }
    #edit-gateway__gWdetails-description {
      background-color: $background;
    }
    #gateway-cloud > button:disabled,
    #gateway-location > button:disabled {
      background: #f4f4f4;
      border-bottom: 1px solid #c6c6c6;
      .bx--list-box__label {
        color: #161616;
      }
    }

    .form-field-description {
      padding-left: 1rem;
    }
    .edit-gateway__gWdetails-section {
      .add-labels-component {
        .input-container {
          .input {
            width: 90%;
          }
          .button {
            width: 10%;
          }
        }
      }
    }
  }
}
