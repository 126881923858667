@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-role-details-tearsheet {
  align-items: center !important;

  .bx--modal-container {
    width: 35%;
    max-width: 480px;
    max-height: 600px;
    .edit-role-form {
      padding: 1rem;

      .bx--form-item {
        margin-bottom: 1rem;
      }
    }
  }
  .inline-notification-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
