@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.network-segments-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  // .c4p--side-panel.c4p--side-panel--md {
  //   width: 77rem;
  //   max-width: 100%;
  // }

  .c4p--side-panel__container.c4p--side-panel__container-right-placement.c4p--side-panel__container--md {
    width: 79%;
  }

  .c4p--side-panel__container .c4p--side-panel__title-container {
    background-image: linear-gradient(to right, #103cb4, #6929c4, #8a3ffc);
  }

  .c4p--side-panel__container .c4p--side-panel__title-text {
    background-image: linear-gradient(to right, #103cb4, #6929c4, #8a3ffc);
    color: #ffffff;
  }

  .c4p--side-panel__container
    .bx--btn.c4p--side-panel__close-button
    .bx--btn__icon {
    color: #ffffff;
  }
  .onboard-network-segment-modal .onBoardModalContainer {
    padding: 0px 25px 0px 24px;
  }
  // .c4p--side-panel .c4p--side-panel__header {
  //   background-image: linear-gradient(to right, #103cb4, #6929c4, #8a3ffc);

  //   .c4p--side-panel__title-text {
  //     top: 15px;
  //     background-image: linear-gradient(to right, #103cb4, #6929c4, #8a3ffc);
  //     color: #ffffff;
  //     font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  //     font-weight: var(--cds-productive-heading-02-font-weight, 400);
  //   }

  //   .c4p--side-panel .c4p--side-panel__title {
  //     margin-bottom: 16px;
  //   }

  //   // .c4p--side-panel__collapsed-title-text {
  //   //   top: 15px;
  //   //   color: #ffffff;
  //   //   font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  //   //   font-weight: var(--cds-productive-heading-02-font-weight, 400);
  //   // }
  // }

  .title-container {
    flex: 1;
  }

  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;
  }

  .nwSegments-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    justify-content: flex-end;

    .unmanaged-checkbox {
      margin-left: $spacing-03;
    }

    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;

      button {
        background-color: $ui-background;
        color: $ui-05;
      }

      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }

  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }

    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }
  }

  .cds--grid {
    margin: 0;
  }

  .application-card {
    margin: $spacing-05 0;
  }

  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;

    .application-skeleton {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }

  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;

      .bx--tooltip__trigger {
        color: $hover-primary-text;
        cursor: pointer;
      }
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }

  .tags {
    margin-left: unset;
    margin-bottom: 1rem;
    min-height: 24px;
  }

  .skeleton-sort-drop-down {
    display: flex;
    margin-left: -$spacing-05;
    height: $spacing-07;
    overflow: hidden;
    width: 100%;
    margin-right: $spacing-05;

    .sorting-skeleton {
      height: $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }

  .bx--btn--primary {
    width: max-content;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bx--btn--tertiary {
      margin-right: 0px;
    }
  }
}
