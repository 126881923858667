@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.api-key-modal-custom-component {
  position: absolute;
  z-index: 10000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white-0;
  display: flex;
  //min-height: 365px;

  @media screen and (min-width: 42rem) {
    width: 84%;
    max-height: 90%;
  }

  @media screen and (min-width: 66rem) {
    width: 60%;
    max-height: 84%;
  }

  @media screen and (min-width: 84rem) {
    width: 48%;
  }

  .api-key-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    //  min-height: 365px;
    justify-content: space-between;

    .api-key-modal-content {
      padding: 1rem;
      padding-bottom: 3rem;
      .modal-label {
        padding-bottom: 0.5rem;
        @include carbon--type-style('label-01');
        color: $gray-70;
      }
      .modal-title {
        padding-bottom: 1rem;
        @include carbon--type-style('expressive-heading-03');
        color: $gray-100;
      }
      .modal-body {
        padding-bottom: 1.5rem;
        @include carbon--type-style('body-long-01');
        color: $gray-100;
      }

      .api-key-generate-error-message {
        display: flex;
        flex-direction: row;
        margin-top: 8px;

        .error-icon {
          margin-right: 8px;
          color: $red-60;
        }
        .error-message {
          @include carbon--type-style('label-01');
        }
      }
      .modal-api-generated-api-key-field-container {
        padding-bottom: 1.25rem;
        margin-top: 8px;
        input.api-key-modal-password-input {
          background-color: $gray-10;

          &.hide-visibility-toggle {
            + button.bx--text-input--password__visibility__toggle {
              visibility: hidden;
            }
          }
          &.show-visibility-toggle {
            + button.bx--text-input--password__visibility__toggle {
              visibility: visible;
            }
          }
        }
      }
      .generate-success-download-body-text {
        .generate-key-success-message {
          margin: 0;
          width: 100%;
          max-width: none;
        }
      }
    }
    .button-container {
      display: flex;
      width: 100%;
      .bx--btn-set {
        display: flex;
        width: 100%;

        .generate-key-loading-button {
          margin-left: 0 !important;
          padding-left: 1rem;
          background: $blue-80;

          .bx--inline-loading__animation {
            position: absolute;
            right: 0.5rem;
          }

          .bx--inline-loading__text {
            color: $white-0;
            @include carbon--type-style('body-short-01');
          }
        }

        .cds--popover-container {
          display: flex;
          width: 100%;

          .cds--toggletip-content {
            .tooltip-message-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;

              .icon {
                display: flex;
                align-items: center;
                margin-right: 8px;
              }
            }
          }
        }

        .bx--btn {
          display: flex;
          width: 50%;
          max-width: none;
          @include carbon--type-style('body-short-01');
          height: 4rem;

          &.copy-button {
            width: 100%;
          }
        }
      }
    }
  }
}

.api-key-modal-overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 9000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  &.open-modal {
    display: block;
  }
}
