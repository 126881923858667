@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.deployment-environment-details {
  .page-content {
    background-color: $ui-01;
    padding: 1rem;

    .delete-deploymentEnv {
      margin-top: 1rem;
      margin-bottom: 4rem;
      .delete-deploymentEnv-disabled {
        border-color: transparent;
        background: transparent;
        color: $disabled-02;
        outline: none;
        cursor: not-allowed;
        box-shadow: none;
      }
    }
  }

  .details-tile {
    margin-bottom: 1rem;
  }

  .details-tile-skeleton {
    width: 100%;
    min-height: 20rem;
  }

  .bx--data-table-container {
    margin-bottom: 1rem;
  }

  .bx--data-table-header,
  .bx--table-toolbar,
  td {
    background: white;
  }

  .bx--child-row-inner-container {
    width: 74.25%;
    float: right;
  }

  .bx--pagination {
    background-color: $ui-background;

    select {
      background-color: $ui-background;
    }
  }

  .table-header {
    width: 25%;
  }

  .services-table {
    .headers {
      border-bottom: 1px solid $ui-03;
    }
    .header {
      @include carbon--type-style('productive-heading-01');
      color: $text-01;
      padding: 1rem;
    }

    .cell {
      padding: 1rem;
    }
  }
  .bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--off,
  .bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--on {
    margin-left: $spacing-09;
  }
  .bx--form-item {
    margin-right: $spacing-05;
  }

  // Styles related to managed toggle
  .header-wrapper {
    padding-right: 0;
  }

  .tooltip-container {
    .bx--form-item {
      margin-right: 0;
    }

    .label {
      @include carbon--type-style('label-01');
      color: $text-02;
      letter-spacing: 0.32px;
    }

    .bx--toggle__switch {
      margin-top: $spacing-03;
    }

    .bx--tooltip--definition {
      button {
        border: none;
      }
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end
      + .bx--assistive-text {
      right: $spacing-02;
    }
  }

  .header-icon-contaniner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon-text {
      padding-left: 4px;
    }
    .unmanaged-icon-text {
      padding-left: 4px;
      color: $gray-60;
    }
    .managed-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $interactive-01;
      background-color: $interactive-01;
      border-radius: 50%;
    }
    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
    }
    // &.unmanaged {
    //   .icon-text {
    //     color: $gray-60;
    //   }
    // }

    .delete-deploymentEnv {
      margin-top: 1rem;
    }
    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
    }
  }

  .autodiscovered-icon-contaniner {
    position: relative;

    .icon-with-tooltip {
      left: 0;
      bottom: -14px;
    }
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
  }

  .details-tile .data-container .value .bx--tag {
    margin-left: 0;
    margin-right: $spacing-04;
  }

  .procedural-status-component {
    .bx--inline-loading__text {
      @include carbon--type-style('body-short-01');
      color: $text-01;
    }
  }
  .healthStatusContainer {
    display: flex;
    .health-status-component {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        padding-right: 0.25rem;
        display: flex;
      }
    }
  }
  .procedural-status-component {
    .bx--inline-loading__text {
      color: $text-01;
    }

    .label {
      @include carbon--type-style('body-short-01');
      color: $text-01;
    }

    &.tooltip {
      cursor: pointer;

      .label:hover {
        color: $link-01;
      }
    }
  }
  .depenvDetails_noaccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.deleteDEpEnvModal {
  .bx--modal-container {
    width: 40rem;
    height: 13.25rem;
    .bx--modal-header__heading {
      @include carbon--type-style('body-long-01');
    }
    .bx--modal-header__label {
      @include carbon--type-style('productive-heading-03');
      color: $text-01;
    }
    .bx--modal-close {
      display: block;
    }
  }
}
