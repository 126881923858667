@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-details-form-tearsheet {
  max-width: 800px;

  .inline-error-notification-row {
    position: sticky;
    z-index: 10;
    top: 0;
  }

  .c4p--tearsheet-create__step--title {
    display: none;
  }

  .gateway-details-form {
    .cds--combo-box--readonly {
      pointer-events: none;
    }
    .router-mode-combobox .cds--label {
      pointer-events: none;
    }
    .rhsi-edge-form {
      .form-heading {
        padding-left: 1rem;
        @include carbon--type-style('productive-heading-03');
        margin-bottom: 0;
      }

      .row.bx--row {
        margin-bottom: 1rem;

        &.deployed-in-environment {
          margin-bottom: 3rem;

          .bx--text-input-wrapper--readonly {
            .bx--label {
              margin-bottom: 0;
            }

            .bx--text-input__field-wrapper {
              svg.bx--text-input__readonly-icon {
                display: none;
              }

              input#gateway-namespace-name,
              input#gateway-deployed-in-cluster-name,
              input#gateway-deployed-in-cluster-location-name {
                background-color: inherit !important;
                -webkit-text-fill-color: $gray-100;
                border-bottom: none;
                pointer-events: none;
                padding: 0;
                height: 30px;
              }
            }
          }
        }
      }

      .gateway-configuration-section {
        margin-bottom: $spacing-09;

        .heading {
          @include carbon--type-style('productive-heading-03');
          margin-bottom: $spacing-05;
        }

        .description {
          @include carbon--type-style('body-short-01');
          color: $gray-70;

          .descriptionText {
            margin-right: $spacing-03;
          }
        }

        .gateway-sizing-section {
          margin-top: $spacing-07;

          #select-gateway-sizing {
            .bx--list-box__menu {
              .bx--list-box__menu-item {
                background: $white-0;

                &:hover {
                  background: $gray-20;
                }

                .bx--list-box__menu-item__option {
                  border-bottom: 1px solid $gray-20;
                  border-top: 1px solid transparent;
                }
              }
            }
          }

          .gateway-sizing-heading {
            @include carbon--type-style('heading-02');
            margin-bottom: 1rem;
          }

          .gateway-sizing-list-wrapper {
            .list-selection-input {
              display: none;
            }

            .cds--structured-list-thead {
              .cds--structured-list-row {
                .cds--structured-list-th {
                  width: 19%;
                  padding: 0.5rem 1rem 0.5rem 1rem;

                  &:first-of-type {
                    width: 5%;
                  }
                }
              }
            }

            .cds--structured-list-row {
              &.cds--structured-list-row--selected {
                background-color: transparent;
              }

              &.cds--structured-list-row--focused-within {
                outline: none;
              }

              .cds--structured-list-td {
                padding: 0.5rem 1rem 0.5rem 1rem;

                &.custom-sizing-cell {
                  padding: 2px 8px 2px 16px;

                  .custom_compute_profile_input {
                    height: 32px;
                    padding-right: 1rem;
                    min-width: auto;
                  }

                  .bx--form-item {
                    margin-left: -16px;
                  }
                }

                &.list-selection-radio-btn {
                  vertical-align: middle;
                  padding: 10px 0px 10px 16px;

                  .cds--radio-button__appearance {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
          }

          .gateway-sizing-error-message {
            @include carbon--type-style('helper-text-01');
            color: $red-60;
            margin-left: 24%;
          }
        }

        .gateway-optional-settngs-section {
          margin-top: 32px;

          .optional-settngs-heading {
            @include carbon--type-style('heading-02');
          }

          .gateway-optional-settngs-accordion {
            margin-top: 1rem;

            .ingress-form-description {
              margin-bottom: 1rem;
            }

            .row.bx--row {
              margin-bottom: 2rem;
            }

            @media (min-width: 640px) {
              .cds--accordion__content {
                padding-inline-end: 3rem;
              }
            }
          }
        }

        .bx--checkbox-wrapper {
          .bx--checkbox-label-text {
            color: $gray-100;
          }
        }

        .gateway-configuration-ingress-form {
          margin-top: $spacing-07;

          .ingress-form-heading {
            @include carbon--type-style('heading-02');
            margin-bottom: $spacing-03;
          }

          .ingress-form-description {
            @include carbon--type-style('body-short-01');
            margin-bottom: $spacing-04;
            color: $gray-70;
          }

          .ingress-config-form {
            margin-top: $spacing-06;

            .bx--list-box__menu-item--active
              + .bx--list-box__menu-item
              > .bx--list-box__menu-item__option {
              border-top: 1px solid $gray-20;
            }

            #router-ingress-hostname-col,
            #controller-ingress-hostname-col,
            #ingress-annotations-col {
              margin-top: $spacing-06;
            }
          }

          #select-gateway-ingress-type {
            .bx--list-box__menu {
              .bx--list-box__menu-item {
                background: $white-0;

                &:hover {
                  background: $gray-20;
                }

                .bx--list-box__menu-item__option {
                  border-bottom: 1px solid $gray-20;
                  border-top: 1px solid transparent;
                }
              }
            }
          }
        }
      }

      .optional-details {
        .form-heading {
          margin-bottom: 0;
        }
      }
    }
  }

  .inline-error-notification-row {
    .bx--inline-notification {
      max-width: 100%;
    }
  }

  .configuration-settings-section {
    background-color: #ffffff;
    padding: 16px;

    &.router-sizing-section,
    &.ingress-settings-section {
      margin-bottom: 16px;
      background-color: #ffffff;
      padding: 16px;
    }

    .configuration-settngs-section-heading {
      @include carbon--type-style('heading-02');
      padding-bottom: 1rem;
    }

    .configuration-settings-row {
      .field-div-col {
        padding-bottom: $spacing-06;
      }

      width: 100%;

      .field-div-col {
        .field-div {
          display: flex;
          flex-direction: column;

          .label {
            margin-bottom: 4px;
            color: $gray-70;
            @include carbon--type-style('label-01');
          }

          .value {
            @include carbon--type-style('body-short-01');
            color: $gray-100;
          }
        }
      }
    }
  }
  input#router-data-connection-count {
    background-color: white;
  }
  input#routers-count {
    background-color: white;
  }
}
