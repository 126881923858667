@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
.policy-container {
  display: flex;
  align-items: center;
  .bx--tooltip__trigger {
    .bx--tooltip__trigger--definition {
      .bx--tooltip--bottom {
        .bx--tooltip--align-center {
          .bx--tooltip--a11y + .bx--assistive-text {
            bottom: unset;
            top: 0;
          }
        }
      }
    }
  }
}
.policy-target-container {
  .policy-to-label {
    margin-right: $spacing-03;
  }

  .policy-target-main-label {
    margin-left: $spacing-02;
    text-align: left;
    margin-top: -0.135rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .policy-target-name {
    max-width: $spacing-12;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bx--assistive-text {
    flex-direction: column;
    list-style: none;
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
    color: $text-02;
  }
}
