.button-options-component {
  .bx--dropdown {
    background-color: #0F62FE;
  }
  .bx--dropdown:hover {
    background-color: #0F62FE;
  }
  .bx--dropdown--open:hover {
    background-color: #0F62FE;
  }
  .bx--list-box__menu-icon > svg {
    fill: #FFFFFF;
}
.bx--list-box__label {
  color: #FFFFFF;
}
.bx--list-box__menu {
  margin-top: 4px;
}

.bx--dropdown--disabled .bx--list-box__field, .bx--dropdown--disabled .bx--list-box__menu-icon {
  color: #8d8d8d ;
  border-color: #c6c6c6;
  background: #c6c6c6;
  box-shadow: none;
  cursor: not-allowed;
}
}