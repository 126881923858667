@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.identity-tag-container {
  display: flex;
  align-items: center;

  .name {
    @include carbon--type-style('heading-02');
    text-transform: capitalize;
  }

  .mail {
    @include carbon--type-style('body-short-02');
  }
}