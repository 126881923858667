@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
.topology-empty-page {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .empty-image {
    height: 100%;
    display: flex;
  }
  .dataset {
    position: absolute;
    right: 50%;
    left: 50rem;
    bottom: 52%;
    display: flex;
    flex-direction: column;
    .header {
      @include carbon--type-style('productive-heading-05');
      min-width: 245px;
      color: #161616;
    }
    .description {
      padding-top: 0.8rem;
      @include carbon--type-style('body-long-01');
      min-width: 374px;
      color: #525252;
    }
  }
}
