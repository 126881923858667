@import 'carbon-components/scss/globals/scss/spacing.scss';
@import '@carbon/themes/scss/themes';

@import 'carbon-components/scss/globals/scss/typography.scss';

#create-network-segment,
.continue-gateway-modal {
  z-index: 9100;
}

.gatewayDeploySuccessToast {
  margin-top: 50px;
}

.register-namespace-tearsheet {
  .bx--text-input__readonly-icon {
    display: none;
  }

  .bx--text-input-wrapper--readonly {
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
    border-bottom-color: #C6C6C6
  }

  .autodiscoverIntoRow {
    margin-bottom: 13px;
  }

  .form-field-segment {
    display: inline-block;
    margin-bottom: $spacing-03;
    @include carbon--type-style('label-01');
    color: $text-02;
  }

  .create-namespace-segment-skelton {
    display: flex;
    justify-items: center;
    width: 100%;
    height: 2.5rem;
    margin-top: $spacing-02;
  }

  .namespace-details-tearsheet {
    max-width: 800px;

    .bx--fieldset {
      .bx--label {
        width: 100%;
      }
    }

    .descriptionContainer {
      .bx--label {
        width: auto;
      }
    }

    .tearsheet-header {
      display: flex;
      /* width: 100%; */
      /* flex: 1; */
      justify-content: space-between;
      align-content: space-between;
    }
  }

  .c4p--tearsheet-create__step--fieldset {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
    //styleName: Fixed heading styles/heading-03;
    // font-family: IBM Plex Sans;
    // font-size: 20px;
    // font-weight: 400;
    // line-height: 28px;
    // text-align: left;
  }

  .data-loader {
    display: flex;
    flex-direction: column;

    .skeleton-row {
      padding: $spacing-05;

      .reg-namespace-skeleton {
        width: 100%;
        height: 18px;

        &:first-child {
          width: 100%;
          margin-bottom: $spacing-02;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .titleSection {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;

    .subtitle {
      @include type-style('body-short-01');
      color: $text-02;
    }

    .autodiscover-row {
      label.bx--label.label {
        display: flex;
      }
    }

    .bx--form-item.autodiscover-toggle {
      margin-top: -16px;
    }
  }
  
  .inline-notification-container {
    padding: 0px 32px;
    position: sticky;
    top: 0;
    z-index: 9;

    .bx--inline-notification--error {
      margin-bottom: 0px;
    }
  }

  .autodiscover-tooltip {
    margin-left: 8px;
  }

  .bx--inline-notification.bx--inline-notification--error {
    margin-top: -8px;
  }
}

.register-namespace-form {
  // padding: 24px 32px;

  .namespacedetailsCol {
    margin-bottom: 16px;
  }

  .cds--dropdown--readonly {
    cursor: not-allowed;
    pointer-events: none;
  }

  .dep-env-title {
    @include carbon--type-style('productive-heading-03');
    margin-bottom: 16px;
  }

  .details-title {
    @include carbon--type-style('productive-heading-03');
    margin-top: 16px;
  }

  .row.bx--row {
    padding-bottom: 16px;
  }

  .bx--text-input__readonly-icon {
    display: none;
  }

  .create-segment-button {
    color: #0f62fe;

    .createText {
      margin-right: 2px;
    }
  }

  .autodiscover-toggle-group {
    button {
      margin-left: 8px;
    }
  }

  #select-network-segment {
    .cds--list-box__menu {
      .cds--list-box__menu-item:last-child {
        .cds--list-box__menu-item__option {
          padding: 0;
          margin: 0;
          padding-inline-end: 0;

          .create-segment-button {
            padding: 0.6875rem 1rem;
            width: 100%;
          }
        }
      }
    }
  }

  .network-segment-label {
    display: flex;
    margin-bottom: -3px;

    .networksegment-tooltip {
      margin-left: 6px;

      .bx--assistive-text {
        padding: 16px;
      }
    }
  }

  .namespace-label {
    display: flex;
    margin-bottom: 0px;

    .namespace-tooltip {
      margin-left: 6px;

      .bx--assistive-text {
        padding: 16px;
      }
    }
  }

  .autodiscover-row {
    margin-bottom: 16px;

    .bx--label {
      display: flex;
      margin-bottom: -5px;
    }
  }

  .gatewayset {
    .bx--text-input-wrapper--readonly {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: none;
    }

    input {
      background-color: transparent;
    }
  }

  #gateway-set {
    background-color: transparent;
  }

  .bx--text-input,
  #select-network-segment {
    border-bottom: 1px solid #c6c6c6;
  }

  .bx--text-input-wrapper--readonly {
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;

    #addNamespaceName {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: none;
    }

    #namespace_depEnv_name,
    #namespace_location_name,
    #namespace_cloud_name {
      color: $text-secondary;
      background-color: transparent;
    }
  }

  #namespace_cloud_name,
  .cloud-name {
    color: $text-secondary;
    background-color: transparent;
  }

  .nameSpaceVal {
    display: flex;
    align-items: center;
  }

  .cloud-icon-cont {
    display: flex;
    margin-right: 6px;
  }

  .cloud-column {
    margin-top: -9px;
  }

  .details-subtitle {
    padding-top: 16px;
    @include type-style('body-short-01');
    color: $text-02;
    margin-bottom: 16px;
  }

  .connect-gateway-tooltip {
    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus,
    .bx--tooltip__trigger.bx--tooltip--bottom:focus svg {
      outline: none;
    }
  }
}