@import '@carbon/ibm-products/css/index-without-carbon-released-only.css';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g10;
@include carbon--theme();

.wide-tearsheet-container {
  &.c4p--tearsheet--wide .c4p--tearsheet__content {
    background: $background;
  }

  &.c4p--tearsheet--wide .c4p--tearsheet__buttons {
    background: $background;
  }

  &.c4p--tearsheet--wide .c4p--tearsheet__content .bx--pagination,
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--pagination__control-buttons,
  &.c4p--tearsheet--wide .c4p--tearsheet__content .bx--text-input,
  &.c4p--tearsheet--wide .c4p--tearsheet__content .bx--text-area,
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--search-input,
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--select-input,
  &.c4p--tearsheet--wide .c4p--tearsheet__content .bx--dropdown,
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--dropdown-list,
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--number
    input[type='number'],
  &.c4p--tearsheet--wide
    .c4p--tearsheet__content
    .bx--date-picker__input {
    background-color:  $ui-01;
  }
}
