@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.apikey-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;
  }

  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }

    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }

  .cds--grid {
    margin: 0;
  }

  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
    }

    tbody tr:hover{
      background: none;
    }

    tbody tr:hover:not(:has(.delete-api-key-btn:hover)) {
      background-color: #dcdcdc !important;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }

  .create-api-key-modal {
    @media (min-width: 66rem) {
      .bx--modal-container {
        width: 50%;
      }
    }

    .c4p--apikey-modal__messaging {
      padding-right: 0;
      svg {
        display: none;
      }

      .generate-key-success-message {
        svg {
          fill: $support-info;
          display: flex;
        }
      }

      .c4p--apikey-modal__error-icon svg {
        fill: $support-01;
      }
    }
  }
}
