@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.topology-application-service-connection-container {
  .header-title {
    position: relative;
    width: 100%;
    .header-icon {
      width: 8%;
    }
    .no-underline-link:first-of-type {
      width: 38%;
      padding: 0 $spacing-02;
    }
    .no-underline-link:last-of-type {
      width: 52%;
      padding: 0 $spacing-02;
    }
    button,
    .generic-truncate-string {
      width: 100%;
    }
    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center.bx--tooltip--a11y
      + .bx--assistive-text {
      transform: translate(-40%, 100%);
    }
    .truncate-last {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
    img {
      height: $spacing-05;
      width: $spacing-05;
      position: relative;
      top: $spacing-01;
    }
  }

  .skeleton-container {
    padding-right: $spacing-05;

    .app-details-skeleton {
      width: 100%;
    }
  }

  .details-container {
    padding: $spacing-01 0;

    .label {
      @include carbon--type-style('label-01');
      color: $text-02;
      padding: $spacing-01 0;
      &:not(:first-of-type) {
        padding-top: $spacing-03;
      }
    }

    .value {
      @include carbon--type-style('body-short-01');
      color: $text-01;
      padding: $spacing-01 0;
    }

    .summary {
      padding-top: $spacing-03;

      .session {
        display: flex;
        padding: $spacing-02 0 0 0;

        div:first-child {
          flex-basis: 40%;
        }
      }

      .header {
        @include carbon--type-style('productive-heading-01');
        color: $text-01;
      }

      .value {
        @include carbon--type-style('body-short-01');
        color: $text-01;

        svg {
          position: relative;
          top: 2px;
          margin-right: $spacing-02;
        }
      }
    }
  }

  .dropdown-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing-05;

    div {
      flex-grow: 1;
    }

    .left {
      margin-right: 16px;
    }

    .refresh {
      position: relative;
      margin-left: $spacing-04;

      .icon {
        position: absolute;
        cursor: pointer;
        bottom: 6px;
        right: 0;

        svg {
          fill: $teal-50;
        }
      }
    }
  }

  .no-underline-link {
    color: $text-01;
  }

  .metric-container {
    .cds--modal {
      right: 0;
      left: unset;
    }

    .child-items {
      margin-bottom: 0;
    }
  }

  .side-panel-component {
    .bx--accordion__item--active .bx--accordion__content {
      padding-bottom: $spacing-05;
    }
  }
}
