@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.event-groups-table {
  position: relative;

  .events-table-skeleton {
    width: 100%;
    height: $spacing-13 + $spacing-13 + $spacing-09;
  }

  .resource-name {
    text-align: initial;
  }

  .bx--tooltip--definition.bx--tooltip--a11y .bx--assistive-text {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    td:nth-child(1),
    td:nth-child(4),
    th:nth-child(4) {
      white-space: normal;
    }
  }

  .no-underline-link {
    text-decoration: none !important;
  }

  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-primary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
    }

    tbody {
      .events-table-severity {
        display: flex;
        align-items: center;
        gap: $spacing-02 + $spacing-01;
        padding-left: $spacing-04 + $spacing-01;

        img {
          height: $spacing-04;
          width: $spacing-04;
        }

        &.severity-minor img {
          height: $spacing-04 + $spacing-01;
          width: $spacing-04 + $spacing-01;
        }
      }

      tr:has(.table-font-weight) {
        font-weight: 700;
      }

      tr {
        td {
          color: $text-01;

          .resource-name {
            display: flex;
            align-items: center;
            gap: $spacing-02 + $spacing-01;
          }
        }

        :hover {
          background-color: #dcdcdc;

          a:nth-child(1) {
            color: $hover-primary-text;
          }
        }
      }
    }
  }

  .events-selected {
    background-color: $interactive-01;
    color: $white-0;
    display: flex;
    align-items: center;
    height: $spacing-09;
    padding: 0 $spacing-05;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    z-index: 10;

    .events-selected-items {
      font-size: $spacing-04 + $spacing-01;
    }

    .events-selected-buttons {
      display: flex;
      gap: $spacing-05;

      .bx--btn {
        padding: 0 $spacing-04;
      }

      div {
        cursor: pointer;
      }

      .events-selected-Cancel {
        &:before {
          content: '|';
          right: $spacing-05;
          position: relative;
        }
      }
    }
  }

  .find-and-filter-container {
    width: 100%;

    .dropdown {
      .bx--list-box__label::first-letter {
        text-transform: capitalize;
      }

      .bx--list-box__menu {
        text-transform: capitalize;
      }
    }

    .bx--dropdown {
      margin-left: 0;
    }
  }

  .bx--data-table-container {
    .bx--table-toolbar {
      display: -webkit-inline-box;

      .bx--batch-actions {
        z-index: 10;
      }
    }

    .bx--data-table-content {
      tr th {
        &:last-child .bx--table-header-label {
          min-width: $spacing-12 + $spacing-03;
        }

        &:nth-last-child(2) .bx--table-header-label {
          min-width: $spacing-11 + $spacing-03;
        }

        &:nth-last-child(3) .bx--table-header-label {
          min-width: $spacing-12 + $spacing-03;
        }
      }

      .bx--expandable-row {
        td {
          .bx--child-row-inner-container {
            display: flex;
            gap: $spacing-09;
            padding-left: $spacing-09 + $spacing-03;

            .expand-row-label {
              @include carbon--type-style('label-01');
              color: $text-02;
              line-height: 2.33333;
            }

            .expand-row-text {
              @include carbon--type-style('label-02');
              color: $text-01;
            }

            td {
              border: none;
              font-size: 0.75rem;
              font-weight: 400;
            }

            div {
              &:first-letter {
                text-transform: capitalize;
              }
              span {
                &:first-letter {
                  text-transform: capitalize;
                }
                color: $text-02;
                display: block;
                font-size: $spacing-04;
              }
              :last-child {
                color: $text-01;
              }
            }
          }
        }
      }

      .bx--expandable-row + .bx--expandable-row {
        height: $spacing-10 + $spacing-03;
      }
    }
  }

  .line-break {
    white-space: pre-wrap;
  }
}
