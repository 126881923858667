@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-network-segment-tearsheet {
  .outer-container {
    margin: $spacing-05;

    .title-container,
    .optional-title-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $text-primary;

      .title {
        @include type.type-style('heading-02');
      }

      .visibility {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 40px;
        padding: 0 $spacing-05;
        border: 1px solid $gray-20;

        .title {
          @include type.type-style('body-compact-01');
        }

        .icon {
          margin-left: $spacing-07;

          div {
            display: flex;
          }
        }
      }
    }

    .optional-title-container {
      margin-top: $spacing-09;
      margin-bottom: $spacing-05;
    }

    .form {
      .row {
        margin-top: $spacing-07;
        margin-bottom: $spacing-05;
      }

      .row:first-child {
        margin-top: $spacing-05;
      }

      .gateway-set-label {
        @include carbon--type-style('label-01');
        color: $gray-70;
        display: flex;

        .info-tooltip-text {
          padding-left: $spacing-03;
          border: none;
        }

        .cds--tooltip-content {
          margin-left: $spacing-09;
        }
      }
    }

    .accordion {
      margin-bottom: $spacing-10;

      .cds--accordion__content {
        padding-inline-end: $spacing-05;
      }

      .row:first-child {
        margin-top: $spacing-03;
      }

      .row:last-child {
        margin-bottom: 0;
      }
    }

    .inline-notification-container {
      position: sticky;
      top: 25px;
      z-index: 1;
    }
  }
}
