@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.add-application-deployment-details-container {
  .add-application-deployment-details-content {
    .data-loader {
      display: flex;
      flex-direction: column;

      .skeleton-row {
        padding: $spacing-05;
        border-bottom: 1px solid $gray-20;
        .app-deployment-skeleton {
          width: 100%;
          height: 18px;

          &:first-child {
            width: 100%;
            margin-bottom: $spacing-02;
          }
          &:nth-child(2) {
            width: 40%;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
    .partition-table-container {
      flex: 0 0 25%;
      background-color: $white-0;

      .header {
        @include carbon--type-style('productive-heading-03');
        display: flex;
        justify-content: start;
        align-items: center;
        padding: $spacing-05 $spacing-05 0;
      }

      .partition-table {
        .bx--search {
          margin: 0 $spacing-05;
          width: auto;
          .bx--search-input {
            border-bottom: none;
            height: $spacing-09;
          }
          .bx--search-close {
            width: $spacing-09;
            height: $spacing-09;
          }
        }

        .partition-option {
          display: flex;
          flex-direction: row;
          padding: $spacing-05;
          border-top: 1px solid $gray-20;

          .bx--radio-button-wrapper {
            .bx--radio-button__appearance {
              width: $spacing-05;
              height: $spacing-05;
              margin-right: 0;
            }
          }

          &:last-child() {
            border-bottom: 1px solid $gray-20;
          }

          .partition-option-fields {
            display: flex;
            flex-direction: column;

            .partition-name {
              @include carbon--type-style('heading-01');
            }
            .partition-label {
              @include carbon--type-style('body-short-01');
              color: $gray-70;
            }
          }

          &:hover {
            background: #e5e5e5;
          }
        }

        .empty-state {
          display: flex;
          flex-direction: column;
          padding: $spacing-07 $spacing-07;

          .description {
            padding: $spacing-05 $spacing-05 0;
          }
        }
      }
    }
  }
}
