@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-permission-details-tearsheet {
  .c4p--tearsheet__content {
    padding: 1rem 2rem 0;
    overflow-y: hidden;
    .edit-permission-table-container {
      background: $gray-10;
      height: 100%;

      .edit-permissions-header {
        min-height: 4rem;
        padding: 16px 48px 16px 16px;
        @include type-style('productive-heading-03');
        position: relative;

        .permission-edit-icon {
          position: absolute;
          right: 36px;
        }
      }

      .header {
        min-height: 3rem;
        background: $gray-20;
        display: flex;
        width: 100%;
        justify-content: center;
        padding-left: 32px;
        .bx--row {
          width: 100%;
          align-items: center;
          @include type-style('productive-heading-01');
          flex-wrap: nowrap;

          div {
            display: flex;
            justify-content: start;
            padding-left: 0;

            .bx--tooltip--definition {
              .bx--tooltip__trigger {
                .text {
                  @include type-style('productive-heading-01');
                }
              }
            }
          }
        }
      }

      .cds--tabs.cds--tabs--contained .cds--tabs__nav-item--selected {
        background-color: #fff;
      }

      .cds--tab-content {
        padding: 0;
        overflow-y: scroll;
        height: calc(100% - 3rem);

        .table-header-container {
          position: sticky;
          top: 0;
          z-index: 10;
          .searchBar {
            min-height: 3rem;
            padding: 0;

            .bx--search {
              min-height: 3rem;

              .bx--search-magnifier {
                .icon-with-tooltip {
                  top: 0;
                  left: 14px;
                  button {
                    min-height: 3rem;

                    svg {
                      fill: $gray-100;
                    }
                  }
                }
              }
              input.bx--search-input {
                border: none;
                min-height: 3rem;
              }
              .bx--search-close {
                min-height: 3rem;
              }
            }
          }
        }
      }

      .bx--accordion {
        background: $white-0;
        .bx--accordion__item {
          border-top: none;
          border-bottom: 1px solid $gray-20;

          &.bx--accordion__item--active {
            border-bottom: none;
          }

          .bx--accordion__heading {
            min-height: 3rem;
            align-items: center;
            .bx--accordion__title {
              @include type-style('productive-heading-01');
            }
          }
          .bx--accordion__content {
            padding-right: 1rem;
            padding-bottom: 0;
            padding-top: 0;
            margin-left: 0;
            .bx--row {
              min-height: 3rem;
              align-items: center;
              display: flex;
              border-top: 1px solid $gray-20;
              padding-left: 2rem;
              @include type-style('body-short-01');

              .rg_name {
                @include type-style('body-short-01');
                word-break: break-word;
              }
              .labels {
                display: flex;
                flex-direction: column;
                .bx--tooltip--definition {
                  .bx--tooltip__trigger {
                    padding-top: 3px;
                    .group-check,
                    .resources-check {
                      @include type-style('body-short-01');
                    }
                  }
                }
              }

              &.resource-group-row {
                min-height: 66px;

                .resource-group-col {
                  display: flex;
                  flex-direction: column;
                  padding-left: 0;
                  align-items: start;
                  min-height: 66px;
                  justify-content: center;

                  .group-check,
                  .resources-check {
                    @include type-style('body-short-01');
                    color: $gray-70;
                    word-break: break-all;

                    .bx--checkbox-wrapper {
                      margin: 0;
                      .bx--checkbox-label {
                        min-height: 1rem !important;
                        &::before {
                          top: 0;
                        }
                        &::after {
                          top: 0.375rem;
                        }
                      }
                    }
                  }

                  .group-check {
                    .bx--checkbox-wrapper {
                      margin-bottom: 4px;
                    }
                  }
                  .resources-check {
                    .bx--checkbox-wrapper {
                      margin-top: 4px;
                    }
                  }

                  .empty-check {
                    padding: $spacing-03 + $spacing-01;
                  }

                  &:nth-child(1) {
                    padding-left: 1rem;
                    padding-right: 0;
                  }
                }
              }

              &.resource-type-row {
                .resource-type-col {
                  padding-left: 0;
                  display: flex;

                  .bx--tooltip--definition {
                    .bx--tooltip__trigger {
                      .text {
                        @include type-style('body-short-01');
                      }
                    }
                    .bx--tooltip__trigger.bx--tooltip--visible
                      + .bx--assistive-text {
                      transform: translate(-25%, 100%);
                    }
                  }

                  &:nth-child(1) {
                    padding-left: 1rem;
                    padding-right: 0;
                  }
                }
              }

              .checkbox-tooltip {
                .bx--tooltip__trigger.bx--tooltip--visible
                + .bx--assistive-text {
                  transform: translate(-50%, 100%) !important;
                }
                button.bx--tooltip__trigger {
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
