@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.deployment-env-table-wide-tearsheet {
  z-index: 9999 !important;
  .deployment-table-container {
    .deployment-table-content {
      padding: $spacing-06 $spacing-07;
    }
  }
}
