.add-labels-component {
  width: 100%;

  .input-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .input {
      width: 80%;
    }

    .button {
      width: 20%;
      padding-left: 1rem;
      padding-top: 1.5rem;

      button {
        width: 100%;
      }
    }
  }

  .values-container {
    margin-top: 0.3rem;

    .bx--tag {
      margin-left: 0;
    }
  }
}
