@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-cloud-tearsheet {
  padding: 1.5rem 2rem;
  width: 60%;

  .selected-cloud-card {
    background-color: white;
    height: 88px;
    top: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;

    .selected-cloud-icon {
      display: flex;
    }

    .selected-cloud-name {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  input {
    background-color: white;
  }

  .cds--list-box {
    background-color: white;
  }

  .form-item {
    margin-bottom: 1rem;
  }

  .autodiscover-group {
    display: flex;
    margin-bottom: 1rem;

    .cds--toggle {
      margin-right: 1rem;
    }

    .secret-input-field {
      flex: 100%;
      margin-top: -3px;
      margin-left: 49px;
    }

    .secret-helper-text {
      @include carbon--type-style('helper-text-01');
      padding-top: 0.5rem;
    }
  }

  .selected-cloud-tooltip {
    margin-left: 2rem;
  }

  .helper-text {
    @include carbon--type-style('helper-text-01');
    margin-top: -0.5rem;
  }

  .noSecretsContent {
    width: 100%;
    margin-left: $spacing-08;
    margin-top: $spacing-04;
  }

  .secretLabel {
    @include type-style('body-short-02');
    color: $text-01;
  }

  .no-secret-text {
    @include type-style('body-short-01');
    color: $text-01;
    margin-top: $spacing-03;
    margin-bottom: $spacing-05;
  }

  .secret-link {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.3rem;
    }
  }
  #register-secret-btn {
    border: none;
    background-color: transparent;
    color: #0f62fe;
    padding: 0px;
    margin: 0px;
    min-height: 0px;
  }

  .bx--inline-notification {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
