@import 'carbon-components/scss/globals/scss/spacing.scss';

.edit-identityDetails-form {
  padding: 0 $spacing-05;

  .bx--form-item,
  .bx--list-box__wrapper {
    margin-top: $spacing-05;
  }

  .add-labels-component {
    width: 98%;

    .input-container .button {
      padding-top: $spacing-08;
    }
  }
  .bx--combo-box {
    background-color: #f4f4f4;
  }
}
