@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.configuration-settings-accordion {
  margin-top: $spacing-05;

  .bx--accordion__item {
    background-color: $white-0;
    border-bottom: 0;
    border-top: 0;

    .bx--accordion__heading {
      padding-top: $spacing-05;
      padding-bottom: $spacing-05;
      align-items: center;
      .bx--accordion__title {
        @include carbon--type-style('productive-heading-03');
      }
    }
  }

  .bx--accordion__content {
    padding-left: 0;
    padding-bottom: 0;
    margin-left: $spacing-05;

    .configuration-settings-section {
      &.router-sizing-section,
      &.ingress-settings-section {
        margin-bottom: 16px;
        border-bottom: 1px solid $gray-20;
      }
      .configuration-settngs-section-heading {
        @include carbon--type-style('heading-02');
        padding-bottom: 1rem;
      }

      .configuration-settings-row {
        padding-bottom: 24px;
        width: 100%;

        .field-div-col {
          // margin-bottom: $spacing-06;
          .field-div {
            display: flex;
            flex-direction: column;
            .label {
              margin-bottom: 4px;
              color: $gray-70;
              @include carbon--type-style('label-01');
            }
            .value {
              @include carbon--type-style('body-short-01');
              color: $gray-100;
              overflow: hidden;
              text-wrap: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  @media (min-width: 640px) {
    .bx--accordion__content {
      padding-right: 3rem;
    }
  }
}

.skupper-configuration-skeleton {
  width: 100%;
  margin-top: 1rem;
  height: 60px;
}
