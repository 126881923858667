@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.deployment-env-table-wide-tearsheet {
  z-index: 10000 !important;

  .bx--modal-container--sm {
    z-index: 10000 !important;
  }

  .deployment-table-container {
    .deployment-table-content {
      padding: $spacing-06 $spacing-07;

      .deployment-table {
        .bx--inline-notification--error {
          max-width: 100% !important;
          width: 100%;
          position: sticky;
          z-index: 10;
          top: 0;
        }
      }
    }
  }

  .appGroupContainer {
    padding: 1.5rem 2rem;
  }
  
  .clusterTitle {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
    margin-bottom: 8px;
  }

  .clusterSubTitle {
    @include type.type-style('body-01');
    color: $text-secondary;
    margin-bottom: 16px;
  }
}