@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.details-tile {
  .default.header {
    margin-bottom: $spacing-06;
  }
  .header {
    margin-bottom: $spacing-03;
    display: flex;

    .text {
      @include carbon--type-style('productive-heading-03');
      flex: 1;
    }

    .edit {
      float: right;
      margin-top: -$spacing-03;
    }

    .edit-policy-link {
      margin-top: -($spacing-03 + $spacing-01);
    }

    .edit-link {
      color: $ui-05;
      background-color: $ui-02;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      cursor: pointer;

      &:hover {
        background-color: $ui-03;
      }
    }
    .disabled {
      color: $icon-secondary;
      cursor: not-allowed;
    }

    .disabled-link {
      border: none;
      background: none;
      box-shadow: none;
      color: $text-on-color-disabled;
    }
  }

  .data-container {
    display: flex;
    flex-wrap: wrap;

    .section {
      padding-left: 0;
      margin-bottom: $spacing-06;
    }
    .text--wrap {
      .value {
        text-wrap: wrap;
      }
    }

    .label {
      @include carbon--type-style('label-01');
      color: $gray-70;
      margin-bottom: 6px;
      position: relative;

      .tooltip {
        padding-left: $spacing-02;
        position: absolute;
        bottom: 1px;
      }
    }

    .value {
      @include carbon--type-style('body-short-01');

      .bx--tag {
        margin-left: 0;
      }

      .run-auto-discovery {
        color: $link-01;
        margin-left: $spacing-05;

        .run-button {
          margin-left: $spacing-01;
          margin-bottom: -$spacing-01;
        }

        &:hover {
          cursor: pointer;
        }
      }
      .auto-discover-label {
        .bx--tooltip--definition {
          button {
            border: none;
            &:hover {
              cursor: pointer;
            }
            .autoDiscover-error {
              @include carbon--type-style('body-short-01');
              color: #0f62fe;
            }
          }
        }
      }
      button {
        @include carbon--type-style('body-short-01');
      }
    }

    .label.useEncryptedTypeLabel {
      text-decoration-line: underline;
      text-decoration-style: dotted;
    }

    .value.gateway {
      color: $interactive-01;
    }

    .value.tag-label {
      display: flex;
      align-items: center;

      span {
        margin-left: $spacing-02;
      }
    }
    .image-icon-cont {
      display: flex;
      margin-right: 5px;
    }
    
    .valueIconContainer {
      display: flex;
      align-items: center;
    }
  }

  .auto-discover-label-item {
    display: flex;
    align-items: center;

    .autoDiscoverIcon {
      display: flex;
      padding-right: $spacing-03;
    }
  }
}
