@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.define-environment-details {
  padding: 0.5rem;
  max-width: 90%;

  .select-cloud-dropdown {
    &.disabled {
      .cds--label--disabled {
        color: $gray-70;
      }
      .cds--list-box__menu-icon {
        display: none;
      }
      button {
        background: $gray-10;
        border-bottom: 1px solid $gray-30;
        .bx--list-box__label {
          color: $gray-70;
        }
      }
      .cds--list-box__label {
        .selected-cloud-item {
          display: flex;
          align-items: center;

          .selected-cloud-icon {
            display: flex;
            margin-right: 8px;
            padding-top: 1px;
            img {
              width: 16px;
              height: 16spx;
            }
          }
          .selected-cloud-name {
            display: flex;
            align-items: center;
            color: $gray-70;
          }
        }
      }
    }
  }

  .bx--label--disabled {
    color: $gray-70;
  }

  .select-location-dropdown-column {
    display: flex;
    align-items: flex-end;

    .bx--list-box__wrapper {
      width: 100%;
    }
  }

  .select-location-dropdown {
    &.disabled {
      .bx--list-box__field {
        cursor: not-allowed;
        input {
          background: $gray-10;
          border-bottom: 1px solid $gray-30;
          color: $gray-70;
          pointer-events: none;
        }
        button {
          display: none;
        }
      }
    }
  }

  .step-title {
    @include carbon--type-style('productive-heading-03');
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;

    .manage-checkbox-section {
      position: absolute;
      right: 1rem;
      height: 40px;
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 16px;
    }
  }

  .bx--inline-notification {
    margin-top: 0;
  }

  .c4p--tearsheet-create__step--title {
    display: none;
  }

  .bx--text-input__readonly-icon {
    display: none;
  }

  #readOnly-field {
    border-bottom-color: #e0e0e0;
    background-color: transparent;
  }

  .select-resourcegroup-type {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .bx--dropdown__wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  .deployment-env-name {
    display: flex;
    flex-direction: column;
  }

  .row {
    margin-top: 1.5rem;
  }

  #description-field {
    background-color: $background;
  }

  .label-field-row {
    min-width: 100%;
    margin-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .description-field-row {
    min-width: 100%;
    margin-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .deployed-in-vpc-column {
    max-width: 50%;
  }

  .vpc-checkbox-column {
    padding-left: 1.4rem;

    .vpc-use {
      display: flex;
      justify-items: center;
    }

    .vpc-use-title {
      @include carbon--type-style('label-01');
      color: #525252;
      font-weight: 500;
      padding-right: 0.5rem;
    }

    .checkbox-label {
      padding-left: 0.4rem;
      margin-top: -0.2rem;
      display: flex;
      justify-content: center;

      .checkbox-label-text {
        padding-left: 0.25rem;
        margin-top: 0.2rem;
      }
    }

    .vpc-use-helpertext {
      padding-top: 0.25rem;
      @include carbon--type-style('label-01');
      color: #525252;
      padding-bottom: 0.4rem;
    }
  }
  .generic-notAuthorised-container {
    .label {
      display: flex;
      margin-bottom: 6px;
      @include carbon--type-style('label-01');
      color: $gray-70;
    }
    .generic-status-container {
      display: flex;
      height: 40px;
      align-items: center;
    }
  }
}
