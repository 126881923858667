@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';


.application-depl-details {
  background-color: $ui-01;

  .header__title_appdepl{
    display: inline-flex;
  }

  .appl-depl-details-card {
    background-color: $ui-01;
    padding-top: 1rem;

    .details-tile-skeleton {
      width: 100%;
      min-height: 20rem;
    }

    .details-tile{
      .data-container .value:nth-child(4){
        white-space: normal;
      }
    } 
  }
  .delete-application-deployment-modal {
    .bx--modal-container{
      width: 35rem;
      height: auto;
    }
  }

  .deploymentDeteteButton{
    margin-left: 1rem;
    margin-bottom: 4rem;
  }
}