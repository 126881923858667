@import 'carbon-components/scss/globals/scss/typography.scss';
.topology-legend-tools_indicators {
  padding-top: 10px;
  .legend-tools-indicators-header {
    @include carbon--type-style('productive-heading-01');
    padding-bottom: 18px;
  }
  .legend-tools-indicators-label {
    @include carbon--type-style('body-long-01');
    padding-bottom: 18px;
    color: $gray-100;
  }
}
