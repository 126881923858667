@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-details-tile {
  .header {
    margin-bottom: $spacing-06;
    display: flex;

    .text {
      @include carbon--type-style('productive-heading-03');
      flex: 1;
    }

    .edit {
      float: right;
      margin-top: -$spacing-03;
    }

    .edit-link {
      color: $ui-05;
      background-color: $ui-02;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      cursor: pointer;

      &:hover {
        background-color: $ui-03;
      }
    }
  }

  .data-container {
    // For medium screens we will render 2 columns of equal width
    @media (max-width: 1000px) {
      .section {
        .sub-section {
          width: 50% !important;
        }
      }
    }

    // For small screens we will render 1 column
    @media (max-width: 700px) {
      .section {
        .sub-section {
          width: 100% !important;
        }
      }
    }

    .section {
      padding-left: 0;
      margin-bottom: $spacing-06;
      display: flex;
      flex-wrap: wrap;

      .sub-section {
        min-height: 4.5rem;
        width: 33.33%;
        padding-right: $spacing-04;

        .no-underline-link {
          color: inherit;

          &:hover {
            color: $blue-70;
          }
        }
      }

      .intended-section,
      .ingress-section {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .intended-version {
          display: flex;
          align-items: center;
          gap: 4px;
          span {
            display: flex;
            gap: 4px;
            color: $link-primary;
            cursor: pointer;
          }
          .bx--link--disabled {
            span {
              color: $disabled-02;
              cursor: not-allowed;
            }
          }
        }
      }

      .description-section {
        width: 100%;
        display: flex;
        .sub-section {
          &:first-child {
            width: 66.66%;
          }
        }
      }
      &.waypoint-section {
        .sub-section {
          width: 25%;
        }
        .description-section {
          .sub-section {
            width: 50%;
          }
        }
      }
    }

    .text {
      vertical-align: text-top;
      padding-left: 0.2rem;
    }

    .label {
      @include carbon--type-style('label-01');
      color: $gray-70;
    }

    .value {
      @include carbon--type-style('body-short-01');

      .bx--tag {
        margin-left: 0;
      }

      color: $text-01;

      .procedural-status-component {
        .bx--inline-loading__text {
          color: $text-01;
        }

        .label {
          @include carbon--type-style('body-short-01');
          color: $text-01;
        }

        &.tooltip {
          cursor: pointer;

          .label:hover {
            color: $link-01;
          }
        }
      }
    }

    .label.useEncryptedTypeLabel {
      text-decoration-line: underline;
      text-decoration-style: dotted;
    }

    .value.gateway {
      color: $interactive-01;
    }

    .value.tag-label {
      display: flex;
      align-items: center;

      span {
        margin-left: $spacing-02;
      }
    }
  }

  .edit-disabled-tooltip {
    .bx--tooltip__trigger svg,
    .bx--tooltip__trigger svg:focus,
    .bx--tooltip__trigger svg:hover {
      fill: $disabled-02;
    }
    .edit-link {
      border-bottom: none;
      cursor: not-allowed;
    }
    .edit-link:focus {
      border-color: transparent;
      box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 2px #f4f4f4;
    }
  }
}
