@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
.breadcrumb-container {
  padding-bottom: $spacing-02;
  padding-top: $spacing-04;

  .bx--breadcrumb.bx--skeleton {
    .bx--breadcrumb-item {
      width: 6rem;
    }
  }

  .breadcrumb-label {
    &::after {
      color: $active-light-ui;
    }
    a {
      @include carbon--type-style('label-01');
      text-decoration: none;
      color: blue;
    }
    .bx--link {
      cursor: pointer;
    }
  }
      /*
      MAKE SURE THESE ALIGN TO PROTOTYPES SUPPLIED BY DESIGN

      &:visited {
        color: gray;
      }
      *
    }

    /*
    &.bx--breadcrumb-item--current a {
      color: $active-light-ui;
    }
    */
  
}
