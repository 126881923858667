@import 'carbon-components/scss/globals/scss/spacing.scss';

.auto-discover-label {
  display: inline-flex;
  flex-direction: row;
  gap: $spacing-02;

  .auto-discover-label-item {
    display: flex;
  }

  .value {
    margin-top: -$spacing-01;
  }
}