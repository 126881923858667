@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
.icon-with-tooltip {
  position: absolute;
  .icon-text {
    padding-left: 1rem;
    font-size: $spacing-04 + $spacing-01;
    font-weight: 400;
    line-height: $spacing-05 + $spacing-02;
    font-family: IBM Plex Sans;
    text-align: left;
    color: $text-05;
  }
  img {
    margin-top: -0.6rem;
  }
  .bx--btn {
    min-height: 1px;
    width: 1rem;
    height: 1rem;
    &.bx--tooltip__trigger:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .bx--btn--icon-only {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .bx--btn--ghost:hover {
    background-color: transparent;
  }

  .bx--btn--ghost:active {
    color: $white-0;
  }
}
