@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.find-and-filter-container {
  background-color: $ui-01;

  .bx--dropdown {
    background-color: $ui-02;
  }

  .bx--row {
    margin-right: 0;
    margin-left: 0;
  }

  .bx--search-input {
    background-color: $ui-02;
    border-bottom-color: $gray-20;
    height: $spacing-09 !important;
  }

  .bx--search-close {
    width: $spacing-09;
    height: $spacing-09;
  }

  .bx--list-box {
    background-color: $ui-02;
  }

  .bx--label {
    margin-top: 1rem;
  }

  .search-side-buttons {
    color: black;
    padding: $spacing-05;
    background-color: $ui-02;
    padding-top: $spacing-05 + $spacing-01;
    height: $spacing-09;
    margin-left: -1px;
    border-bottom-color: $gray-20;
    svg {
      right: 0.9rem;
    }

    &:hover {
      background-color: $ui-03;
    }
  }

  .active-filter {
    margin-left: 0px;
    margin-bottom: 0px;
    border-bottom: -1px solid $white-0;
    border-right: 1px solid $gray-20;
    border-top: 1px solid $gray-20;
  }

  .filter-and-filter-row {
    background-color: $white-0;
    padding-bottom: 1rem;
    border-top: 1px solid $gray-20;
    margin-top: -1px;

    .bx--row {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
    }

    .dropdown {
      .cds--list-box__wrapper {
        margin-top: 1rem;
      }

      .bx--dropdown {
        .bx--list-box__field,
        .bx--list-box__menu {
          background-color: $gray-10;

          .dropdown-icon {
            text-align: left;
            justify-items: center;
            position: relative;
            display: flex;
            align-items: center;

            .icon-filter-dropdown {
              position: relative;
              display: flex;
              align-items: center;
              padding-right: $spacing-01 + $spacing-02;
            }
          }
        }

        .bx--list-box__field {
          box-shadow: inset 0px -1px 0px #8d8d8d;
        }

        .bx--list-box__label {
          color: $gray-40;
        }
      }

      .bx--multi-select {
        .bx--list-box__field,
        .bx--list-box__menu {
          background-color: $gray-10;
        }

        .bx--list-box__field {
          box-shadow: inset 0px -1px 0px #8d8d8d;
        }

        .bx--list-box__label {
          color: $gray-40;
        }
      }

      .bx--multi-select--selected {
        .bx--list-box__field--wrapper {
          box-shadow: inset 0px -1px 0px #8d8d8d;
          background-color: $gray-10;
        }
      }
    }
  }

  .filter-buttons-container {
    display: flex;
    align-items: center;
    background-color: $white-0;

    &.filter-selected {
      border-top: 1px solid $gray-20;
      padding: 12px 16px;
    }

    .buttons {
      margin-right: 0.5rem !important;
      margin-bottom: 1rem;
    }

    .clearButton {
      margin-left: $spacing-02;
      color: $blue-60;
      cursor: pointer;
    }

    .bx--tag--filter {
      margin: 0 $spacing-02;

      .bx--tag__label {
        color: $gray-100;
        @include carbon--type-style('caption-01');

        .filter-tag-label {
          position: relative;
        }
      }
    }
  }

  .searchBar {
    padding-right: 0;
    margin-right: 0;
    padding-left: 0;
    background-color: $white-0;
    height: $spacing-09;

    .bx--search-magnifier {
      .icon-with-tooltip {
        top: 12px;
        left: 14px;

        button {
          margin-top: 1.5px;

          .bx--assistive-text {
            transform: translate(-10%, 100%);
          }

          svg {
            fill: $gray-70;
            overflow: initial;
          }
        }
      }
    }
  }

  .leftFiltersContainer {
    display: flex;
    align-items: center;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid $gray-20;

    .dropdown {
      background-color: $white-0;
      height: 100%;
      padding-top: $spacing-02;
      .bx--dropdown__wrapper {
        button.bx--list-box__field {
          width: 288px;
          padding: 15px $spacing-05 15px $spacing-05;
        }
      }
    }

    .bx--label {
      margin-top: 0;
      padding-left: 1rem;
    }

    .bx--dropdown .bx--dropdown--inline .bx--list-box {
      margin-left: -2rem;
    }

    .bx--list-box {
      border-bottom: none;
    }
  }

  .leftInlineMultiSelectFilter {
    display: flex;
    align-items: center;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid $gray-20;
    height: $spacing-09;

    .cds--list-box {
      border-block-end: 0;
    }

    .cds--multi-select--filterable {
      background-color: $ui-02;
    }

    .dropdown {
      background-color: $white-0;
      height: 100%;
      display: flex;

      .cds--label {
        padding-left: $spacing-05;
      }
    }

    .bx--label {
      margin-top: 0;
      padding-left: 1rem;
    }

    .bx--dropdown .bx--dropdown--inline .bx--list-box {
      margin-left: -2rem;
    }

    .bx--list-box {
      border-bottom: none;
    }
  }

  .action-button {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $blue-60;
    background-color: $white-0;

    &:hover {
      background-color: $gray-20;
    }
  }

  .action-button-icon {
    margin-left: 0.5rem;
  }

  .tableSearch {
    .bx--search-close {
      height: $spacing-09;
    }
  }

  .emptyContainer {
    background-color: $white-0;
  }

  .view {
    @include carbon--type-style('body-short-01');
    color: $text-02;
    margin: $spacing-04;
    color: #525252;
    line-height: $spacing-06;
  }

  .bx--text-input::placeholder {
    color: $text-primary;
    opacity: 1;
  }
}
