@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.register-location-modal,
.edit-location-modal {
  .register-location-body {
    padding: 0 2rem 1.5rem 2rem;
    .bx--row {
      padding-top: 1.5rem;
    }
    .register-location-form {
      .bx--row {
        padding-bottom: 1.5rem;
      }
      .col-location-cloud {
        .bx--list-box__menu-item__option,
        .bx--list-box__label {
          display: flex;
          align-items: center;
          .dropdown-icon {
            display: flex;
            align-items: center;
            .icon-filter-dropdown {
              padding-right: $spacing-04 - $spacing-01;
              &.option-type-AWS {
                padding-top: 5px;
              }
            }
          }
        }
      }
      .col-location-name,
      .col-location-noEdit-cloud,
      .col-location-noEdit-type {
        .bx--text-input__readonly-icon {
          display: none;
        }
        .bx-text-input {
          border-bottom: 1px $border-disabled;
        }
        .bx--text-input--invalid,
        .bx--text-input--warning,
        .bx--text-input-wrapper--readonly .bx--text-input {
          background-color: unset;
        }
      }
      .col-location-noEdit-cloud {
        position: relative;

        .bx--text-input--invalid,
        .bx--text-input--warning,
        .bx--text-input-wrapper--readonly .bx--text-input {
          padding-left: 2.5rem;
          position: relative;
        }

        .icon-filter-dropdown {
          position: absolute;
          left: $spacing-06;
          top: 30px;
          z-index: 9007;
          &.option-type-AWS {
            top: 34px;
          }
        }
      }
      .col-location-name {
        padding-top: 2.5px;
      }
    }
    .sub-header-section {
      .sub-header {
        color: $text-01;
        font-family: IBM Plex Sans;
        font-size: $spacing-06 - $spacing-02;
        font-weight: 400;
        line-height: $spacing-08 - $spacing-01;
        display: flex;
        align-items: center;
      }
      .bx--col-md-2 {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .cancel-location-modal {
      .bx--modal-container {
        max-height: 35%;
      }
    }

    .address-label {
      @include carbon--type-style('label-01');
      color: $text-02;
    }

    .address-tile {
      margin-top: 0.5rem;
    }

    .address-line {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .address-location {
        display: flex;
        align-items: center;

        .location-icon {
          margin: 0.25rem 0.25rem 0 0;
        }
      }
    }

    .label {
      @include carbon--type-style('label-01');
      color: $gray-70;
    }

    .value {
      @include carbon--type-style('body-short-01');
      margin-top: $spacing-02;
      .bx--tag {
        margin-left: 0;
      }
    }

    .address-close-icon {
      min-height: 0.5rem;

      .bx--btn {
        min-height: 0;
        padding: 0;
      }

      .bx--btn--ghost:hover {
        &::before {
          display: none;
        }
        .bx--assistive-text {
          display: none;
        }

        background-color: transparent;
      }
    }

    .address-sub-row {
      padding-top: 1rem;
    }

    .col-location-cloud {
      .location-cloud-option {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .auto-discover-icon {
          opacity: 0.5;
        }
      }
    }

    .zone-dropdownItem {
      .bx--list-box__menu {
        cursor: no-drop;
        .bx--list-box__menu-item:nth-child(3) {
          pointer-events: none;
          opacity: 0.65;
          filter: alpha(opacity=65);
          box-shadow: none;
        }
      }
    }
  }
  .bx--modal-header__heading {
    .auto-discover-title {
      display: flex;
      align-items: center;
      justify-content: start;
      .auto-discover-icon {
        padding-left: 0.5rem;
      }
    }
  }
  .inline-notification-container {
    padding: 0 2%;
  }
}
