@import 'carbon-components/scss/globals/scss/spacing.scss';
.create-policy-tearsheet {

  .connection-access-policy-error-snackbar {
    position: sticky;
    top: 0;
    z-index: 10000;
  }
}
.connection-access-policy-error-snackbar {
  margin-left: -$spacing-06;
  margin-right: -$spacing-06;

  .notification {
    margin-top: -$spacing-06;
    margin-bottom: $spacing-07;
  }
}
