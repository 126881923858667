@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.network-segments-container {
  .bx--data-table {
    td .bx--tag {
      white-space: nowrap;
      margin: 0 0.25rem 0 0;
    }
    .networksegment-name {
      display: flex;
      a {
        @include carbon--type-style('label-02');
        color: #161616;
      }

      &.unmanaged a {
        @include carbon--type-style('label-02');

        color: #6f6f6f;
      }

      .unmanaged-icon {
        height: $spacing-04;
        width: $spacing-04;
        border: 2px solid $gray-30;
        border-radius: 50%;
        margin-right: $spacing-02;
        margin-top: $spacing-02;
      }
    }
    .networksegment-gatewaySet,
    .networksegment-infrastructureGroup,
    .networksegment-description,
    .networksegment-metricsCollection,
    .networksegment-label {
      @include carbon--type-style('label-02');
      color: $gray-100;

      &.unmanaged {
        @include carbon--type-style('label-02');
        color: $gray-60;
      }
      .overflow-component
        .overflow-wrap
        .bx--tooltip__trigger.bx--tooltip__trigger--definition {
        color: $gray-100;
      }
    }
  }

  .no-underline-link {
    text-decoration: none !important;
  }
  .procedure-field {
    .bx--inline-loading__text {
      font-size: $spacing-04 + $spacing-01;
    }
  }
  .generic-truncate-string {
    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: none;
      font-size: inherit;
      text-align: left;
      color: inherit;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
      outline: none;
    }
  }
  .description-container {
    max-width: 20rem;
    .description {
      max-width: 150%;
      display: inline-block;
      direction: ltr;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media screen and (max-width: 1200px) {
      .description {
        max-width: 100%;
      }
    }
  }
}
