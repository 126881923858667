@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.cloud-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;
  }

  .bx--modal-container {
    height: 100%;
  }

  .cloud-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    justify-content: flex-end;

    .bx--text-input {
      padding-right: 3.9rem;
    }

    .unmanaged-checkbox {
      margin-left: $spacing-03;
    }

    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;

      button {
        background-color: $ui-background;
        color: $ui-05;
      }

      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }

  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }

    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }

  .resource-card {
    margin: $spacing-03 0;
    padding-right: $spacing-03;
    padding-left: $spacing-03;

    .cloud-card {
      margin: $spacing-03 0;
      padding-right: $spacing-03;
      padding-left: $spacing-03;
    }
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }
  }

  .cds--grid {
    margin: 0;
  }

  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;

    .resouce-group-skeleton {
      width: 100%;
      height: $spacing-13;
    }

    .no-resource-group {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-13 + $spacing-07;
    }
  }

  .add-rg-modal {
    .bx--form-item {
      margin-bottom: $spacing-05;
    }

    .bx--tooltip-definition {
      @include carbon--type-style('body-short-01');
    }
  }

  .auto-discover-label-item {
    align-items: center;
  }

  .autodicovery-icon {
    display: flex;
    margin-right: 4px;
  }

  .skeleton-sort-drop-down {
    display: flex;
    margin-left: -$spacing-05;
    height: $spacing-07;
    overflow: hidden;
    width: 100%;
    margin-right: $spacing-05;

    .sorting-skeleton {
      height: $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }
  
  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
      .bx--tooltip__trigger {
        color: $hover-primary-text;
        cursor: pointer;
      }
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }
}
