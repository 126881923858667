@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-tooltip-partition {
  background-color: $white-0;
  min-width: $spacing-13;
  min-height: $spacing-11;

  color: $text-01;
  border-left: 3px solid $teal-70;

  &.unmanaged {
    border-left: 3px solid $gray-60;
  }

  .header {
    font-family: 'IBM Plex Sans Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 9px;
    padding: $spacing-03;
    border-bottom: 1px solid $gray-20;
    display: flex;
    flex-direction: row;
    align-items: center;
    .header-title {
      flex: 0 0 85%;
      line-height: 1.25;
    }
    .page-title-icon {
      display: flex;
      align-items: center;
      position: relative;
      top: 5px;
    }
  }

  .body {
    padding: $spacing-03;

    .label {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;

      padding-bottom: $spacing-02;
    }
  }
}
