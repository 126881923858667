@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.register-env-tearsheet {
  padding-top: $spacing-08;

  .bx--modal-header {
    .c4p--tearsheet__header-content {
      padding-top: 18px;
      margin-top: -1.6rem;
    }
  }
}

.edit-env-tearsheet {
  padding-top: $spacing-08;

  .bx--modal-header {
    .c4p--tearsheet__header-content {
      padding-top: 18px;
      margin-top: -1.6rem;
    }
  }
}
