@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.create-secrets-form {
  padding: 24px 32px;

  .row.bx--row {
    padding-bottom: 32px;
  }

  .ip-row {
    display: flex;
    margin-left: 1px;
  }

  .eachIp {
    flex: 1;
    margin-right: 10px;
  }

  .edit-secret-label {
    font-size: 12px;
    color: #525252;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
  }

  .value {
    height: 2.5rem;
    max-height: 2.5rem;
    /* align-items: center; */
    /* display: flex; */
  }

  .manager-type {
    .bx--combo-box {
      .bx--list-box__selection {
        display: none;
      }
    }
  }

  .secret-details-doc {
    .doc-info-header {
      color: $text-01;
      @include carbon--type-style('productive-heading-01');
    }

    .doc-info-details {
      margin-top: 16px;

      .info-details {
        @include type-style('body-short-01');
        margin-bottom: 16px;
      }

      .secret-info-link {
        @include type-style('body-short-01');
        color: $link-01;
        text-decoration: none;
        display: flex;
        align-items: center;

        .link-icon {
          display: flex;
          padding-left: 1px;
        }
      }
    }
  }

  .bx--col-md-3 {
    max-width: 400px;
  }

  .bx--col-md-6 {
    max-width: 800px;
  }

  #create-secret-manager-type,
  #create-secret-secretType-edit,
  #create-secret-name-edit {
    background: transparent;
    border-color: #c6c6c6;
  }
  .bx--text-input__readonly-icon {
    display: none;
  }

  .acessKeyContainer {
    padding-top: 16px;

    .apiKeyLabel {
      color: $text-01;
      @include carbon--type-style('productive-heading-01');
      padding-bottom: 24px;
    }
  }

  .secretAccessKeyContainer {
    padding-top: 32px;
  }
  .secret-accessKeyId {
    min-width: 100%;
  }

  #edit-secret-apiKey {
    &::placeholder {
      color: $text-01;
      font-size: 6px;
      font-style: normal;
      font-weight: 400;
    }
  }

  #edit-secret-secretAccessKey {
    &::placeholder {
      color: $text-01;
      font-size: 6px;
      font-style: normal;
      font-weight: 400;
    }
  }

  #edit-secret-accessKeyId {
    &::placeholder {
      color: $text-01;
      font-size: 5px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .password-empty {
    .bx--text-input--password__visibility__toggle {
      visibility: hidden;
    }
  }

  .path-docs-header {
    padding-top: 14px;
  }

  .secretDetailsLabel {
    color: $text-01;
    @include carbon--type-style('productive-heading-01');
    padding-bottom: $spacing-06;
  }
  .kubernetesKeyContainer {
    padding-top: 16px;
    padding-bottom: 0px !important;

    .apiKeyLabel {
      margin-bottom: $spacing-06;
      @include carbon--type-style('productive-heading-01');
    }
  }
  .docSec {
    padding-bottom: 16px;
  }
}

.bx--modal-container[aria-label='Edit secret'] {
  .bx--inline-notification__title {
    padding-right: 0;
  }

  .c4p--tearsheet__header-description {
    max-width: initial;
  }
}

#register-secret-container {
  .inline-notification-container {
    position: sticky;
    top: 0;
    z-index: 9;
  }

  .data-loader {
    display: flex;
    flex-direction: column;

    .skeleton-row {
      padding: $spacing-05;
      border-bottom: 1px solid $gray-20;

      .secret-skeleton {
        width: 100%;
        height: 18px;

        &:first-child {
          width: 100%;
          margin-bottom: $spacing-02;
        }

        &:nth-child(2) {
          width: 40%;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
