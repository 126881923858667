@import 'carbon-components/scss/globals/scss/spacing.scss';
@import '@carbon/themes/scss/themes';

@import 'carbon-components/scss/globals/scss/typography.scss';

.deploy-gateway-tearsheet {
  padding-top: 2.5rem;
  
  .deploy-gateway-details {
    max-width: 800px;
    #network-seg-compactibility{
      background-color: transparent;
    }
    #gateway-network-segment {
      .bx--list-box__menu {
        .bx--list-box__menu-item {
          &:last-child {
            .bx--list-box__menu-item__option {
              display: flex;
              align-items: center;
              padding-top: 0;
              padding-bottom: 0;

              .create-network-segment-button {
                color: $blue-60;
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .c4p--tearsheet-create__step--fieldset {
    width: 100%;
    @include carbon--type-style('productive-heading-03');
    color: $text-01;

    .tearsheet-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  svg.bx--text-input__readonly-icon {
    display: none;
  }
}