@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-onboarding-status-component {
  margin-bottom: $spacing-05;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  background: linear-gradient(90deg, #001d6c -3.12%, #6929c4 80.7%);
  padding: 16px;

  .onboard-status-messege {
    color: $gray-20;
    margin-right: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    .status-title {
      color: white;
      font-size: 16px;

      &.step-2,
      &.step-3,
      &.step-4 {
        margin-bottom: 0px;
      }
      &.step-1,
      &.step-5 {
        margin-bottom: 24px;
      }
    }

    .icon-messege {
      width: 223px;
      height: 88px;

      .loading-icon {
        height: 56px;
        width: 56px;
        margin-bottom: 24px;
      }

      .onboard-description {
        font-size: 14px;
        font-weight: normal;

        &.step-2,
        &.step-3,
        &.step-4 {
          margin-top: 17px;
        }

        &.step-1,
        &.step-5 {
          margin-top: -9px;
        }
      }
    }

    .icon-messege-cluster {
      width: 244px;
      height: 88px;

      &.step-2,
      &.step-3 {
        margin-top: 46px;
        margin-bottom: -21px;
      }

      &.step-1,
      &.step-5 {
        margin-top: 51px;
        margin-bottom: -32px;
      }

      &.step-4 {
        margin-top: 28px;
        margin-bottom: -12px;
      }

      .onboard-description-cluster {
        font-size: 14px;
        font-weight: normal;

        &.step-2,
        &.step-3 {
          margin-bottom: 18px;
        }
        &.step-1,
        &.step-5 {
          margin-bottom: 9px;
        }
        &.step-4 {
          margin-bottom: 18px;
        }
      }
    }

    &.without-icon {
      margin-right: 4.8%;
    }
  }

  .center-alignment {
    width: 2px;
    height: 138px;
    border: 1px solid var(--Default-Purple-60, #8a3ffc);
    margin-right: 1%;
  }

  .onboard-progress-indicator {
    align-items: center;
    width: 100%;
  }

  .progress-steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-right: 0;
    border-radius: 10px;
    position: relative;

    .progress-step {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
      color: #ffffff;
      width: 100%;

      &:last-child {
        width: 50%;
      }

      .line {
        height: 2px;
        background-color: #a56eff;
        position: absolute;
        width: 88%;
        top: 10px;
        left: 22px;

        &.complete {
          background-color: #ffffff;
        }
      }

      &:first-child .line {
        top: 16px;

        &.complete {
          top: 10px;
        }
      }

      .step-loading-icon {
        width: 20px;
        height: 16px;
      }

      .step-label {
        color: $gray-20;

        &.complete {
          color: $white-0;
        }

        .step {
          font-size: 14px;
          font-weight: 600;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .status {
          font-size: 12px;
          width: 152px;
          height: 7px;

          .tooltip-text {
            border-bottom: 1px dotted $blue-50;
          }
        }
      }
    }
  }

  .tooltip-text {
    cursor: pointer;
  }

  .waiting-for-closure-tooltip-messege
    .cds--popover-content.cds--tooltip-content {
    width: 187px;
  }
}
