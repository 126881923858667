@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.c4p--side-panel__container .c4p--side-panel__collapsed-title-text {
  color: #ffffff;
  font-size: var(--cds-productive-heading-03-font-size, 1.25rem);
  font-weight: var(--cds-productive-heading-03-font-weight, 400);
  line-height: var(--cds-productive-heading-03-line-height, 1.4);
  letter-spacing: var(--cds-productive-heading-03-letter-spacing, 0);
}

.onboard-network-segment-modal {
  .bx--modal-header__heading.c4p--tearsheet__heading {
    color: white;
    @include type.type-style('heading-03');
  }

  .cds--btn--icon-only > :first-child {
    color: #ffffff;
  }
  .onBoardModalContainer {
    padding: 32px 24px 0px 24px;

    .onboardingSubtitle {
      @include type-style('body-short-01');
      color: $text-secondary;
      max-width: 639px;
      .learnMoreLink {
        text-decoration: none;
        margin-left: $spacing-03;
      }
    }
  }

  .installAgentContainer {
    display: flex;
  }

  .content-title {
    @include type-style('body-short-01');
    color: $text-primary;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .content-data {
    @include type-style('body-short-01');
    color: $text-secondary;
  }

  .installAgent {
    flex: 1;
    margin-right: 48px;
  }

  .docsSection {
    flex: 1;
  }

  .agent-info-link {
    @include type-style('body-short-01');
    color: $link-01;
    text-decoration: none;
    display: flex;
    align-items: center;

    .link-icon {
      display: flex;
      padding-left: 1px;
    }
  }

  .faq-section {
    margin: 0px 0px 24px 0px;
  }

  .faq-qn {
    @include type-style('body-short-01');
    color: $text-primary;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .faq-ans {
    @include type-style('body-short-01');
    color: $text-secondary;
  }

  .faqContentContainer {
    max-width: 664px;
  }

  .networkListContainer {
    display: flex;

    .networkCard {
      width: 292px;
      height: 116px;
      padding: 16px;
      border: 1px solid #e0e0e0;
      margin-right: 16px;

      &:hover {
        background-color: #e8e8e8;
      }

      &.unClicked {
        .unselected-icon {
          display: none;
        }
      }

      &.clicked {
        border: 1px solid $gray-100;
      }

      .unknown-count {
        @include carbon--type-style('label-01');
        color: $text-secondary;
      }

      .count {
        @include carbon--type-style('label-01');
        color: $text-secondary;
        //margin-left: 14px;
      }

      .unknown-status {
        @include carbon--type-style('body-short-01');
        color: $text-secondary;
        display: flex;
        margin-top: 24px;
        align-items: center;
      }

      .waiting-nseg-completion-status {
        @include carbon--type-style('body-short-01');
        display: flex;
      }

      .known-status {
        @include carbon--type-style('body-short-01');
        color: $text-secondary;
        display: flex;
        margin-top: 22px;
        align-items: center;
        // margin-left: 13px;
      }

      .selected-icon {
        margin-left: 96%;
        margin-top: -9%;
      }

      .inline-edit-selected-icon {
        margin-left: 96%;
        margin-top: -11%;
      }

      .warning-icon {
        color: #0f62fe;
        margin-right: 4px;
      }
    }

    .unknown-network-segment-name {
      @include type.type-style('body-compact-02');
      color: $text-primary;
      display: flex;
    }
    .known-network-segment-name {
      @include type.type-style('body-compact-02');
      color: $text-primary;
      display: flex;
      .inline-edit {
        width: 100%;
        margin-left: var(--cds-spacing-05, -1rem);
        margin-top: -7px;
      }
      .no-underline-link {
        // margin-left: 16px;
      }
    }
  }
}
