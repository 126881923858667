@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.cloud-location-card {
  height: 92px;
  padding: $spacing-05;
  padding-top: 0;
  border-right: 1px solid $gray-20;
  cursor: pointer;

  &.selected {
    background: $white-0;
    border-left: 4px solid $blue-60;
    padding-left: $spacing-04;

    border-top: 1px solid $gray-20;
    border-bottom: 1px solid $gray-20;
    border-right: none;
  }

  .title-session {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-group {
      display: flex;
      align-items: center;

      .icon {
        margin-top: $spacing-02;
      }

      .title {
        @include type.type-style('heading-compact-02');
        margin-left: $spacing-03;
      }

      .unmanaged-label {
        @include type.type-style('body-01');
        margin-left: $spacing-03;
        padding-left: $spacing-03;

        border-left: 1px solid $gray-30;
      }
    }

    .button-group {
      padding-top: $spacing-03;
      visibility: hidden;
      min-width: 68px;

      button {
        min-height: 32px;
        padding: $spacing-03;
        color: $blue-60;

        &.bx--btn--ghost.bx--btn--icon-only svg path {
          fill: $blue-60;
        }

        &.bx--btn--ghost:hover {
          background-color: #D6D6D6;
        }
      }
    }
  }

  .address-session {
    display: flex;
    align-items: center;
    padding-bottom: $spacing-01;

    @include type.type-style('label-01');

    .name {
      padding-left: $spacing-01;
    }
  }

  .depl-env-session {
    @include type.type-style('label-01');
  }

  &.managed {
    color: $text-primary;

    .title {
      color: $text-primary;
    }
  }

  &.unmanaged {
    color: $text-helper;

    &.selected {
      border-left: none;
      padding-left: $spacing-05;
    }
  }

  &:hover {
    background: $gray-10-hover;

    .button-group {
      visibility: visible;
    }
  }
}
