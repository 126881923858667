@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.role-details-page {
  .page-content {
    padding: 1rem;
    background-color: $gray-10;
    flex: 1 1;
    .bx--tooltip--definition {
      margin-top: $spacing-05;
      margin-bottom: 4rem;
    }
    .bx--tooltip__trigger {
      border: none;
    }
    button.delete-role-disabled {
      background: none;
      color: $gray-30;
      border: none;
      outline: none;
    }
    .role-details-card {
      .role-details-card-container {
        .role-details-skeleton {
          width: 100%;
          min-height: 20rem;
        }
      }
    }
    .delete-role-modal {
      .bx--modal-container {
        width: 38%;
        max-height: 14.5rem;
      }
    }
  }
}
