@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.event-groups-table {
  .bx--data-table {
    td {
      background-color: $white-0;
    }

    .bx--btn {
      max-width: fit-content;
    }

    tr td:nth-child(4) {
      padding: 0.938rem 1.063rem;
    }
  }

  .events-emptyContainer:hover td {
    background-color: $white-0;
    margin-top: none;
    margin-bottom: none;
  }

  .events-emptyContainerCol {
    border-top: none;
    border-bottom: none;
    background-color: $white-0;
    color: $gray-60;

    .header {
      @include carbon--type-style('productive-heading-03');
      color: $text-01;
    }

    .description {
      @include carbon--type-style('body-long-01');
      margin-bottom: $spacing-05;
      color: $text-01;

      .vertical-empty-state {
        justify-content: flex-start;
        align-items: center;
      }
    }

    .bx--data-table-container {
      margin-bottom: 0px;

      .bx--toolbar-content {
        z-index: 10;
      }
    }

    .events-tablePagination {
      background-color: $white-0;
      height: 3rem;

      .bx--select-input {
        background-color: $white-0;
      }

      .bx--pagination__control-buttons {
        height: 100%;

        button {
          height: 100%;
        }
      }
    }

    .events-emptyContainer {
      background-color: $white-0;

      .bx--btn {
        max-width: fit-content;
      }
    }
  }
  .bx--modal-container {
    height: auto;
  }

  .line-break {
    white-space: pre-wrap;
  }
  .select-all-checkbox {
    margin-top: 0.6rem;
    margin-left: 0.4rem;
  }
}
