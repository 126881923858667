@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import '@carbon/themes/scss/themes';

.policy-filter {
  margin: $spacing-05 0 $spacing-05 0;
  height: $spacing-07;
  .searchBar.bx--col {
    padding-left: 0px;
    padding-right: 0px;
  }
  .autofill-search-component {
    background-color: $gray-10;
    .bx--search {
      .bx--search-input {
        border: none;
        border-bottom: 1px solid transparent;
        background-color: $gray-10;
        @include carbon--type-style('label-02');
        cursor: pointer;
        &::-ms-input-placeholder {
          color: $gray-40;
        }
        &::placeholder {
          color: $gray-40;
        }
      }
    }
    .enabled-search {
      .bx--search-input {
        border-bottom: 1px solid $gray-50;
      }
    }
    .disabled-search {
      .bx--search-magnifier-icon {
        fill: $gray-50;
      }
      .bx--search-input {
        cursor: not-allowed;
        border-bottom: 1px solid transparent;
      }
    }
  }

  .add-button {
    background-color: $white-0;
    border-left: 1px solid $gray-20;
  }

  .dropdown {
    width: 100%;
    height: $spacing-09;

    .bx--list-box {
      max-height: $spacing-09;
      height: $spacing-09;
      border: 0px;
    }
  }
}
