@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.register-cloud-tearsheet {
  padding-top: $spacing-08;

  .cloud-card-border {
    border: $spacing-01/2 solid black;
  }

  .cloud-card {
    background-color: white;
    height: 112px;
    width: 277px;
    cursor: pointer;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    position: relative;

    .title {
      @include carbon--type-style('productive-heading-03');

      .cds--tooltip {
        margin-left: $spacing-03;
      }

      .cloud-desc-tooltip {
        border-width: 0;
        background: transparent;
        padding: 0;
      }
    }

    .cloud-selected {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .cloud-icon {
      position: absolute;
      bottom: 0;
      width: $spacing-07;
      height: $spacing-07;
      margin-bottom: $spacing-05;
    }
  }

  .secrets-manager-step {
    .secrets-manager-step-container {
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .content div {
        margin-bottom: 1rem;
      }

      .header {
        @include carbon--type-style('productive-heading-02');
      }

      .title {
        @include carbon--type-style('productive-heading-03');
      }

      .description {
        @include carbon--type-style('body-short-01');
      }

      .subdescription {
        margin-top: 0.5rem;
      }

      .secrets-image {
        margin-right: -1.5rem;
      }

      .documentation-link {
        text-decoration: none;
        display: flex;
        align-items: center;
      }

      .documentation-icon {
        margin-left: 0.5rem;
      }
    }
  }

  .cloud-selection-step {
    .c4p--tearsheet-create__step--title {
      display: none;
    }

    .c4p--tearsheet-create__step--fieldset {
      @include carbon--type-style('productive-heading-02');
      color: black;
    }

    .cloud-select-container {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .add-details-step {
    .c4p--tearsheet-create__step--fieldset {
      margin-bottom: 12px;
    }

    .c4p--tearsheet-create__step--title {
      display: none;
    }

    .add-details-container {
      .cloud-card {
        border: 1px black solid;
      }

      input {
        background-color: white;
      }

      .form-item {
        margin-bottom: 1rem;
      }

      .autodiscover-group {
        display: flex;
        margin-bottom: 1rem;

        .cds--toggle {
          margin-right: 1rem;
        }
      }

      .secretInput {
        margin-top: -3px;
        margin-left: 49px;
      }

      .apiEndpoint {
        margin-bottom: 1rem;
      }
    }
  }

  .noSecretsContent {
    width: 100%;
    margin-left: 40px;
    margin-top: 12px;
  }

  .secretLabel {
    @include type-style('body-short-02');
    color: $text-01;
  }

  .no-secret-text {
    @include type-style('body-short-01');
    color: $text-01;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .secret-link {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  .cloudSelect-skeleton {
    height: $spacing-13 + $spacing-07;
    width: 100%;
    margin-left: $spacing-05;
  }

  .inline-notification-container {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .skeleton-container {
    display: flex;
    margin-bottom: 4rem;

    .skeleton-tile {
      width: 14rem;
      height: 11.25rem;
      margin-right: 1rem;
    }
  }
}

.register-cloud-tearsheet.secrets-step {
  .c4p--action-set__action-button.bx--btn--secondary {
    border-color: #c6c6c6;
    background: #c6c6c6;
    color: #8d8d8d;
    cursor: not-allowed;
    pointer-events: none;
  }
}
