@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-application-service-container {
  .header-title {
    img {
      height: $spacing-05;
      width: $spacing-05;
      position: relative;
      top: 2px;
    }
  }

  .skeleton-container {
    padding-right: $spacing-05;

    .app-details-skeleton {
      width: 100%;
    }
  }

  .bx--data-table-content {
    margin-left: -$spacing-05;
  }

  .bx--tag {
    margin-left: 0;
  }

  .pairs {
    .port-number-spacing, .protocol-spacing {
      padding-left: $spacing-01;
    }
  }
}
