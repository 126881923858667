@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.empty-state {
  display: inline-flex;
  padding: $spacing-07 $spacing-10;
  width: 100%;
  max-width: 58rem;

  .header,
  .description {
    display: flex;
    justify-content: flex-start;
    max-width: 22rem;
  }

  .header {
    @include carbon--type-style('productive-heading-03');
  }

  .description {
    @include carbon--type-style('body-short-01');
    margin-top: 0.5rem;
  }

  .action-button {
    margin-top: $spacing-05;
  }

  .horizontal-line {
    border-top: 1px solid #dcdcdc;
    margin-top: $spacing-05;
    margin-bottom: $spacing-05;
    height: $spacing-05;
    width: 50%;
  }

  .text-container {
    margin-left: -1rem;
    margin-bottom: $spacing-05;
  }
}

.vertical-empty-state {
  min-height: 154px;
  padding-top: $spacing-07 + $spacing-01;
  padding-left: $spacing-06 + $spacing-01;
  padding-right: $spacing-06 + $spacing-01;
  padding-bottom: $spacing-09;
  .header {
    @include carbon--type-style('productive-heading-03');
    margin-bottom: $spacing-03;
    color: $text-01;
  }

  .description {
    @include carbon--type-style('body-short-01');
    margin-bottom: $spacing-05;
    color: $text-01;
  }
  .horizontal-line {
    border-top: 1px solid #dcdcdc;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 1rem;
  }
  .bx--dropdown__wrapper {
    background-color: $white-0;
    max-width: 220px;
    border: 1px solid $blue-60;

    .bx--dropdown {
      width: 100%;
      &:hover {
        background-color: #0353e9;
        button.bx--list-box__field {
          .bx--list-box__label {
            color: $white-0;
          }
          .bx--list-box__menu-icon {
            svg {
              fill: $white-0;
            }
          }
        }
      }
      button.bx--list-box__field {
        .bx--list-box__label {
          color: $blue-60;
          @include carbon--type-style('body-short-01');
        }
        .bx--list-box__menu-icon {
          svg {
            fill: $blue-60;
          }
        }
      }
    }
  }
}
.small {
  .header {
    @include carbon--type-style('body-short-02');
    // margin-bottom: $spacing-03;
    // color: $text-01;
  }

  .description {
    @include carbon--type-style('body-short-01');
    margin-bottom: $spacing-05;
    color: $text-01;
  }
}
