@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-tooltip-deploymentenv {
  display: flex;
  flex-direction: column;
  background-color: $white-0;
  min-width: $spacing-13;
  min-height: $spacing-11;

  color: $text-01;
  border-left: 3px solid $cyan-50;

  &.unmanaged {
    border-left: 3px solid $gray-60;
  }

  .header {
    font-family: 'IBM Plex Sans Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 9px;
    padding: $spacing-03;
    border-bottom: 1px solid $gray-20;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-title {
      flex: 0 1 auto;
      line-height: 1.25;
      max-width: 65%;
      margin-right: 5px;
    }

    .page-title-icon {
      display: flex;
      align-items: center;
      position: relative;
      top: 5px;
    }
  }

  .body {
    padding: $spacing-03;

    .data-field {
      display: flex;
      align-items: center;
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      margin-bottom: $spacing-02;

      .cloudtypeicon {
        margin-right: 4px;
        line-height: 1px;
      }

      .field-label {
        margin-right: $spacing-03;
      }

      .field-value {
        display: flex;

        .autoDiscoverIcon {
          padding-right: $spacing-01;
        }
      }

      &:nth-child(4) {
        .field-label {
          margin-right: $spacing-04;
        }
      }
    }
  }

  .skeleton-container {
    width: 90%;
    margin: 8px auto;

    .service-skeleton {
      height: 1rem;
      width: 100%;
      margin: 5px 0;

      &:nth-child(1) {
        width: 75%;
      }

      &:nth-child(2) {
        width: 55%;
        height: 1rem;
      }
    }
  }
}
