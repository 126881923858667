@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.policy-options-tearsheet {
  .c4p--tearsheet__header-description {
    color: $gray-70;
  }
  .policy-options-container {
    padding: 0 $spacing-07;
    .nwsegment-options-container {
      margin: $spacing-06 0 0 0;
      .nwsegment-options-title {
        @include carbon--type-style('productive-heading-03');
        color: $gray-100;
      }
      .nwsegment-options-section {
        padding: $spacing-06 0 $spacing-09 0;
        .bx--radio-button-group {
          display: block;
          .bx--radio-button-wrapper {
            padding-top: $spacing-05;
            .bx--radio-button__label {
              justify-content: start;
              .nwsegment-options-section-label {
                @include type-style('label-02');
                color: $gray-100;
              }
              .nwsegment-options-section-subLabel {
                padding-top: $spacing-02;
                @include type-style('helper-text-01');
                color: $gray-60;
              }
            }
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
    .nwsegment-options-card {
      .nwsegment-options-card-heading {
        color: $text-01;
        @include type-style('productive-heading-01');
        margin-bottom: $spacing-05;
      }
      .policy-card {
        width: 450px;
        box-shadow: 0px 1px 2px 0px #0000004d;
        background-color: $ui-background;
        .remove-icon {
          display: none;
        }
      }
    }
    .table-container {
      .table-heading {
        @include carbon--type-style('productive-heading-03');
        color: $text-01;
        margin-bottom: $spacing-06;
      }
    }
    .policy-options-tabs {
      padding-left: 1rem;
      margin-top: 16px;

      .services {
        background-color: transparent;
        margin-top: -4px;
        button {
          box-shadow: none;
          padding-left: 0px;
          padding-top: 0px;
          @include carbon--type-style('productive-heading-03');
          color: $text-01;
        }
      }

      span.bx--radio-button__appearance {
        height: 16px;
        width: 16px;
      }

      .bx--tabs--scrollable__nav {
        overflow: visible;
        .tab.disabled {
          .bx--tabs--scrollable__nav-link {
            overflow: visible;

            .bx--tooltip--definition {
              display: inline-block;
              .bx--tooltip__trigger {
                text-align: left;
                border-bottom: none;
                outline: none;

                .tab-name-container {
                  @include carbon--type-style('label-02');
                  color: $ibm-color__gray-50;
                  pointer-events: all;
                  cursor: not-allowed;
                }
              }
              .bx--assistive-text {
                bottom: -$spacing-baseline;
              }
            }

            .bx--tooltip--definition,
            .bx--tooltip__trigger,
            .tab-name-container {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
