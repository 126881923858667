@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-application-details-container {
  .header-title {
    display: flex;

    .page-title-icon {
      margin-left: $spacing-03;
      margin-top: $spacing-03;
    }
  }

  .skeleton-container {
    padding-right: $spacing-05;

    .app-details-skeleton {
      width: 100%;
    }
  }

  .bx--tag {
    margin-left: 0;
  }

  .pairs {
    .port-number-spacing,
    .protocol-spacing {
      padding-left: $spacing-01;
    }
  }

  .bx--data-table th,
  .bx--data-table td {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
  }

  .bx--data-table {
    tbody {
      tr {
        height: $spacing-07;

        &:hover {
          background: transparent;

          td {
            background: transparent;
            border: transparent;
          }
        }
      }
    }
  }
}
