@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.service-form {
  padding: 1rem;

  .c4p--tearsheet__header-description {
    max-width: 100%;
  }

  .bx--form-item,
  .bx--list-box__wrapper {
    margin-bottom: 1rem;
  }

  .bx--form-item {
    margin-right: 0;
  }

  .bx--list-box__selection {
    display: none;
  }

  .port-form {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    .button {
      padding-top: $spacing-06;
      padding-right: $spacing-05;

      button {
        float: right;
      }
    }

    .protocol {
      .bx--text-input {
        background-color: $gray-10;
      }
    }

    label[for='serviceProtocol'] {
      margin-bottom: 5px;
    }
  }

  .port-table-container {
    padding: $spacing-05;
    padding-top: 0;

    .bx--data-table-content {
      width: 100%;

      .bx--data-table {
        table-layout: fixed;
      }

      tr:last-child td:last-child {
        width: $spacing-09;
      }
    }

    .remove-button-container {
      button {
        width: $spacing-09;
        height: $spacing-09;
        padding: $spacing-03;
        color: $text-primary;
      }
    }
  }

  .service-form-add-labels {
    width: 100%;

    .input-container {
      display: flex;
      gap: $spacing-06;

      .input {
        flex: 1;
      }

      .button {
        padding-top: $spacing-06;
      }
    }

    .values-container {
      .bx--tag {
        margin-left: 0;
      }
    }
  }
}
