@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.roles-list-table {
  td:nth-child(1),
  td:nth-child(4),
  th:nth-child(4) {
    white-space: nowrap;
  }

  .resource-name {
    text-align: initial;
  }

  .bx--tooltip--definition.bx--tooltip--a11y .bx--assistive-text {
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    td:nth-child(1),
    td:nth-child(4),
    th:nth-child(4) {
      white-space: normal;
    }
  }
  .no-underline-link {
    text-decoration: none;
  }
  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline;
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }
  .circle-icon {
    border: 1px solid gray;
    height: $spacing-04;
    width: $spacing-04;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    left: $spacing-04;
    top: $spacing-01;
    cursor: pointer;
  }
  .icon {
    margin-left: $spacing-02;
    width: $spacing-04;
    height: $spacing-04;
    display: inline-block;

    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
      position: absolute;
      margin-bottom: $spacing-03;
    }

    .icon-with-tooltip {
      margin-top: - $spacing-04;
      margin-left: $spacing-02;
      .bx--btn--ghost:hover {
        background-color: transparent;
      }
    }
  }
}
