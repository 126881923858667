@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

//Network segment details page
.network-segment-details {
  background-color: $gray-10;
  .network-segment-details-container {
    background-color: $ui-01;
    padding: 1rem;
  }

  .details-tile {
    margin-bottom: 1rem;
    .default {
      margin-bottom: 2rem;
    }

    .bx--inline-loading {
      min-height: unset;
    }
  }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
  }
  .details-tile-skeleton {
    width: 100%;
    min-height: 20rem;
  }
  .edit-link.bx--btn--disabled {
    color: #8d8d8d;
    border: none;
  }
  .bx--child-row-inner-container {
    width: 74.25%;
    float: right;
  }

  // Styles related to managed toggle
  .header-wrapper {
    padding-right: 0;
  }

  .delete-network-segment {
    margin-left: $spacing-05;
    margin-top: $spacing-05;
    padding-bottom: 4rem;
  }
  .delete-location-modal {
    .bx--modal-container {
      height: 13.25rem;
    }
  }

  .details-tile .data-container .value .bx--tag {
    margin-left: 0;
    margin-right: $spacing-04;
  }

  .health-status-component {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .label-tags {
    .bx--tooltip__label button span {
      max-height: 18px;
    }
  }

  .header-icon-contaniner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon-text {
      @include type.type-style('body-01');
      padding-left: 4px;
    }

    &.unmanaged {
      .icon-text {
        color: $text-helper;
      }
    }
  }
}
