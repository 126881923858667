@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-tooltip-gateway {
  background-color: $white-0;
  min-width: $spacing-13;
  min-height: $spacing-11;
  color: $text-01;
  border-left: 3px solid $blue-80;

  .header {
    font-family: 'IBM Plex Sans Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 9px;
    padding: $spacing-03;
    border-bottom: 1px solid $gray-20;
    display: flex;
    justify-content: space-between;

    .version {
      color: $text-secondary;
      font-weight: 400;
    }
  }

  .body {
    padding: $spacing-03;
    display: flex;
    flex-direction: column;

    .gw-type-info {
      display: flex;
      justify-content: flex-start;
      gap: $spacing-02;

      .gw-type-title {
        font-family: 'IBM Plex Sans Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 16px;
        letter-spacing: 0.32px;
        padding-bottom: $spacing-02;
      }
    }

    .health-status-component,
    .procedural-status-component,
    .bx--inline-loading {
      display: flex;
      justify-content: flex-start;
      gap: $spacing-02;
      align-items: center;

      .icon,
      .bx--inline-loading__animation {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
        width: $spacing-05;

        img {
          width: 12px;
          height: 12px;
        }

        .bx--loading--small {
          width: 0.75rem;
        }
      }

      .label,
      .bx--inline-loading__text {
        font-family: 'IBM Plex Sans Condensed';
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 16px;
        letter-spacing: 0.32px;
        padding-bottom: $spacing-02;
        padding-top: $spacing-02;
      }
    }

    .gw-procedural-status {
      display: flex;
      align-items: center;
      gap: $spacing-03;

      .gw-procedural-status-icon {
        padding-top: $spacing-01;

        img {
          height: 12px;
          width: 12px;
        }

        + .procedural-status-component .bx--inline-loading__animation,
        + .procedural-status-component .icon {
          display: none;
        }
      }
    }
  }

  .skeleton-container {
    width: 90%;
    margin: $spacing-03 auto;

    .service-skeleton {
      height: 1rem;
      width: 100%;
      margin: $spacing-02 0;

      &:nth-child(1) {
        width: 75%;
      }

      &:nth-child(2) {
        width: 55%;
        height: 1rem;
      }
    }
  }
}
