@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-location-details-container {
  .skeleton-container {
    padding-right: $spacing-05;

    .app-details-skeleton {
      width: 100%;
    }
  }

  .bx--data-table-content {
    margin-left: -$spacing-05;
  }

  .bx--tag {
    margin-left: 0;
  }

  .bx--data-table th,
  .bx--data-table td {
    border-bottom: 0;
  }

  .bx--data-table {
    tbody {
      tr {
        height: $spacing-07;

        &:hover {
          background: transparent;

          td {
            background: transparent;
            border: transparent;
          }
        }
      }
    }
  }
}