@import 'carbon-components/scss/globals/scss/typography.scss';

.policy-card {
  background-color: $gray-10;

  .policy-card-label-section {
    display: flex;
    height: $spacing-09;
    align-items: center;
    justify-content: space-between;

    margin-top: $spacing-03;
    .label {
      display: flex;
      align-items: center;
      width: 100%;
      padding: $spacing-04 + $spacing-01 $spacing-05;
      .icon {
        display: flex;
        line-height: 1.25;
      }

      .text {
        padding-left: $spacing-03;
        font-family: IBM Plex Sans;
        @include carbon--type-style('heading-01');
        color: $gray-70;
      }
    }
  }

  .remove-icon {
    color: $text-01;
    padding: 15px;
    .bx--btn__icon {
      margin-left: 0;
    }
  }
}
.policy-card:first-child .policy-card-label-section {
  margin-top: 0;
}

.sycSvc-card {
  padding: 0 $spacing-05 $spacing-05;

  .bx--inline-notification {
    margin-top: 0;
    .bx--inline-notification__close-button {
      display: none;
    }

    .bx--inline-notification__title {
      @include carbon--type-style('productive-heading-01');
      color: var(--Text-text-primary, $gray-100);
      width: 100%;
    }

    .bx--inline-notification__subtitle {
      width: 252px;
      @include carbon--type-style('body-short-01');
      color: var(--Text-text-primary, $gray-100);
    }
  }
  .network-segment-card {
    margin: 0;
    .remove-icon {
      display: none;
    }
  }
}

.network-segment-card {
  max-height: 76px;
  .subtext {
    @include carbon--type-style('label-01');
    color: $text-02;
    padding: 0 $spacing-05 $spacing-04;
  }
}

.policy-options-tearsheet {
  .policy-card {
    background: $white-0;
  }
}

.policy-card.allowDropShadow {
  box-shadow: 0px 1px $spacing-01 0px #0000004d;
}
