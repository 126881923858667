@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.inline-notification-container {
  .notification {
    width: 100%;
    min-width: 100%;
    color: $text-01;

    .bx--inline-notification__close-button
      .bx--inline-notification__close-icon {
      fill: $text-01;
    }

    .bx--inline-notification__title {
      @include type-style('productive-heading-01');
    }

    .bx--inline-notification__subtitle {
      @include type-style('body-short-01');
    }

    &.error {
      border-top: 1px solid $red-60;
      border-bottom: 1px solid $red-60;
      border-right: 1px solid $red-60;
      background: $red-10;
    }

    &.success {
      background: $green-10;
      border-top: 1px solid $green-60;
      border-bottom: 1px solid $green-60;
      border-right: 1px solid $green-60;
    }
  }

  @media (min-width: 66rem) {
    .bx--inline-notification__text-wrapper {
      p.bx--inline-notification__title {
        padding-right: 0;
      }
    }
  }
}
