@import 'carbon-components/scss/globals/scss/typography.scss';

.create-role-permission-resource-type-table {
  .bx--data-table-container {
    overflow-x: inherit;
    padding-top: 0;
  }

  .bx--data-table-content {
    overflow-x: inherit;
    overflow-y: inherit;
  }

  .bx--data-table thead {
    position: sticky;
    top: -1.5rem;
    z-index: 1;
  }

  .role-search {
    .bx--search-input {
      border-bottom: 0;
    }
  }

  .bx--data-table td.bx--table-expand {
    padding: 0;

    button {
      padding: 0;
    }
  }

  .role-permission-type-expand-cell {
    @include type-style('productive-heading-01');

    &:nth-child(2) {
      width: 13rem;
      min-width: 13rem;
    }
  }

  .bx--data-table th {
    width: 7rem;
    min-width: 7rem;
    padding: 0;

    .bx--table-header-label {
      display: flex;
      justify-content: center;
    }

    &:nth-child(2) {
      width: 13rem;
      min-width: 13rem;
      padding: 0;
    }
  }

  tr.bx--parent-row.bx--expandable-row+tr[data-child-row] td {
    padding: 0;
  }

  .row-expanded-type-table td {
    border-top: 0;
  }

  .row-resource-type {
    width: 15rem;
    min-width: 15rem;
    padding-left: 3rem !important;
  }

  .resource-type-check-box {
    width: 7rem;
    min-width: 7rem;

    .bx--tooltip--definition {
      display: flex;
      justify-content: center;
    }

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: 0;
    }
  }

  .resource-type-check-box div {
    display: flex;
    align-items: center;
  }

  .resource-type-check-box:last-child {
    .bx--assistive-text.bx--assistive-text {
      left: 0;
    }
  }

  .bx--data-table tbody tr:hover,
  .bx--data-table tbody tr:hover td,
  tr.bx--parent-row.bx--expandable-row:hover+tr[data-child-row] td {
    background-color: #fff;
    background: #fff;
  }
}