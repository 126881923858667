@import 'carbon-components/scss/globals/scss/spacing.scss';
@import '@carbon/themes/scss/themes';

@import 'carbon-components/scss/globals/scss/typography.scss';

.deploy-gateway-namespace {
  max-width: 800px;

  #network-seg-compactibility {
    background-color: transparent;
  }

  #select-network-segment-register-namespace {
    .cds--list-box__menu {
      .cds--list-box__menu-item {
        &:last-child {
          .cds--list-box__menu-item__option {
            display: flex;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;

            .create-network-segment-button {
              color: $blue-60;
              display: flex;
              align-items: center;
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .optional-details {
    margin-top: $spacing-09;

    .form-heading {
      padding-left: 1rem;
      @include carbon--type-style('productive-heading-03');
      margin-bottom: 0;
    }

    .gateway-labels-row {
      margin-top: $spacing-06;
    }

    .gateway-description-row {
      margin-top: $spacing-07;
    }
  }

  .gateway-section {
    .connected-gateway {
      display: flex;
      align-items: center;
      height: 2.5rem;
      border-bottom: 1px solid #e0e0e0;

      .text {
        display: flex;
        align-items: center;
        @include carbon--type-style('body-short-01');
        margin: 0 $spacing-05;
      }

      .disconnected-gw {
        width: 100%;
      }
    }
  }

  .details-subtitle {
    padding-top: 16px;
    @include type-style('body-short-01');
    color: $text-02;
    margin-bottom: 16px;

    .bold-text {
      font-weight: 600;
    }
  }

  div#select-network-segment-register-namespace {
    margin-top: -8px;
  }

  #connected-gateway-infra-resource {
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
  }

  .gateway-infra-row{
    margin-bottom: 22px;
  }
}