@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.network-segment-resources-list {
  .filterNetworkSegmentTitle {
    display: flex;
    margin-top: 17px;
  }
  .resources-list-header {
    @include carbon--type-style('productive-heading-03');
    color: $text-01;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
  .network-segment-resources-list-tabs {
    padding-left: 1rem;
  }
  .bx--tab-content {
    padding: 0 1rem;
  }
  .no-underline-link {
    color: $gray-100;
  }
  .gateway-name-link {
    .gateway-name-container {
      display: inline-flex;
      .icon {
        margin-right: 0.25rem;
      }
      .name {
        color: $gray-100;
      }
    }
    &:hover {
      .name {
        color: $blue-60;
      }
    }
  }
  .location-name-link,
  .cloud-name-link,
  .application-name-link,
  .namespace-name-link,
  .deployment-env-name-link,
  .policy-name-link {
    &:hover {
      color: $blue-60;
    }
  }

  .connect-gateway-cluster-btn {
    min-width: 120px;
    min-height: unset;
    padding: $spacing-03;
  }

  // To make sure that entire table row is not showing hover color when we hover connect gateway button
  .bx--data-table-content {
    tr {
      &:hover {
        background: none;

        td {
          background: none;
        }
      }

      &:hover:not(:has(.connect-gateway-cluster-btn:hover)) {
        td {
          background: #e5e5e5;
        }
      }

      &:has(.unmanaged) {
        td,
        .name,
        .label,
        .bx--inline-loading__text,
        .location-name-link {
          color: $text-helper;
        }
      }
    }
  }
}
