@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.subscription-usage-container {
  .page-content {
    background-color: $white-0;
    margin: 1rem;
    padding: $spacing-06;

    .page-content-header {
      @include carbon--type-style('heading-02');
      color: $text-02;
      margin-bottom: $spacing-07;
      display: flex;

      .header-text {
        margin-right: 5px;
      }

      .info-icon {
        svg {
          color: $gray-70;
        }
      }
    }
    .page-content-sub-header {
      @include carbon--type-style('label-01');
      color: $text-02;
    }
    .current-month-usage {
      @include carbon--type-style('productive-heading-06');
      color: $purple-70;
      margin-bottom: $spacing-07;
    }
    .cds--cc--tooltip {
      .datapoint-tooltip {
        .tooltip-color {
          &.current-month {
            background-color: $purple-70;
          }
          &.prev-month {
            background-color: $cyan-50;
          }
        }
      }
    }
  }
}
