@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-tooltip-location {
  background-color: $white-0;
  min-width: $spacing-13;
  min-height: $spacing-11;
  position: relative;

  color: $text-01;
  border-left: 3px solid #6929c4;

  .header {
    font-family: 'IBM Plex Sans Condensed';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 9px;
    padding: $spacing-03;
    border-bottom: 1px solid $gray-20;
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-title {
      flex: 0 1 auto;
      line-height: 1.25;
      max-width: 65%;
      margin-right: 5px;
    }

    .page-title-icon {
      display: flex;
      align-items: center;
      position: relative;
      top: 5px;
    }
  }

  .cloud-info {
    display: flex;
    justify-content: flex-start;

    .page-title-icon {
      position: absolute;
      left: 0;
      margin-top: $spacing-02;
    }

    .cloud-title {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      padding-left: 20px;
      padding-bottom: $spacing-02;
    }
  }

  .body {
    padding: $spacing-03;

    .label {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      padding-bottom: $spacing-02;
    }
  }

  .skeleton-container {
    width: 90%;
    margin: 0 auto;

    .service-skeleton {
      height: 1.5rem;
      width: 100%;
      margin: 10px 0;

      &:first-child {
        margin-bottom: 18px;
      }

      &:nth-child(2) {
        width: 65%;
        height: 1rem;
      }
    }
  }
}
