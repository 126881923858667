@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
.secret-table-container {
  margin: 1rem;

  .skelton-header {
    min-width: 100%;
    background-color: #ffffff;
    min-height: 3rem;
  }
  .table-header {
    background-color: #c6c6c6;
  }

  .details-exp {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: -7px;

    .details-header {
      font-weight: 600;
      color: #161616;
    }

    .details-body {
      padding-top: 1.3rem;

      .details-table {
        display: flex;
        flex-direction: row;

        .first-row {
          margin-top: 0.3rem;
        }
        .first-row-key {
          margin-top: 0.5rem;
        }
        .second-row-secret {
          margin-top: 0.1rem;
        }
        .second-row {
          margin-top: 0.3rem;
        }
        .third-row {
          padding-top: 0.3rem;
        }
      }

      .second-column {
        padding-left: 3rem;
      }
    }
  }
  .delete-secret-modal {
    .bx--modal-container {
      width: 35rem;
      height: 13.25rem;
    }
  }
  .secret-actions {
    padding-right: 0px;
    .bx--overflow-menu {
      float: right;
      height: 44px;
      width: 44px;
    }
  }

  .overflow-menu.secret-actions {
    &.hide {
      display: none;
    }
  }

  .vertical-empty-state {
    min-height: auto;
    margin-bottom: 0px;
    padding: 2rem;
    .description {
      @include type-style('body-long-01');
      color: $text-02;
    }
  }
}

.bx--overflow-menu-options {
  .bx--tooltip--definition {
    width: 100%;

    .bx--tooltip__trigger {
      width: 100%;
      cursor: not-allowed;
      z-index: 1;
      border-bottom: none;

      &:focus {
        outline: 2px solid $blue-60;
      }

      .bx--overflow-menu-options__btn {
        &.disabled {
          pointer-events: none;
        }
      }
      &::before {
        left: 5px !important;
      }
    }

    .bx--assistive-text {
      transform: translate(-105%, -100%) !important;
    }
  }
}
