@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.role-details-page {
  .role-identities-table-container {
    margin-top: 1rem;
    background: $white-0;
    .role-identities-header {
      min-height: 4rem;
      padding: 16px 48px 16px 16px;
      @include type-style('productive-heading-03');
    }

    .bx--data-table-container {
      .bx--tooltip--definition {
        padding: 1rem;

        .bx--assistive-text {
          bottom: 7px !important;
          transform: translate(-37%, 100%) !important;
        }
      }

      .bx--data-table {
        tbody {
          td {
            .no-underline-link {
              color: $gray-70;

              &:hover {
                color: $blue-70;
                text-decoration: underline !important;
              }
            }
          }
        }
      }
      .add-identity-button {
        &:hover {
          background: $white-0;
          .text {
            color: $blue-60;
          }
          svg {
            fill: $blue-60;
          }
        }

        &.disabled {
          background: none;

          .text {
            color: $gray-30;
          }
          svg {
            color: $gray-30;
          }

          border: none;
          outline: none;
          text-decoration: none;
        }
      }

      .emptystate-table-header {
        min-height: 3rem;
        margin-left: 0;
        margin-right: 0;
        background-color: $gray-20;
        border: none;
        align-items: center;

        div {
          @include type-style('productive-heading-01');
        }
      }

      .bx--search {
        &:hover,
        &:focus {
          background: $white-0;
        }
        .bx--search-input {
          border-bottom: none;
        }
      }

      .bx--toolbar-search-container-active {
        .bx--search-input {
          background: $white-0;
        }
      }

      .vertical-empty-state {
        margin-bottom: 1rem;
      }
    }
  }
}
