@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.cloud-namespace-table {
  cursor: default;

  .namespace-table-session {
    padding-bottom: $spacing-05;
    margin-top: $spacing-07;
    border-top: 1px solid $gray-30;

    .table-button-group {
      visibility: hidden;
      min-width: 68px;

      button {
        min-height: 32px;
        padding: $spacing-03;
        color: $blue-60;

        &.bx--btn--ghost.bx--btn--icon-only svg path {
          fill: $blue-60;
        }

        &.disabled {
          color: $gray-100;
          opacity: 25%;
        }
      }
    }

    tbody {
      tr {
        background: $gray-10;

        td {
          background: transparent;
          color: $text-primary;

          &:last-child {
            position: sticky;
            right: 0;
          }
        }

        .name {
          display: flex;
          align-items: center;
          color: $text-primary;

          .icon {
            margin-top: $spacing-02;
            margin-right: $spacing-02;
          }
        }

        .gateway {
          display: flex;
          align-items: center;

          .icon {
            background: none;
            height: 16px;
            padding-right: $spacing-01;
          }
        }

        &:hover {
          background: $gray-10-hover;

          .table-button-group {
            background: $gray-10-hover;
            visibility: visible;

            .bx--btn--ghost:hover {
              background-color: #D6D6D6;
            }
          }
        }

        .unmanaged {
          color: $text-helper;
        }
      }
    }

    .bx--pagination {
      background: $gray-20;

      .bx--select-input {
        background: $gray-20;
      }
    }

    .bx--search-input {
      border: none;
    }

    .namespace-table-container {
      .vertical-empty-state, .error403-state {
        width: 100%;
        padding: $spacing-08;
        background: $gray-10;
        min-height: unset;

        .header,
        .description {
          background: none;
          border: none;
          padding: 0;
          color: $text-01;
          height: unset;
        }

        .header {
          @include type.type-style('body-short-02');
        }

        .description {
          @include type.type-style('caption-01');
        }

        .empty-state-icon {
          img {
            height: 64px;
            width: 64px;
          }
        }
      }
    }

    .bx--data-table-content {
      overflow-y: hidden;
    }
  }
}
