@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.select-direction-and-link-metric {
  padding: $spacing-06 $spacing-07;
  .select-direction-and-gateway-section {
    .select-direction-and-gateway-section-title {
      @include carbon--type-style('productive-heading-03');
      padding-bottom: 24px;
    }
    .selection-header {
      max-width: 800px;
      max-height: 80px;
      display: flex;
      flex-direction: row;

      .starting-gateway {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding: 18px;
        min-width: 298px;
        min-height: 80px;
        background-color: $white-0;
        margin-right: 2px;
        .selected-gateway-name {
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          @include carbon--type-style('body-long-01');
          color: $gray-100;
        }
        .gateway-img {
          padding-right: 5px;
          margin-right: -100px;
          margin-top: -7px;
        }
      }

      .creation-direction {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding: 18px;
        min-width: 200px;
        min-height: 80px;
        background-color: $white-0;
        margin-right: 2px;
        .direction-dropdown-title {
          padding-left: 6px;
        }
        .direction-dropdown {
          outline: none;
          border: none;
          margin-left: -10px;
          min-width: fit-content;
          .dir-dropdown {
            display: flex;
            flex-direction: row;
            .dropdown-text {
              padding-top: 4px;
              margin-top: -5px;
              padding-left: 5px;
            }
          }
        }
        .direction-dropdown .bx--list-box__field:focus {
          outline: none;
        }
      }

      .remote-gateway {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding: 18px;
        min-width: 298px;
        min-height: 80px;
        background-color: $white-0;
        margin-right: 2px;
        .select-gateway-button {
          padding: 7px;
          min-height: 0px;
          margin-top: 3px;
          margin-left: -8px;
        }
        .select-gateway-value {
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          @include carbon--type-style('body-long-01');
          color: $gray-100;
          .gw-edit {
            margin-top: -7px;
            padding: 0px;
            min-height: 32px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .gateway-img {
          padding-right: 5px;
          margin-right: -100px;
          margin-top: -7px;
        }
      }
    }
  }
  .cost-field-title {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    .cost-field-header {
      @include carbon--type-style('label-01');
      color: $gray-70;
    }
    .cost-field-header-icon {
      padding-left: 0.5rem;
    }
  }
  .cost-field {
    width: 298px;
  }
}

.select-gateway-tearsheet {
  .curr-gateway-info {
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 24px;
    min-height: 70px;
    margin-bottom: 16px;
    background-color: $white-0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    min-width: 500px;

    .gateway-info {
      min-width: 95px;
      min-height: 38px;
      padding-right: 80px;
      max-width: 30%;
      .title {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding-bottom: 5px;
      }
      .select-value {
        display: flex;
        flex-direction: row;
        .curr-gateway-icon {
          margin-top: 2px;
          padding-right: 2px;
        }
      }
    }

    .network-segment-info {
      min-width: 101px;
      min-height: 38px;
      padding-right: 80px;
      max-width: 30%;
      .title {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding-bottom: 5px;
      }
      .select-value {
        display: flex;
        flex-direction: row;
      }
    }

    .deployment-env-info {
      min-width: 144px;
      min-height: 38px;
      padding-right: 80px;
      .title {
        @include carbon--type-style('label-01');
        color: $gray-70;
        padding-bottom: 5px;
      }
      .select-value {
        display: flex;
        flex-direction: row;
      }
    }
  }
  .gateway-table {
    padding-left: 32px;
    padding-right: 32px; 
    .health-status-component {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .icon {
        display: flex;
        align-items: center;
      }
      .label {
        color: $gray-90;
      }
    }
  }
  .gateway-table  tr{
    &:hover{
      cursor: pointer;
    }
  }
  .gateway-name {
    display: flex;
    flex-direction: row;
    .gateway-icon {
      margin-top: 5px;
      padding-right: 5px;
    }
    .table__gateway__name {
      margin-top: 4px;
    }
  }
}
