@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.middle-ellipsis {
  word-break: keep-all;
  overflow-wrap: normal;

  span[data-text] + .bx--tooltip--definition {
    display: flex;
    margin-top: -($spacing-05 + $spacing-02 + $spacing-01);
  }
}
