@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import '@carbon/themes/scss/themes';

.selected-from-to-card {
  // default styling: active (is selected) and disabled
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 20rem;
  background-color: $ui-background;
  padding: 1rem;
  position: relative;

  .card-header {
    .heading {
      @include type-style('productive-heading-02');
      color: $text-helper;
      margin-bottom: $spacing-04;
      color: $text-01;
    }
  }

  .divider {
    border-top: 1px solid $ui-03;
    margin: 0;
    margin-bottom: $spacing-05;
    margin-left: -$spacing-05;
    margin-right: -$spacing-05;
  }

  .add-resources-button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
}

.selected-from-to-card.active {
  border-top: 2px solid $blue-60;
  box-shadow: 0px $spacing-01 6px 0px #00000033;
}

.selected-from-to-card.disabled {
  .card-header .heading {
    color: $disabled-03;
  }

  &:after {
    background-color: $disabled-03 !important;
  }
}

.selected-from-to-card.active.disabled {
  border-top: 2px solid $disabled-03;
}

.selected-from-to-card.from {
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    right: -10px;
    width: 10px;
    height: 20px;
    border-radius: 0 100px 100px 0;
    background-color: $blue-60;
  }
}

.selected-from-to-card.to {
  &:before {
    border-top-left-radius: 110px;
    border-bottom-left-radius: 110px;
    border: 1px solid $disabled-03;
    background-color: $ui-01;
    border-right: 0;
    position: absolute;
    content: '';
    top: 50%;
    left: -10px;
    width: 10px;
    height: 20px;
  }
}
