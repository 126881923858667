@import 'carbon-components/scss/globals/scss/typography.scss';
.topology-legends-main-section {
  .nodes-headers {
    @include carbon--type-style('productive-heading-01');
    padding-top: 10px;
    padding-bottom: 18px;
  }
  .node-section-row {
    padding-bottom: 18px;
  }
  .nodes-location {
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nodes-section-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 204px;
    height: 40px;

    .nodes-sub-section-label {
      padding-left: 10px;

      @include carbon--type-style('body-long-01');
    }
  }
  .helper-column {
    display: flex;
    flex-direction: column;
  }
  .helper-text {
    padding-left: 10px;
    @include carbon--type-style('helper-text-01');
    color: $gray-70;
  }
}
