@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.create-network-segment,
.edit-network-segment {
  .bx--modal-container {
    height: 80%;
    width: 50%;
  }
  .bx--modal-header {
    margin-bottom: 0;
  }
  .bx--modal-content {
    padding-top: 0;
  }
  .bx--modal-close {
    display: block;
  }

  .bx--modal-content {
    margin-bottom: $spacing-05;
  }

  .network-segment-form {
    .modal-header {
      max-width: 90%;
    }

    .bx--row {
      padding-top: $spacing-05;
      padding-bottom: $spacing-05;
    }

    .gateway-set-label {
      @include carbon--type-style('label-01');
      color: $gray-70;
      display: flex;

      .info-tooltip-text {
        padding-left: $spacing-03;
        color: $gray-70;
        background-color: $background;
        border: none;
      }

      .cds--tooltip-content {
        margin-left: $spacing-09;
      }
    }
  }
}