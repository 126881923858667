@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.add-application-deployment-details-container {
  .sub-header {
    margin: $spacing-06 0 0 $spacing-07;
    @include carbon--type-style('productive-heading-02');
  }

  .add-application-deployment-details-content {
    display: flex;
    flex-direction: row;
    padding: $spacing-06 $spacing-07 0;

    &.selected {
      padding: $spacing-06 0 0 $spacing-07;
    }

    .application-deployment-table {
      flex: 0 0 100%;

      &.selected {
        flex: 0 0 73%;
        margin-right: $spacing-05;
      }

      .bx--data-table-container {
        .bx--data-table-content {
          .bx--data-table {
            .bx--radio-button-wrapper {
              .bx--radio-button__appearance {
                width: $spacing-05;
                height: $spacing-05;
                margin-right: 0;
              }
            }

            .generic-truncate-string {
              .bx--tooltip__trigger {
                cursor: pointer;
              }
            }

            .auto-discover-field {
              display: flex;
              flex-direction: column;

              .auto-discover-label {
                align-items: center;

                .label-icon {
                  height: $spacing-05;
                }
              }
            }

            .name-column-value {
              align-items: center;

              .no-underline-link {
                position: absolute;
              }
            }

            tbody {
              tr {
                td {
                  max-width: $spacing-12 + $spacing-07;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
}