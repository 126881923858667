@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.cloud-location-deployment-env {
  padding: $spacing-03 $spacing-05;
  border-bottom: 1px solid $gray-20;

  .title-session {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-group {
      display: flex;
      align-items: center;

      .icon {
        margin-top: $spacing-02;
      }

      .title {
        @include type.type-style('heading-compact-02');
        margin-left: $spacing-03;
      }

      .unmanaged-label {
        @include type.type-style('body-short-01');

        margin-left: $spacing-03;
        padding-left: $spacing-03;

        border-left: 1px solid $gray-30;
      }

      .subtype {
        @include type.type-style('body-short-01');

        margin-left: $spacing-03;
        padding-left: $spacing-03;

        border-left: 1px solid $gray-30;
      }
    }

    .button-group {
      visibility: hidden;
      min-width: 68px;

      button {
        min-height: 32px;
        padding: $spacing-03;
        color: $blue-60;

        &.bx--btn--ghost.bx--btn--icon-only svg path {
          fill: $blue-60;
        }

        &.disabled {
          color: $gray-100;
          opacity: 25%;
        }

        &.bx--btn--ghost:hover {
          background-color: #d6d6d6;
        }
      }

      .manage-location-tooltip {
        max-height: 36px;
      }
    }
  }

  .details-session {
    padding: $spacing-05 $spacing-05;
    padding-bottom: 0;

    .label {
      @include type.type-style('label-01');
      color: $text-02;
      padding-bottom: $spacing-01;
    }

    .value {
      @include type.type-style('body-short-01');
      color: $text-primary;
    }
  }

  .no-table-session {
    padding-bottom: $spacing-07;
  }

  &.managed {
    color: $text-primary;
    padding-bottom: 0;
  }

  &.unmanaged {
    color: $text-helper;

    &.selected {
      border-left: none;
      padding-left: $spacing-05;
    }
  }

  &:hover:not(:has(.namespace-table-container:hover)) {
    background: $gray-10-hover;

    .button-group {
      visibility: visible;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .action-row {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
    height: 32px;

    .label {
      @include type.type-style('helper-text-01');
    }

    .chevron-icon {
      padding-left: $spacing-02;
    }
  }
}
