@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.header-component {
  .header-wrapper {
    padding-left: 2rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    background-color: $ui-background;

    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    .action-btn-disabled {
      border-color: #c6c6c6;
      background: #c6c6c6;
      box-shadow: none;
      color: #8d8d8d;
      cursor: not-allowed;
    }
    .action-buttons {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition {
        border-bottom: none;
      }
      .bx--tooltip--definition .bx--tooltip__trigger {
        border-bottom: none;
      }
    }
    .action-button,
    .bx--dropdown__wrapper {
      margin-right: 0.5rem;
    }
    .bx--dropdown__wrapper {
      background-color: $blue-60;

      .bx--dropdown {
        &:hover {
          background-color: #0353e9;
        }
        button.bx--list-box__field {
          .bx--list-box__label {
            color: $white-0;
            @include carbon--type-style('body-short-01');
          }
          .bx--list-box__menu-icon {
            svg {
              fill: $white-0;
            }
          }
        }
      }
    }

    .bx--toggle__switch {
      min-width: 6rem !important;
    }
  }

  .notification-wrapper {
    .notification-container {
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .cds--inline-notification {
        min-width: 100%;
      }

      .cds--actionable-notification {
        min-width: 100%;
        .cds--actionable-notification__action-button.cds--btn--ghost {
          margin-top: 1.6rem;
        }
      } 
    }
  }
}
