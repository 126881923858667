@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.application-deployment-services-card {
  background: #ffffff;
  margin: $spacing-05;

  .header {
    @include carbon--type-style('productive-heading-03');
    padding: $spacing-05;
  }

  .btn-action {
    margin-top: $spacing-06;
  }

  .table-container {
    margin-top: $spacing-05;
    .delete-link {
      color: black;
      cursor: pointer;
    }
    &:hover {
      background-color: $background-hover;
      .delete-link {
        color: black;
        cursor: pointer;
      }
    }
  }

  .find-and-filter-container {
    display: none;
  }

  .add-endpoint-section {
    margin: 0;
    .hostname-input-label-container {
      margin-bottom: $spacing-03;

      .learn-more-link {
        @include carbon--type-style('label-01');
        margin-left: $spacing-03;
        text-decoration: none;
      }
    }
  }

  .no-services-snackbar-row {
    margin: 0;

    .bx--inline-notification {
      background-color: $notification-info-background-color;
      border: 1px solid rgba(0, 67, 206, 0.3);
      border-left: 3px solid $support-info;

      svg {
        fill: $support-info;
      }
    }
  }
}
