@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.title-container {
  width: 80%;

  .page-title-main {
    display: flex;

    .page-title-only {
      margin-right: 0.7rem;
      @include carbon--type-style('productive-heading-04');
    }

    .page-title-text {
      @include carbon--type-style('productive-heading-04');
    }

    .page-title-icon {
      margin-left: 0.5rem;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
    }

    &.gw-health-status {
      flex-direction: column;
    }
  }

  .skeleton-header {
    width: 30% !important;
    height: 2.25rem;
    margin-bottom: unset;
    margin-top: 0.5rem;
  }

  .subtitle-skeleton {
    margin-top: 0.5rem;
    height: 1.5rem;
  }
}

.sub-heading-text {
  @include carbon--type-style('body-long-02');
  padding-top: $spacing-03;
  display: flex;
  vertical-align: middle;

  .subtitle-icon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: $spacing-03;
  }

  .learnMoreLink {
    text-decoration: none;
    margin-left: $spacing-03;
  }
}