@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

$carbon--theme: $carbon--theme--g10;
@include carbon--theme();

.overview-tile-skeleton {
  width: 100%;

  .header-skeleton {
    max-width: 20%;
    height: 1.5rem;
    margin-bottom: 1rem;
  }

  .stats-row-skeleton {
    display: flex;
    padding: 1rem 0;
    margin-bottom: 1rem;

    .stat {
      width: 33%;
    }

    .subheader-text {
      max-width: 30%;
      height: 1rem;
    }
    .delete-network-segment {
      min-width: 12%;
      height: 3.5rem;
      margin-top: 1rem;
    }
    .data-placeholder {
      height: 3.5rem;
      min-width: 50%;
    }
  }

  .table-header-skeleton {
    height: 1.5rem;
    max-width: 15%;
  }

  .table-skeleton {
    min-height: 8rem;
    width: 100%;
  }
}

.overview-tile {
  width: 100%;
  padding-bottom: 0;
  position: relative;

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-text {
      @include carbon--type-style('productive-heading-02');
    }
  }

  .suggested-text {
    @include carbon--type-style('productive-heading-01');
    margin-bottom: 1rem;
  }

  .header-row.view-all {
    margin-top: -0.5rem;

    a {
      text-decoration: none;
    }
  }

  .view-all-button {
    min-height: unset;
    margin-right: -1rem;
  }

  .button-row {
    border-top: 1px solid #dcdcdc;
    min-width: 100%;
    min-height: 2.5rem;
    .add-resource-button {
      background-color: $white-0;
      min-height: unset;
    }
  }

  .empty-state-container {
    display: flex;
    align-items: center;
    height: 75%;
    min-height: 17.25rem;

    .empty-state {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      padding-left: 16px;

      .empty-state-icon-container {
        display: flex;
        max-width: 80px;
        justify-content: center;
      }

      .text-container {
        margin-left: 0;
      }
    }
  }
}
