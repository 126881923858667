@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.cloud-details-view {
  background-color: $gray-10;

  .cloud-details-card-container {
    padding-top: $spacing-05;
  }
  
  .cloud-details-skeleton {
    width: 100%;
    height: $spacing-13 + $spacing-10;
  }

  .cloud-run-now-error-skeleton {
    width: 100%;
    height: $spacing-07;
    margin: $spacing-05;
    padding: $spacing-05;
  }

  .no-underline-link {
    text-decoration: none !important;
  }

  a {
    text-decoration: none;
    color: $text-secondary;
  }

  a:hover {
    color: $hover-primary-text;
    text-decoration: underline !important;

    .bx--tooltip__trigger {
      color: $hover-primary-text;
      cursor: pointer;
    }
  }

  .auto-discover-label {
    .auto-discover-label-item {
      display: flex;
      align-items: center;

      .completed-icon,
      .errored-icon,
      .in-progress-icon,
      .pending-icon {
        line-height: 1;
      }

      .errored-icon {
        filter: invert(14%) sepia(82%) saturate(5900%) hue-rotate(352deg) brightness(91%) contrast(86%);
      }

      .pending-icon {
        line-height: 1;
        filter: invert(20%) sepia(77%) saturate(2871%) hue-rotate(210deg) brightness(91%) contrast(123%);
      }

      .autodiscovery-icon {
        margin-right: 10px;
      }
    }
  }

  .error-notification {
    margin-left: $spacing-05;
    margin-right: $spacing-05;

    .notification {
      height: $spacing-09;
    }

    .bx--inline-notification__text-wrapper {
      flex: 1;

      .bx--inline-notification__subtitle {
        flex: 1;

        .subtitle {
          width: 100%;

          button {
            margin-top: -$spacing-03;
            float: right;
          }
        }
      }
    }
  }
}