@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-page {
  height: 100%;
  width: 100%;

  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    padding-bottom: 0;
    background-color: $ui-background;

    .network-segment-filter-container {
      .bx--multi-select__wrapper {
        width: 272px;
        grid-gap: 0;
        .bx--text-input {
          border: 0;
        }
      }
    }
  }

  .page-content {
    height: calc(100vh - 90px);
    width: 100%;
  }

  .content-switcher-view {
    margin-top: $spacing-03;

    .bx--content-switcher-btn {
      width: 120px;
      display: inline-grid;
      text-align: center;
    }
  }
}
