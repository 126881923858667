@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.apikeys-table {
  .bx--data-table td .bx--tag {
    white-space: nowrap;
    margin: 0 0.25rem 0 0;
  }

  .key-cell {
    display: flex;
    align-items: center;

    .bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
      max-width: none;
    }

    .bx--password-input {
      background: none;
      outline: none;
      border: none;
      padding-left: 0;
    }

    .delete-api-key-btn {
      background: none;
      color: $black-100;

      &:hover {
        background-color: #dcdcdc;
      }
    }

    .bx--text-input--password__visibility__toggle {
      display: none;
    }
  }
}
