@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .action-button {
    display: none;
  }

  #create-network-segment {
    z-index: 10000;

    .bx--modal-container {
      width: 50% !important;
    }
  }

  .page-content {
    padding: $spacing-05;
    background-color: $ui-01;
    flex: 1;
  }

  .bx--modal-container {
    height: 100%;
  }

  .tile-switch-container {
  }

  .gateway-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    margin-top: $spacing-03;
    justify-content: flex-end;
    align-items: flex-end;

    .tile-switch-section {
      display: flex;
      flex-direction: row;
      margin-right: auto;
      flex: 0 0 70%;

      .skeleton-clickable-tile-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        .clickable-tile-skeleton {
          margin-right: $spacing-05;
          width: 34%;
        }
      }

      .gateway-clickable-tile {
        margin-right: $spacing-05;
        width: 34%;
        height: 92px;

        &.unClicked {
          .tile-content {
            .tile-title {
              .selected-icon {
                display: none;
              }
            }
          }
        }

        &.clicked {
          border: 1px solid $gray-100;
        }

        .tile-content {
          display: flex;
          flex-direction: column;

          .tile-title {
            display: flex;
            flex-direction: row;
            .title {
              @include carbon--type-style('label-01');
              color: $gray-70;
              margin-bottom: 0.25rem;
            }
            .selected-icon {
              margin-left: auto;
            }
          }

          .tile-resource-count {
            @include carbon--type-style('productive-heading-05');
            color: $gray-100;
          }
        }
      }
    }

    .bx--text-input {
      padding-right: 3.9rem;
    }

    .unmanaged-checkbox {
      margin-left: $spacing-03;
    }

    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;

      button {
        background-color: $ui-background;
        color: $ui-05;
      }

      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }

  .find-and-filter-container {
    .bx--list-box__menu {
      z-index: 99999;
    }
  }

  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }

    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }

  .gateway-resource-card {
    margin: $spacing-03 0;
    padding-right: $spacing-03;
    padding-left: $spacing-03;
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }
  }

  .cds--grid {
    margin: 0;
  }

  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;
    margin-top: $spacing-05;

    .resouce-group-skeleton {
      width: 100%;
      height: $spacing-13;
    }

    .no-resource-group {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-13 + $spacing-07;
    }
  }

  .add-rg-modal {
    .bx--form-item {
      margin-bottom: $spacing-05;
    }

    .bx--tooltip-definition {
      @include carbon--type-style('body-short-01');
    }
  }

  .bx--modal-container {
    width: 80% !important;
  }

  .skeleton-sort-drop-down {
    display: flex;
    margin-left: -$spacing-05;
    height: $spacing-07;
    overflow: hidden;
    width: 100%;
    margin-right: $spacing-05;

    .sorting-skeleton {
      height: $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }

  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      .bx--tooltip__trigger {
        color: $hover-primary-text;
        cursor: pointer;
      }
    }

    tbody tr {
      &:hover {
        background: none;

        td {
          background: none;
        }
      }

      &:hover:not(
          :has(.connect-gateway-cluster-btn:hover, .deploy-gateway-btn:hover)
        ) {
        td {
          background-color: #dcdcdc;
        }
      }
      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }
}
