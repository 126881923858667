@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.accordionCard {
  background-color: white;
  margin-top: 12px;
  border-left: 3px solid #C6C6C6;

  .accordionTitle {
    padding: 24px 24px 0px 24px;
    @include type.type-style('heading-02');
    font-weight: 600;
    color: $text-primary;
  }

  .accordionSubTitle {
    padding: 8px 24px 0px 24px;
    @include type.type-style('body-01');
    color: $text-secondary;
  }

  .readMoreBlock {
    padding: 23px 0px 15px 24px;
    @include type.type-style('body-compact-01');
    color: $link-primary;
    display: flex;
    align-items: center;
  }
  .readLessBlock {
    height: 44px;
    border-top: 1px solid #E0E0E0;
    padding: 23px 0px 15px 24px;
    @include type.type-style('body-compact-01');
    color: $link-primary;
    display: flex;
    align-items: center;
  }

  .icon-class {
    margin-top: 3px;
    margin-left: 3px;
  }
  .contentContainer{
    padding: 24px 24px 24px 24px;
  }
}