@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.role-details-page {
  .role-permission-table-container {
    margin-top: 1rem;
    background: $white-0;

    .customSkeleton {
      padding: 10px;
      .tab-heading {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 10px;

        .bx--skeleton__placeholder {
          height: 3.5rem;
          width: 120px;
          &:nth-child(1) {
            margin-right: 5px;
          }
          &:nth-child(2) {
            margin-left: 5px;
          }
        }
      }

      .heading-skeleton {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        div {
          display: flex;
          flex-direction: row;
          width: 100%;
          .bx--skeleton__text {
            height: 2rem;
            width: 15% !important;
            margin-right: 10px;

            &:nth-child(1) {
              width: 35% !important;
            }
          }
        }
      }

      .bx--skeleton__text {
        width: 100% !important;
        min-height: 1.5rem;
      }
    }

    .role-permissions-header {
      min-height: 4rem;
      padding: 16px 48px 16px 16px;
      @include type-style('productive-heading-03');
      position: relative;

      .permission-edit-icon {
        position: absolute;
        right: 36px;
        cursor: pointer;
      }
    }

    .header {
      min-height: 3rem;
      background: $gray-20;
      display: flex;
      width: 100%;
      justify-content: center;
      padding-left: 32px;
      .bx--row {
        width: 100%;
        align-items: center;
        @include type-style('productive-heading-01');
        flex-wrap: nowrap;

        div {
          display: flex;
          justify-content: start;
          padding-left: 0;
        }
      }
    }

    .bx--tab-content {
      padding: 0;

      .table-header-container {
        .searchBar {
          min-height: 3rem;
          padding: 0;

          .bx--search {
            min-height: 3rem;

            .bx--search-magnifier {
              .icon-with-tooltip {
                top: 0;
                left: 14px;
                button {
                  min-height: 3rem;

                  svg {
                    fill: $gray-100;
                  }
                }
              }
            }
            input.bx--search-input {
              border: none;
              min-height: 3rem;
            }
            .bx--search-close {
              min-height: 3rem;
            }
          }
        }
      }
    }

    .bx--accordion {
      .bx--accordion__item {
        border-top: none;
        border-bottom: 1px solid $gray-20;

        &.bx--accordion__item--active {
          border-bottom: none;
        }

        .bx--accordion__heading {
          min-height: 3rem;
          align-items: center;
          .bx--accordion__title {
            @include type-style('productive-heading-01');
          }
        }
        .bx--accordion__content {
          padding-right: 1rem;
          padding-bottom: 0;
          padding-top: 0;
          margin-left: 0;
          .bx--row {
            min-height: 3rem;
            align-items: center;
            display: flex;
            border-top: 1px solid $gray-20;
            padding-left: 2rem;
            @include type-style('body-short-01');

            .rg_name {
              @include type-style('body-short-01');
              word-break: break-word;
            }
            .labels {
              display: flex;
              flex-direction: column;
              .group-check,
              .resources-check {
                @include type-style('body-short-01');
                color: $gray-70;
                min-height: 20px;
                word-break: break-word;
              }
              .group-check {
                margin-bottom: 2px;
              }
              .resources-check {
                margin-top: 2px;
              }
            }

            &.resource-group-row {
              min-height: 4rem;

              .resource-group-col {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                align-items: start;

                .group-check {
                  margin-bottom: 2px;
                  min-height: 20px;
                }
                .resources-check {
                  margin-top: 2px;
                  min-height: 20px;
                }

                &:nth-child(1) {
                  padding-left: 1rem;
                  padding-right: 0;
                }
              }
            }

            &.resource-type-row {
              .resource-type-col {
                padding-left: 0;
                display: flex;

                &:nth-child(1) {
                  padding-left: 1rem;
                  padding-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
