@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.services-tile {
  padding: 0;
  max-height: 25rem;
  padding-bottom: $spacing-07;

  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    // border-bottom: 1px solid $ui-03;

    .header {
      @include carbon--type-style('productive-heading-03');
      padding: $spacing-05;
    }

    button svg {
      margin-left: $spacing-03;
    }
  }

  .services {
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    max-height: 18rem;
    overflow: auto;
  }

  .empty-state {
    padding: $spacing-07 $spacing-10 $spacing-07 $spacing-03;
  }
}