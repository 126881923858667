@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.assign-identity-tearsheet {
  align-items: center !important;
  .bx--modal-container {
    max-height: 600px;
  }
  .assign-identity-form {
    padding: 1rem;
    #assign-identity-form-dropdown {
      .bx--list-box__menu {
        .bx--list-box__menu-item {
          background-color: $gray-10;
        }
      }
    }
    .add-labels-component {
      margin-top: 1rem;
    }

    .helper-text {
      @include carbon--type-style('helper-text-01');
      color: $text-05;
      margin-top: $spacing-02;
    }
  }
  .inline-notification-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
