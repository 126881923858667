@import 'carbon-components/scss/globals/scss/typography.scss';

.create-role-details-step {

  .c4p--tearsheet-create__step--title {
    display: none;
  }
  .c4p--tearsheet-create__step--subtitle {
    @include type-style('productive-heading-03');
    color: $text-01;
    margin-bottom: $spacing-03;
    margin-left: $spacing-03;
  }

  .row {
    padding: $spacing-03;

    input, textarea {
      background-color: white;
    }
  }

  .add-labels-component .input-container .input {
     width: 85%;
  }

  .add-labels-component .input-container .button {
    width: 15%;
 }
}