@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.resource-group-card {
  min-height: 100%;
  cursor: pointer;

  .header {
    margin-bottom: 1rem;
    .name {
      @include carbon--type-style('heading-02');
    }
  }

  .helper-text {
    @include carbon--type-style('helper-text-01');
    color: $text-05;
  }

  .label-text {
    @include carbon--type-style('label-01');
    color: $text-02;
  }

  .value,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    @include carbon--type-style('body-short-01');
  }

  .data {
    margin-bottom: 1rem;
  }

  .from {
    margin-bottom: 1rem;
  }

  .tags {
    margin-left: unset;
    margin-bottom: 1rem;

    .bx--tag {
      margin-left: 0;
    }
  }

  .descContent {
    text-align: left;
  }

  .resource-name {
    text-align: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
