@import 'carbon-components/scss/globals/scss/typography.scss';

.side-navigation {
  border-right: solid 1px $gray-20;

  .bx--side-nav__link {
    cursor: pointer;
  }

  .side__nav__divider {
    margin: $spacing-03 $spacing-05;
    border: 1px solid $gray-20;
  }

  a {
    .bx--side-nav__link-text {
      color: $gray-100;
    }
  }

  .bx--side-nav__icon {
    svg {
      fill: $gray-100;
    }
  }
}

.side-navigation.nav-expanded {
  box-shadow: 1px 5px 10px #e0e0e0;
}
