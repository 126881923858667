@import 'carbon-components/scss/globals/scss/typography.scss';
.topology-legend-connection-section-row {
  padding-top: 10px;
  padding-bottom: 10px;

  .connections-section-items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 204px;
    height: 40px;
    padding-bottom: 100px;
    .connections-sub-section-label {
      @include carbon--type-style('body-long-01');
    }
  }
  .connections-active {
    padding-top: 10px;
  }
  .connections-active2{
    margin-top: -14px;
  }
  .connections-active1{
    margin-top: -15px;
  }
}
