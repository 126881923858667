@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.identity-details {
  .page-content {
    background-color: $ui-01;
    padding: 1rem;
  }

  .details-tile {
    margin-bottom: 1rem;
  }

  .details-tile-skeleton {
    width: 100%;
    min-height: 20rem;
  }

  .bx--data-table-header,
  .bx--table-toolbar,
  td {
    background: white;
  }

  .bx--child-row-inner-container {
    width: 74.25%;
    float: right;
  }

  .bx--pagination {
    background-color: $ui-background;

    select {
      background-color: $ui-background;
    }
  }

  .delete-identity {
    margin-top: 1rem;
  }

  .delete-identity-button-disabled {
    pointer-events: none;
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
  }

  .IdentityDeleteDisabled {
    border-color: transparent;
    background: transparent;
    color: #c6c6c6;
    outline: none;
    cursor: not-allowed;
    box-shadow: none;
  }

  .delete-identity-modal {
    .bx--modal-container {
      height: 14.5rem;
    }
  }

  .details-tile .data-container .value .bx--tag {
    margin-left: 0;
    margin-right: $spacing-04;
  }

  .generic-table-title {
    background-color: #ffffff;
    height: 4rem;
    padding: 1rem 1rem 1rem 1rem;
  }
}