@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.service-tile {
  border-bottom: 1px solid $ui-03;
  min-height: 4.5rem;
  display: flex;
  justify-content: space-between;

  .action-buttons {
    display: none;
  }
  .service-name {
    @include carbon--type-style('body-long-01');
    margin-bottom: $spacing-02;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pairs {
    @include carbon--type-style('caption-01');
    color: $text-02;
    margin-bottom: $spacing-02;
    .ports-spacing {
      padding: 0 1rem;
    }
    .protocol-spacing {
      padding-left: 0.25rem;
    }
    .bx--assistive-text {
      display: flex;
      flex-direction: column;
    }
  }

  .labels {
    display: flex;
    .bx--tag {
      margin-left: 0;
    }
  }

  &:hover {
    background-color: $background-hover;

    .action-buttons {
      display: flex;
      align-items: center;

      .edit-svg {
        margin-right: $spacing-06;
      }

      .edit-link,
      .delete-link {
        color: black;
        cursor: pointer;
      }

      .tooltipDeleteService {
        .bx--tooltip__trigger::before {
          top: 5px;
          left: -11px;
          transform: rotate(-90deg);
        }
        .delete-link.disabled {
          cursor: not-allowed;
        }
        .bx--assistive-text {
          left: -250px;
          top: 40px;
        }
      }
    }
  }
  .service-info {
    width: 80%;
  }
}

.service-tile.labels {
  max-height: 6.5rem;
}
