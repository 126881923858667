@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-canvas-view {
  position: relative;
  height: 100%;
  width: 100%;

  .groups {
    .path_placeholder {
      fill-opacity: 0.4;
      stroke-opacity: 1;
    }
  }

  .edge-labels {
    text {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-anchor: middle;

      // For text background
      stroke: $gray-10;
      stroke-width: 0.5em;
      paint-order: stroke;
      stroke-linejoin: round;
    }
  }
  .nested-edge-labels {
    text {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
      text-anchor: middle;

      // For text background
      fill: white;
      stroke: $gray-60;
      stroke-width: 0.1em;
      paint-order: stroke;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
  }
  .nested-edge-labels-circle{
    circle{
      fill:$gray-60;
      stroke:$gray-60;
    }
  }

  .links {
    line {
      stroke: $gray-60;
      stroke-width: 1px;
    }

    .transparent-line {
      stroke: transparent;
      stroke-dasharray: 0;
      stroke-width: 20px;
      stroke-opacity: 1%;
    }

    .hover {
      stroke: $gray-100;
    }

    .focus {
      stroke: $blue-60;
    }

    .disabled {
      stroke: $gray-20;
    }

    .relation-line {
      stroke-dasharray: 2 2;
    }

    .policy-line {
      stroke-dasharray: 4 3;
      stroke-width: 2px;
      &:hover {
        stroke: $gray-60;
      }
    }

    .gateway-line {
      cursor: pointer;
      stroke-dasharray: 0;
      stroke-width: 3px;
      &:hover{
        cursor: pointer;
        stroke: $gray-100;
        stroke-dasharray: 0;
        stroke-width: 3px;
      }
    }

    .connection-line {
      stroke-dasharray: 0;
      cursor: pointer;
      stroke: $gray-10;
      stroke-width: 1px;
      filter: drop-shadow(1px 0 0 $gray-60) drop-shadow(-1px 0 0 $gray-60)
        drop-shadow(0 -1px 0 $gray-60) drop-shadow(0 1px 0 $gray-60);
      &:hover {
        cursor: pointer;
        stroke-dasharray: 0;
        stroke: $gray-10;
        stroke-width: 1px;
        filter: drop-shadow(1px 0 0 $gray-100) drop-shadow(-1px 0 0 $gray-100)
          drop-shadow(0 -1px 0 $gray-100) drop-shadow(0 1px 0 $gray-100);
      }
    }

    .connection-line-hitSpot {
      cursor: pointer;
      stroke-dasharray: 0;
      stroke-width: 11px;
      stroke: transparent;
    }

    .connection-line-hitSpot:hover + .connection-line {
      cursor: pointer;
      stroke-dasharray: 0;
      stroke: $gray-10;
      stroke-width: 1px;
      filter: drop-shadow(1px 0 0 $gray-100) drop-shadow(-1px 0 0 $gray-100)
        drop-shadow(0 -1px 0 $gray-100) drop-shadow(0 1px 0 $gray-100);
    }

    .connection-line-hitSpot + .connection-line-focus {
      cursor: pointer;
      stroke-dasharray: 0;
      stroke: $gray-10;
      stroke-width: 1px;
      filter: drop-shadow(1px 0 0 $blue-60) drop-shadow(-1px 0 0 $blue-60)
        drop-shadow(0 -1px 0 $blue-60) drop-shadow(0 1px 0 $blue-60);
    }

    .gateway-line-hitSpot {
      cursor: pointer;
      stroke-dasharray: 0;
      stroke-width: 11px;
      stroke: transparent;
    }

    .gateway-line-hitSpot:hover + .gateway-line {
      cursor: pointer;
      stroke: $gray-100;
      stroke-dasharray: 0;
      stroke-width: 3px;
    }

    .gateway-line-hitSpot + .gateway-line-focus {
      cursor: pointer;
      stroke: $blue-60;
      stroke-dasharray: 0;
      stroke-width: 3px;
    }

    .nested-line {
      stroke-dasharray: 0;
      stroke-width: 3px;
      &:hover {
        stroke: $gray-60;

      }
    }
  }

  .nodes {
    .label {
      font-family: 'IBM Plex Sans Condensed';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-anchor: middle;
      // For text background
      // stroke: $purple-20;
      // stroke-width: 0.9em;
      // paint-order: stroke;
      // stroke-linejoin: round;
    }

    circle {
      fill: $white-0;
      stroke-width: 2px;
    }

    &.hover circle {
      fill: $gray-10;
    }

    .focused-circle {
      fill: none;
      stroke: $focus;
      stroke-width: 2px;
    }

    .hidden-circle {
      display: none;
    }

    .location-svg {
      fill: $purple-70;
      stroke: $purple-80;
    }

    &.hover .location-svg {
      fill: $purple-80-hover;
      stroke: $purple-80;
    }

    .partition-svg {
      stroke: $teal-70;

      ~ .label {
        font-size: 12px;
        font-weight: 450;
      }

      + image {
        filter: invert(27%) sepia(17%) saturate(3396%) hue-rotate(140deg)
          brightness(96%) contrast(101%);
      }
    }

    .deployment-env-svg {
      stroke: $cyan-60;

      + image {
        filter: invert(30%) sepia(64%) saturate(2132%) hue-rotate(185deg)
          brightness(92%) contrast(101%);
      }
    }

    .infraonly {
      stroke: none;
    }

    .application-svg {
      stroke: $magenta-70;

      ~ .label {
        font-size: 12px;
        font-weight: 450;
      }

      + image {
        filter: invert(18%) sepia(31%) saturate(7226%) hue-rotate(317deg)
          brightness(82%) contrast(94%);
      }
    }

    .service-svg {
      stroke: $red-90;

      ~ .label {
        font-size: 12px;
        font-weight: 450;
      }

      + image {
        filter: invert(7%) sepia(46%) saturate(7338%) hue-rotate(349deg)
          brightness(77%) contrast(103%);
      }
    }

    .gateway-svg {
      stroke: $blue-80;

      ~ .label {
        font-size: 12px;
        font-weight: 450;
      }

      + image {
        filter: invert(14%) sepia(87%) saturate(3595%) hue-rotate(222deg)
          brightness(77%) contrast(108%);
      }
    }

    .gateway-type-svg {
      filter: none;
    }

    &.disabled {
      circle {
        stroke: $gray-30;
      }

      &.hover circle {
        stroke: $gray-30-hover;
      }

      image {
        filter: invert(70%) sepia(0%) saturate(1%) hue-rotate(213deg)
          brightness(108%) contrast(108%);
      }
    }

    &.unmanaged {
      circle {
        stroke: $gray-60;
      }

      &.hover circle {
        stroke: $gray-60-hover;
      }

      image {
        filter: invert(44%) sepia(0%) saturate(1969%) hue-rotate(251deg)
          brightness(97%) contrast(89%);
      }

      .location-svg {
        fill: $purple-70;
        stroke: $purple-80;

        + image {
          filter: none;
        }
      }

      &.hover .location-svg {
        fill: $purple-80-hover;
        stroke: $purple-80;
      }

      .infraonly {
        stroke: none;
      }

      &.hover {
        .infraonly {
          stroke: none;
        }
      }
    }
  }
  
}
