@import 'carbon-components/scss/globals/scss/typography.scss';
.topology-legend-status {
  padding-top: 10px;
  padding-bottom: 18px;
  .legend-status-header {
    @include carbon--type-style('productive-heading-01');
    padding-bottom: 5px;
  }
  .legend-status-desc {
    @include carbon--type-style('helper-text-02');
    color: $gray-70;
  }
  .legend-status-label-icon {
    @include carbon--type-style('body-long-01');

    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .legend-status-label {
      padding-bottom: 4px;
    }
  }
}
