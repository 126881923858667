@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.connection-access-policy-details {
  .canvas {
    border-top: 1px solid $ui-03;
    background: $ui-01;
    display: flex;
    width: 100%;
    .bx--col-lg-12 {
      padding-left: 0;
    }
  }
  .details-card-container {
    margin-top: $spacing-05;
    .details-tile .data-container .value .bx--tag {
      margin-left: 0;
      margin-right: $spacing-04;
    }
  }
  .policy-details-skeleton {
    width: 100%;
    height: 72vh;
  }
  .delete-policy {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
  .from-to-connections-container {
    width: 100%;
    margin-top: $spacing-05;
    .from-to-connections-titlebar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: $spacing-05;
      .policy-title {
        @include carbon--type-style('productive-heading-03');
      }
      // CHANGE STYLING ONCE BUG IS FIXED
      .edit-link {
        // cursor: pointer;
        padding-top: $spacing-03;
        margin-right: $spacing-12 + $spacing-05;
        color: #161616;
        background-color: transparent;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
      }
      .bx--tooltip__trigger.bx--tooltip__trigger--definition {
        border-bottom: none;
      }
      svg {
        cursor: not-allowed;
        fill: $icon-on-color-disabled;
      }
      .edit-link:hover {
        background-color: $ui-03;
      }
    }
    .from-to-connections-subtitlebar {
      padding-top: $spacing-03;
      margin-left: $spacing-05;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      @include type-style('body-short-01');
    }
    .from-to-connections {
      display: flex;
      min-height: 57vh;
      margin-top: $spacing-05;

      .connections-tile {
        height: 100%;

        .connection {
          padding: 0;
          width: 100%;

          .remove-icon {
            display: none;
          }
          .network-segment-card {
            .icon {
              img {
                margin-top: 0;
              }
            }
          }
          .policy-card {
            margin-top: $spacing-05;
          }
          .labels-container {
            margin-left: 2.2rem;
          }
        }
        .title {
          @include carbon--type-style('productive-heading-03');
        }
      }
      .tile-icon {
        width: fit-content;
        margin: 0.5rem;
      }
      .name-and-icon {
        align-items: center;
        flex-wrap: nowrap;
      }
      .connector {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -$spacing-05;
        margin-right: -$spacing-05;
      }

      .policy-header {
        @include carbon--type-style('productive-heading-03');
        margin-top: -0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .connection-from-skeleton {
        margin-top: 1rem;
        padding: 0.5rem;
        border-bottom: 1px solid $ui-03;
        width: 100%;
        height: 7rem;
        .from-container {
          padding-left: $spacing-02;
        }
        .labels-container {
          margin-left: 2.2rem;
        }
      }
      .connection-to-skeleton {
        margin-top: 1rem;
        width: 100%;
        height: 7rem;
        padding: 0.5rem;
        border-bottom: 1px solid $ui-03;
        .to-container {
          margin-left: 1rem;
        }
      }
      .app-description {
        @include carbon--type-style('caption-01');
        color: $text-02;
        margin-bottom: 0.25rem;
        margin-left: 1.6rem;

        .ports-spacing {
          padding: 0 1rem;
        }
        .protocol-spacing {
          padding-left: 0.25rem;
        }
        .bx--assistive-text {
          display: flex;
          flex-direction: column;
        }
      }
      .policy-ports {
        @include carbon--type-style('caption-01');
        color: $text-02;
        margin-bottom: 0.25rem;
        margin-left: 2.7rem;

        .ports-spacing {
          padding: 0 1rem;
        }
        .protocol-spacing {
          padding-left: 0.25rem;
        }
        .bx--assistive-text {
          display: flex;
          flex-direction: column;
        }
      }
      .nameContainer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .deletePolicyModel {
    .bx--modal-container {
      width: 40rem;
      height: 13.25rem;
      .bx--modal-header__heading {
        @include carbon--type-style('body-long-01');
      }
      .bx--modal-header__label {
        @include carbon--type-style('productive-heading-03');
      }
      .bx--modal-close {
        display: block;
      }
    }
  }
}
