@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.procedural-status-component {
  display: flex;

  .icon {
    padding-right: $spacing-02;
  }

  .label {
    @include carbon--type-style('body-short-01');
  }

  .bx--inline-loading {
    min-height: unset;

    .bx--inline-loading__text {
      @include carbon--type-style('body-short-01');
    }
  }

  &.tooltip {
    cursor: pointer;

    .label:hover {
      color: $link-01;
    }
  }
}

.health-status-component {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    @include carbon--type-style('body-short-01');
    margin-left: $spacing-03;
  }

  &.tooltip {
    cursor: pointer;

    .label:hover {
      color: $link-01;
    }
  }

  &:not(.status-operational):hover {
    .label {
      color: $ibm-color__blue-60;
    }
  }
}

.health-status-component-container {
  .bx--tooltip__trigger {
    &.bx--tooltip__trigger--definition {
      border: 0;
      cursor: pointer;
    }
  }
}

.procedural-activity-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .icon {
    display: flex;
    margin-right: $spacing-02;
    margin-top: 0.125rem;
    svg {
      color: $blue-60;
      fill: $blue-60;
    }
  }

  .status-message {
    @include carbon--type-style('body-short-01');
    color: $gray-60;
  }

  .bx--inline-loading {
    .bx--inline-loading__text {
      @include carbon--type-style('body-short-01');
      color: $gray-60;
    }
  }
}

.procedure-field {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    display: flex;
    margin-right: $spacing-02;

    svg {
      color: $blue-60;
      fill: $blue-60;
    }
  }

  .status-message {
    @include carbon--type-style('body-short-01');
    color: $gray-60;
  }

  .bx--inline-loading {
    .bx--inline-loading__text {
      @include carbon--type-style('body-short-01');
      color: $gray-60;
    }
  }
}
