@use '@carbon/react';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

// Set Carbon Feature flags
$feature-flags: (
  grid-columns-16: true,
);

// Set Carbon Global flags
$css--font-face: true;
$css--helpers: true;
$css--body: true;
$css--use-layer: true;
$css--reset: true;
$css--typography: true;
$css--plex: true;

@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g10;
@include carbon--theme();

@import 'carbon-components/scss/globals/scss/styles.scss';

// To fix IBM Plex Sans Condensed not loading issue in Safari
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  src: url('../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  src: url('../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'IBM Plex Sans Condensed';
  src: url('../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBold.ttf');
  font-weight: 600;
}

body {
  margin: auto;
  font-family: 'IBM Plex Sans';
  background: none;
  height: 100%;
  overflow-x: hidden;
}

.app {
  height: 100%;
}

.App {
  min-height: 100vh;
  //background-color: $ui-background;

  .bx--header {
    padding: 0;
    position: sticky;
  }

  .logo-wrapper {
    padding: $spacing-03 0 $spacing-03 $spacing-03;
  }
}

.tenant-dropdown {
  height: 3rem;
  max-height: 3rem;
}

.dropdown-alignment {
  position: absolute;
  right: 0;
  align-items: center;
  display: flex;
}
.page-title {
  display: flex;
  justify-content: space-between;
}

.main-content {
  margin-top: 0px !important;
  padding-top: $spacing-09;
  padding-left: $spacing-09;
  padding-bottom: 0;
  padding-right: 0;
  height: 100%;

  .page-content {
    .body {
      .bx--pagination,
      .card-view {
        margin-bottom: $spacing-07;
      }
    }
  }
}

.tenant-dropdown button {
  background-color: $gray-100;
}

.tenant-dropdown svg {
  fill: $gray-10;
}
.tenant-dropdown button:active {
  background-color: $gray-100;
}
.tenant-dropdown span {
  color: $gray-10;
  opacity: 0.8;
}

.tenant-dropdown span:hover {
  color: $white-0;
  opacity: 1;
}
.main-content {
  background-color: $gray-10;
}

.resource-dropdown button {
  background-color: $gray-100;
}

.resource-dropdown button:active {
  background-color: $gray-100;
  opacity: 1;
  color: $white-0;
}

.resource-dropdown svg {
  fill: $gray-10;
}

.resource-dropdown span {
  color: $gray-10;
  opacity: 0.8;
}

.resource-dropdown span:hover {
  color: $white-0;
  opacity: 1;
}

.bx--dropdown--inline .bx--list-box__menu {
  min-width: $spacing-13 + $spacing-07;
  max-width: $spacing-13 + $spacing-13 + $spacing-13;
}
.bx--dropdown--sm {
  height: $spacing-09;
  max-height: $spacing-09;
}
.bx--list-box--sm .bx--list-box__menu-item {
  height: $spacing-08;
}

.bx--list-box--sm .bx--list-box__menu-item__option {
  height: $spacing-08;
  padding-top: 0.5375rem;
  padding-bottom: 0.4375rem;
}

.bx--dropdown__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-gap: 0 0rem;
  grid-template: auto auto/auto min-content;
}
.tenant-dropdown,
.resource-dropdown {
  .bx--list-box__menu-item {
    background-color: $gray-100;
  }

  .bx--list-box_menu:focus {
    outline: 1px solid #353535;
  }

  .bx--list-box__menu-item:hover {
    background-color: #353535;

    .bx--list-box__menu-item__option {
      color: $gray-10;
    }
  }

  .bx--list-box__menu-item__option {
    border-top-color: $gray-100;
    color: $gray-10;
  }

  .bx--list-box__field:hover {
    outline: 2px solid #353535;
    outline-offset: -2px;
    background-color: #2c2c2c;
  }

  .bx--list-box__field:focus {
    outline: 2px solid $gray-100;
    outline-offset: -2px;
    background-color: $gray-90;
  }

  .bx--list-box__field:active {
    outline: 2px solid $gray-100;
    outline-offset: -2px;
    background-color: #2c2c2c;
  }

  .bx--list-box__menu-item--active.bx--list-box__menu-item--highlighted {
    background: $gray-90;
    color: $gray-30;
  }

  .bx--list-box__menu:focus {
    outline: 1px solid #353535;
  }
}

.bx--content-switcher.view-switcher {
  button.switch-button {
    height: $spacing-07;
    min-height: $spacing-07;
    padding-left: 0.438rem;
    padding-right: 0.438rem;

    &:nth-child(2) {
      .bx--assistive-text {
        position: absolute;
        left: -3px;
      }
    }
  }
}

.bx--tooltip__trigger.bx--btn--icon-only--bottom.bx--tooltip--align-center
  .bx--assistive-text {
  bottom: -0.7925rem;
  transform: translate(-50%, 100%);
}

.profile-icon-header {
  cursor: pointer;
  .identity-profile-icon {
    @include carbon--type-style('label-01');
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    text-align: center;
    background: #1192e8;
    margin: 0 10px 0 0px;
  }
}

.popoverContent {
  position: absolute;
  top: 11px;
  right: 0;
  width: 18rem;
  color: #fff;
  background: #161616;
  border: 1px solid #393939;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;

  .profile-section {
    display: flex;
    padding-bottom: 16px;
    align-items: center;
    border-bottom: 1px solid #393939;
    .identity-profile-icon-popover {
      @include type-style('productive-heading-04');
      color: $text-01;
      width: 64px;
      height: 64px;
      line-height: 64px;
      border-radius: 50%;
      text-align: center;
      background: #1192e8;
      margin: 0 10px 0 0px;
    }
    .c4p--user-profile-image--xlg {
      height: 48px;
      width: 48px;
      font-size: 20px;
    }
    .profile-name {
      @include type-style('productive-heading-01');
      margin-bottom: 2px;
    }
    .profile-email {
      @include type-style('body-short-01');
      color: #f4f4f4;
    }
  }
  .logout-container {
    &:hover {
      // background-color: #353535;
      cursor: pointer;
    }
    // height: 52px;
    display: flex;
    align-items: center;
    padding-top: 16px;

    span.link_text {
      @include type-style('body-short-01');
      color: #c6c6c6;
      margin-left: 8px;
      display: flex;
      align-items: center;
    }

    span.link-container {
      display: flex;
    }
  }
}

.card-view {
  margin-top: $spacing-03;
}
.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
}
