.bx--data-table td .bx--tag {
  white-space: nowrap;
  margin: 0 0.25rem 0 0;
}
.bx--data-table td:nth-child(1) {
  padding-right: 0;
  white-space: nowrap;
}

.no-underline-link {
  text-decoration: none !important;
}

td .resource-cell {
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }
}
