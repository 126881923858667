@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.gateway-details-container {
  .header-wrapper {
    .gw-health-status {
      gap: 8px;
      align-items: baseline;
    }
  }
  .gateway-details-section {
    background-color: $ui-01;
    padding: 1rem;

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      border-bottom: 0px;
    }

    .delete-service-modal {
      .bx--modal-container {
        width: 35rem;
        height: 13.25rem;
      }
    }

    .delete-application-modal {
      .bx--modal-container {
        width: 35rem;
        height: 13.25rem;
      }
    }

    .applicationDetailsDeleteDisabled {
      border-color: transparent;
      background: transparent;
      color: #c6c6c6;
      outline: none;
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  .tiles {
    display: flex;

    .bx--tile {
      flex: 1;
    }

    .details-tile {
      margin-top: 0rem;
      margin-right: 0.5rem;
      margin-bottom: 0rem;

      .header {
        margin-bottom: 0rem;
        display: flex;
        padding-top: 0rem;
        padding-bottom: 1rem;

        .text {
          margin-top: 0.5rem;
        }
      }

      .data-container {
        padding-bottom: 0rem;
      }
    }

    .services-tile {
      max-width: 25%;
      margin-left: 0.5rem;
      max-height: max-content;
    }
  }

  .gateway-details-skeleton {
    width: 50%;
    height: 24.5rem;

    &:first-child {
      margin-right: 1rem;
    }
  }

  .deployments-section {
    background-color: white;

    .text {
      @include carbon--type-style('productive-heading-03');
    }

    .empty-depl-state {
      margin-left: 25%;
    }

    .deployment-button-skeleton {
      width: 45rem;
      margin-top: 5rem;
      margin-left: 95.25rem;
    }

    .deployment-table-skeleton {
      width: 99%;
    }
  }

  .bx--inline-notification__text-wrapper {
    width: 100%;

    .bx--inline-notification__subtitle {
      flex-grow: 1;
    }

    .gateway-status-error-notification {
      display: flex;
      justify-content: space-between;

      .link {
        color: $link-01;
        cursor: pointer;
      }
    }
  }
  .delete-gateway {
    margin-top: $spacing-05;
    margin-bottom: 4rem;

    .gatewayDeleteDisabled {
      border-color: transparent;
      background: transparent;
      color: #c6c6c6;
      outline: none;
      cursor: not-allowed;
      box-shadow: none;
    }
  }

  .connected-namespace-skeleton {
    width: 25%;
    height: auto;
    margin-left: 1rem;
  }

  .header-icon-contaniner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon-text {
      padding-left: 4px;
    }
    .managed-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $interactive-01;
      background-color: $interactive-01;
      border-radius: 50%;
    }
    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
    }
    &.unmanaged {
      .icon-text {
        color: $gray-60;
      }
    }
  }

  .sub-heading-section {
    display: flex;

    .header-icon-contaniner {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon-text {
        @include type.type-style('body-01');
        padding-left: 4px;
      }

      &.unmanaged {
        .icon-text {
          color: $text-helper;
        }
      }
    }

    .health-status-component {
      margin-left: $spacing-03;
      align-items: center;
      justify-content: flex-start;

      .icon {
        padding-right: $spacing-02;
        height: 20px;
      }
    }
  }
}
