@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.remote-gateway-connections {
  margin-top: $spacing-05;

  .generic-table-title {
    height: 3.75rem;
    padding: 1rem;
  }

  .bx--data-table-container {
    margin-bottom: 0px;
  }

  .bx--data-table thead {
    position: sticky;
    top: 0;

    .bx--tooltip__trigger.bx--tooltip__trigger--definition {
      font-size: inherit;
      font-weight: inherit;
      border-bottom: 0.0625rem dotted #525252;
      transition: border-color 110ms;
    }
  }

  .bx--data-table-content {
    overflow: visible;

    @media (max-width: 768px) {
      overflow: auto;
    }
  }

  .bx--data-table tbody {
    overflow-x: auto;

    tr {
      td {
        .action-buttons {
          visibility: hidden;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .delete-remote-connection-btn {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: $gray-30;
            }

            button {
              color: $gray-70;
            }
          }
        }
      }
      &:hover {
        cursor: pointer;
        background-color: $background-hover;
        .action-buttons {
          visibility: visible;
        }
      }
    }
  }

  .name-column-value {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .value {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.125rem;
      color: $gray-70;
    }

    .icon {
      margin-left: 0.25rem;
      width: 0.75rem;
      height: 0.75rem;

      .icon-with-tooltip {
        .bx--btn--ghost:hover {
          background-color: $gray-10;
        }
      }
    }
  }

  .no-underline-link {
    text-decoration: none !important;
  }

  .bx--data-table {
    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }
  .pagination-hide {
    display: none;
  }
  .delete-remote-connection-confirm-modal {
    .text-bold {
      font-weight: bold;
    }
  }
  .direction-arrow-format {
    display: flex;
    flex-direction: row;
    align-items: center;
    .direction-arrow {
      padding-left: 10px;
      margin-top: 5px;
    }
  }
}
