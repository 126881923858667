@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.generic-status-component {
  display: flex;

  .status-icon {
    padding-right: $spacing-02;
  }

  .status-label {
    @include carbon--type-style('body-short-01');
  }
}
