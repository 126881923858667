@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.deployment-envs-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .page-content {
    background: $ui-01;
    padding: $spacing-05;
    flex: 1;
  }

  .environments-switcher {
    display: inline-flex;
    width: 100%;
    height: unset;
    margin-bottom: $spacing-05;
    justify-content: flex-end;

    .unmanaged-checkbox {
      margin-left: $spacing-03;
    }

    .view-switcher {
      justify-content: unset;
      width: fit-content;
      height: unset;

      button {
        background-color: $ui-background;
        color: $ui-05;
      }

      .selected {
        background-color: $ui-05;
        color: $ui-background;
      }
    }
  }

  .pal--data-table {
    .bx--table-toolbar {
      background-color: $ui-background;
    }

    tbody,
    td,
    .bx--pagination,
    select {
      background-color: $ui-background;
    }
  }

  .resource-card {
    margin: $spacing-03 0;
    padding-right: $spacing-03;
    padding-left: $spacing-03;
  }

  .card-view {
    .resource-card-alignment {
      display: flex;
      flex-wrap: wrap;
      margin-right: -$spacing-03;
      margin-left: -$spacing-03;
    }
  }

  .cds--grid {
    margin: 0;
  }

  .cds--grid {
    margin: 0;
  }

  .deployment-card {
    margin: $spacing-05 0;
  }

  .skeleton-card-view {
    display: flex;
    margin-left: -$spacing-05;

    .deployment-env-skeleton {
      height: $spacing-13 + $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }

  .bx--data-table {

    a {
      text-decoration: none;
      color: $text-secondary;
    }

    a:hover {
      color: $hover-primary-text;
      text-decoration: underline !important;

      .bx--tooltip__trigger {
        color: $hover-primary-text;
        cursor: pointer;
      }
    }

    tbody tr:hover {
      background-color: #dcdcdc;

      a:nth-child(1) {
        color: $hover-primary-text;
      }
    }
  }

  .tags {
    margin-top: 1rem;
  }

  .icon {
    margin-left: 0.25rem;
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;

    .unmanaged-icon {
      height: $spacing-04;
      width: $spacing-04;
      border: 1px solid $gray-30;
      border-radius: 50%;
      position: absolute;
      margin-bottom: 0.65rem;
    }

    .icon-with-tooltip {
      margin-left: 5px;

      .bx--btn--ghost:hover {
        background-color: transparent;
      }
    }
  }

  .skeleton-sort-drop-down {
    display: flex;
    margin-left: -$spacing-05;
    height: $spacing-07;
    overflow: hidden;
    width: 100%;
    margin-right: $spacing-05;

    .sorting-skeleton {
      height: $spacing-07;
      width: 100%;
      margin-left: $spacing-05;
    }
  }
}
