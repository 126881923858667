@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';

.sort-dropdown-component {
  width: 20%;

  div.bx--list-box__wrapper:first-child {
    display: flex;
    margin-right: $spacing-05;
    align-items: center;

    label {
      width: $spacing-11;
      margin-bottom: 0;
    }

    .bx--list-box {
      height: $spacing-07;
    }

    .bx--list-box__selection {
      display: none;
    }
  }

  .bx--list-box input[role='combobox'],
  .bx--list-box input[type='text'] {
    min-width: 0;
    background-color: $white-0;
  }
}