@use '@carbon/type';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';

.cloud-locations-list {
  margin: $spacing-05;

  .cloud-location-skeleton {
    width: 100%;
    height: 214px;
  }

  .data-container {
    width: 100%;
    padding-left: $spacing-07;
    padding-right: $spacing-07;

    .deploymentenv-session,
    .location-session {
      padding: 0;

      .header {
        height: 60px;
        background-color: $gray-20;
        border: 1px solid $gray-30;
        border-top: none;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-05;

        .title {
          @include type.type-style('heading-03');
          color: $gray-100;
        }

        .button-group {
          display: flex;
          justify-content: space-between;

          .bx--btn--ghost:hover {
            background-color: #d6d6d6;
          }
        }
      }

      .body {
        .no-data-container,
        .error403-state {
          width: 100%;
          padding: 0;
          margin: 0;
          max-height: 214px;

          .vertical-empty-state,
          &.error403-state {
            min-height: unset;
          }

          &.error403-state {
            padding: $spacing-07;
            height: 296px;

            .header {
              margin-bottom: $spacing-03;
            }
          }

          .header,
          .description {
            background: none;
            border: none;
            padding: 0;
            color: $gray-100;
            height: unset;
          }

          .header {
            @include type.type-style('body-short-02');
          }

          .description {
            @include type.type-style('caption-01');
          }

          .empty-state-icon,
          .error403-state-icon {
            img {
              height: 64px;
              width: 64px;
            }
          }

          .error403-state-icon {
            padding-top: $spacing-03;
          }
        }
      }
    }

    .location-session {
      .header {
        border-left: none;

        .button-group:has(> :nth-child(2)) {
          min-width: 214px;
        }
      }

      .body {
        max-height: calc(100vh - 253px);
        overflow: scroll;

        border: 1px solid $gray-20;
        border-top: none;
        border-right: none;

        .no-locations-empty-state {
          .no-data-container,
          .error403-state {
            border-right: 1px solid $gray-20;
            background-color: $gray-10;
          }
        }
      }
    }

    .deploymentenv-session {
      .header {
        border-left: none;
        border-right: none;

        .button-group:has(> :nth-child(2)) {
          min-width: 244px;
        }

        .title {
          display: flex;
          align-items: center;

          .name {
            white-space: nowrap;
          }

          .in,
          .count {
            padding-left: $spacing-01;
          }

          .location-name {
            @include type.type-style('heading-03');
            padding-left: $spacing-01;
            color: $text-primary;
          }
        }
      }

      .body {
        max-height: calc(100vh - 156px);
        overflow: scroll;

        .no-deployment-env-state {
          background-color: $white-0;

          border: 1px solid $gray-30;
          border-top: none;
          border-left: none;
        }

        .no-locations-depl-empty-state {
          .no-data-container,
          .error403-state {
            border: 1px solid $gray-20;
            border-top: none;
            border-left: none;
            background-color: $gray-10;
          }
        }

        .no-location-selected-state {
          .no-data-container,
          .error403-state {
            background-color: $gray-10;
          }
        }

        .location-deployment-envs {
          background: $white-0;
          border-right: 1px solid $gray-20;
          border-bottom: 1px solid $gray-20;
        }
      }
    }
  }

  .cloud-delete-button {
    position: sticky;
    background-color: $gray-10;
    bottom: 0;
    padding: $spacing-06 0;
  }

  .bx--tooltip__trigger.bx--tooltip__trigger--definition {
    border-bottom: none;
  }

  .find-and-filter-container {
    .leftFiltersContainer .dropdown {
      border-bottom-color: $gray-20;
      margin-bottom: 1px;
    }
  }
}
