@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.autofill-search-component {
  position: relative;
  width: 100%;
  height: 100%;

  .bx--search {
    height: 100%;

    .bx--search-input {
      height: 100%;
    }

    .bx--search-close {
      height: 100%;
      &::before {
        display: none;
      }
      &:hover {
        background-color: transparent;
        border: 0;
        outline: 0;

        &:before {
          background-color: transparent;
        }
      }
    }
  }

  .autofill-suggestion-container {
    position: absolute;
    width: 100%;
    background: $white-0;
    padding: $spacing-04 0 $spacing-01;
    box-shadow: 0px $spacing-01 $spacing-01 + $spacing-02 0px #0000004d;
    z-index: 1000;

    .no-suggestions {
      margin-bottom: $spacing-04;
      padding: 0 $spacing-05;
      .no-suggestions-message {
        @include carbon--type-style('body-short-01');
      }
    }

    .autofill-suggestion-list {
      .heading {
        @include carbon--type-style('label-01');
        margin-bottom: $spacing-02;
        padding: 0 $spacing-05;
        color: $gray-60;
      }
      .autofill-suggestion-category {
        margin-top: $spacing-01;
        margin-bottom: $spacing-04;
      }
      .autofill-suggestion-options {
        margin-top: $spacing-01;
        .autofill-option {
          display: flex;
          line-height: 1;
          padding: $spacing-02 $spacing-05;
          cursor: pointer;
          position: relative;
          align-items: end;
          &:hover,
          &.active {
            background-color: $gray-10;

            .add-icon {
              visibility: visible;
            }
          }
          .option-icon {
            display: flex;
            align-items: center;
          }
          .option-name {
            margin-left: $spacing-03;
            @include carbon--type-style('body-short-01');
          }
          .add-icon {
            position: absolute;
            right: 0;
            margin-right: $spacing-05;
            visibility: hidden;
            color: $blue-60;
            @include carbon--type-style('body-short-01');
          }
        }
        .disabled {
          cursor: not-allowed;

          .option-icon {
            svg {
              fill: $gray-60;
            }
          }
          .option-name,
          .add-icon {
            button {
              @include carbon--type-style('body-short-01');
              color: var(--Text-text-helper, $gray-60);
            }
            color: var(--Text-text-helper, $gray-60);
          }
        }
      }
    }
  }
}
