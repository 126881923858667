@import 'carbon-components/scss/globals/scss/spacing.scss';
@import '@carbon/themes/scss/themes';

@import 'carbon-components/scss/globals/scss/typography.scss';

.deploy-gateway-details {

  .deploy-in-cluster-section {
    margin-top: 24px;

    .heading {
      @include carbon--type-style('productive-heading-03');
      margin-bottom: $spacing-03;
    }

    .sub-heading {
      @include carbon--type-style('body-short-01');
      margin-bottom: $spacing-05;
    }

    .select-cluster-section {
      background: white;
      margin-top: 8px;
      padding: 16px;

      .namespaceLabel {
        @include carbon--type-style('label-01');
        color: $text-secondary;
      }

      .namespaceVal {
        @include carbon--type-style('body-short-02');
        margin-bottom: 16px;
      }

      .subDetailsVal {
        @include carbon--type-style('body-short-01');
        margin-bottom: 16px;
      }
    }

    .editBtnContainer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      align-content: flex-start;

      .edit-deployment-button {
        border: none;
        margin-top: -15px;
        width: 82px;
      }
    }
  }

  .gateway-optional-settngs-section {
    margin-top: 32px;

    .optional-settngs-heading {
      @include carbon--type-style('heading-02');
    }

    .gateway-optional-settngs-accordion {
      margin-top: 1rem;

      .ingress-form-description {
        margin-bottom: 1rem;
      }

      .row.bx--row {
        margin-bottom: 2rem;
      }

      @media (min-width: 640px) {
        .cds--accordion__content {
          padding-inline-end: 3rem;
        }
      }
    }

    .optional-settings-accordion {
      .cds--accordion__item {
        padding: 3px 0px;
        border-color: #C6C6C6;
      }

      margin-top: 16px;

      .cds--accordion__item:last-child {
        border-block-end: 1px solid #C6C6C6;
      }
    }
  }

  .form-field-segment {
    display: inline-block;
    margin-bottom: $spacing-03;
    @include carbon--type-style('label-01');
    color: $text-02;
  }

  .deploy-gateway-skelton {
    display: flex;
    justify-items: center;
    width: 100%;
    height: 2.5rem;
    margin-top: $spacing-02;
  }
}