@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';
.network-segments-container {
  .networkSegment-clickable-card,
  .networkSegment-card {
    position: relative;
    margin-bottom: $spacing-05;
    height: 330px;
    .header {
      @include carbon--type-style('heading-02');
      .generic-truncate-string .bx--tooltip__trigger {
        @include carbon--type-style('heading-02');
      }
      .unmanaged {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $gray-60;
      }
      .unmanaged-icon {
        height: $spacing-04;
        width: $spacing-04;
        border: $spacing-01 solid $gray-30;
        border-radius: 50%;
        margin-right: $spacing-02;
        margin-top: $spacing-02;
      }
    }
    .first-row-data {
      padding-top: $spacing-05;
    }
    .data {
      padding-top: $spacing-05 + $spacing-02;
    }
    .card-footer {
      position: absolute;
      bottom: $spacing-05;
      .tags {
        .bx--col {
          padding-left: 0;
        }
      }
    }
  }
}
