@import 'carbon-components/scss/globals/scss/colors.scss';
@import 'carbon-components/scss/globals/scss/spacing.scss';

.topology-legend-btn-container {
  display: inline-block;
  position: fixed;
  bottom: $spacing-04;
  right: $spacing-05;

  .topology-legend-btn {
    background-color: $white-0;
    color: $blue-70;
    border: none;
    padding: 10px;
    min-width: 65px;
    min-height: 34px;
    &:focus {
      background-color: $gray-50;
    }
  }
}
